import React from 'react';
import { useSelector } from 'react-redux';
import { useGetFavoritesQuery, useGetWatchingListQuery } from 'services/backend';
import { useLazyGetProgramsQuery } from 'services/cms';
import { RootState } from 'store/index';
import { AGE_RATINGS } from 'utils/ageRating';
import { shuffleArray } from 'utils/array';
import { ProgramType } from 'utils/enum';
import { calcSizeForDevice } from 'utils/index';

import Section from '.';
import SectionVerticalSkeleton from './components/SectionHorizontalSkeleton';

import ProgramSave from 'components/Program/Save';

type SectionSuggestionProps = CmsHomeSectionSuggestion & { onLoaded?: () => void };

const SectionSuggestion: React.FC<SectionSuggestionProps> = (props) => {
  const user = useSelector((state: RootState) => state.auth);
  const profile = useSelector((state: RootState) => state.profile);
  const [fetchBasePrograms, lazyBaseProgramsQuery] = useLazyGetProgramsQuery();
  const [fetchSuggestionPrograms, lazySuggestionProgramsQuery] = useLazyGetProgramsQuery();
  const [suggestionPrograms, setSuggestionPrograms] = React.useState<
    CmsApiItem<CmsApiProgramList>[]
  >([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const {
    data: watchingList,
    isLoading: isLoadingWatchingList,
    isError,
  } = useGetWatchingListQuery({});
  const { data: favoritesData, isLoading: isFavoritesQueryLoading } = useGetFavoritesQuery();

  const watchedProgramIds = React.useMemo(() => {
    if (isLoadingWatchingList) return [];

    const orderedItems = [...(watchingList?.items || [])].sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateA.getTime() - dateB.getTime();
    });

    return (
      orderedItems?.reduce<string[]>((list, item) => {
        if (list.includes(item.programId) || !item.duration) return list;
        return [...list, item.programId];
      }, []) || []
    );
  }, [watchingList]);

  const suggestionGenre = React.useMemo(() => {
    if (lazyBaseProgramsQuery.isLoading || isLoadingWatchingList) return null;
    return lazyBaseProgramsQuery.data?.items[0]?.genre?.[0]?._id;
  }, [lazyBaseProgramsQuery.data]);

  React.useEffect(() => {
    if (!suggestionGenre || isFavoritesQueryLoading) return;

    const typesToFetch = [
      ProgramType.MOVIE,
      ProgramType.DOCUMENTARY,
      ProgramType.PROGRAM,
      ProgramType.TV_SHOW,
    ];

    const fetchProgramsByType = async () => {
      setIsLoading(true);

      try {
        const results = await Promise.all(
          typesToFetch.map((type) =>
            fetchSuggestionPrograms({ 'genre._id': suggestionGenre, type } as any).unwrap()
          )
        );

        const combinedPrograms = results.flatMap((result) => result.items);
        setSuggestionPrograms(shuffleArray(combinedPrograms));
      } catch (error) {
        console.error('Error fetching suggestion programs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProgramsByType();
  }, [suggestionGenre]);

  React.useEffect(() => {
    if (!watchedProgramIds.length) return;
    fetchBasePrograms({ _id: watchedProgramIds[0] });
  }, [watchedProgramIds]);

  const suggestionProgramsWithoutFavorites = React.useMemo(() => {
    if (!suggestionPrograms?.length || isFavoritesQueryLoading) return [];

    return suggestionPrograms.filter((suggestionProgram) => {
      const hasSuggestionOnFavorites = favoritesData?.items.some(
        (favoriteProgram) => favoriteProgram.programId === suggestionProgram._id
      );

      return !hasSuggestionOnFavorites;
    });
  }, [suggestionPrograms]);

  const items = React.useMemo(() => {
    if (suggestionProgramsWithoutFavorites?.length <= 0) return [];
    const formattedItems = suggestionProgramsWithoutFavorites.map((i) => ({
      link: `/${i.url}`,
      rating: i?.rating,
      isKid: Boolean(i.kids),
      reference: 'Minha Lista',
      image: { url: i.imageHorizontal?.url },
      jsxButton: <ProgramSave variant="horizontal" program={i as CmsProgram} />,
    }));

    const userRatingIndex = AGE_RATINGS.findIndex((rating) => rating === profile.ageRating);

    // TODO: Update suggestion list for kids mode. Pending business rules to be defined
    if (profile.isKid) return [];

    const filteredItems = formattedItems.filter((item) => {
      if (item?.rating) {
        const findRatingIndex = AGE_RATINGS.findIndex((rating) => rating === item.rating);
        return findRatingIndex <= userRatingIndex && !item.isKid;
      }
      return !item.isKid;
    });

    return filteredItems?.slice(0, 10);
  }, [suggestionProgramsWithoutFavorites]);

  React.useEffect(() => {
    if (
      isLoading ||
      isLoadingWatchingList ||
      isFavoritesQueryLoading ||
      lazyBaseProgramsQuery.isLoading ||
      lazyBaseProgramsQuery.isUninitialized ||
      lazySuggestionProgramsQuery.isLoading ||
      lazySuggestionProgramsQuery.isUninitialized
    ) {
      return;
    }

    setTimeout(() => props?.onLoaded?.(), 0);
  }, [isLoading, isLoadingWatchingList, lazyBaseProgramsQuery, lazySuggestionProgramsQuery]);

  if (
    isLoading ||
    isLoadingWatchingList ||
    isFavoritesQueryLoading ||
    lazyBaseProgramsQuery.isLoading ||
    lazyBaseProgramsQuery.isUninitialized ||
    lazySuggestionProgramsQuery.isLoading ||
    lazySuggestionProgramsQuery.isUninitialized
  ) {
    if (user.isAuthenticated && !isError && !watchingList && suggestionGenre !== undefined) {
      return (
        <SectionVerticalSkeleton
          _name="section"
          title="Programas que você pode gostar"
          variant="section.big"
        />
      );
    }
    return null;
  }

  if (!items.length || isError || items.length < 4) return null;

  return (
    <Section
      showTitle
      horizontal
      items={items as any}
      requestTVFocus
      _name="section"
      title="Programas que você pode gostar"
      variant="section.big"
      cardProps={{
        override: {
          cardWidth: {
            sm: 222,
            base: 222,
            xl: calcSizeForDevice(424, 560),
          },
        },
      }}
    />
  );
};

export default SectionSuggestion;
