import {
  Inter_100Thin,
  Inter_400Regular,
  Inter_700Bold,
  Inter_900Black,
  useFonts,
} from '@expo-google-fonts/inter';
import { GluestackUIProvider } from '@gluestack-ui/themed';
import { BottomSheetProvider } from 'context/BottomSheetContext';
import { GlobalProvider } from 'context/GlobalContext';
import { ModalProvider } from 'context/ModalContext';
import { SocketProvider } from 'context/SocketContext';
import { config } from 'core/gluestack-ui.config';
import * as ExpoSplashScreen from 'expo-splash-screen';
import { AppNavigator } from 'navigation/AppNavigator';
import { useEffect, useMemo } from 'react';
import { StatusBar } from 'react-native';
import { Provider } from 'react-redux';
import { PersistGate, type PersistGateProps } from 'redux-persist/integration/react';
import SplashScreen from 'screens/Splash';
import { persistor, store } from 'store/index';
import { ThemeProvider } from 'styled-components/native';
import theme from 'theme';
import { Platform } from 'utils/platform';

import { AssessmentModal } from 'components/Assessment';
import { StreamProvider } from 'components/Player/Stream/context';
import { PlayerProvider } from 'components/Player/context';

ExpoSplashScreen.preventAutoHideAsync();

function App() {
  const [fontsLoaded, fontError] = useFonts({
    Inter_900Black,
    Inter_100Thin,
    Inter_400Regular,
    Inter_700Bold,
  });

  const persistGateProps = useMemo<Omit<PersistGateProps, 'persistor'> | undefined>(() => {
    if (
      !Platform.Version?.toString().includes('LG WebOS') &&
      (Platform.OS !== 'web' || Platform.isTV)
    ) {
      return {
        loading: <SplashScreen />,
        onBeforeLift() {
          return new Promise<void>((resolve) => {
            setTimeout(resolve, 4000);
          });
        },
      };
    }
  }, []);

  useEffect(() => {
    if (fontsLoaded || fontError != null) {
      ExpoSplashScreen.hideAsync();
    }
  }, [fontsLoaded, fontError]);

  if (!fontsLoaded && fontError === null) {
    return null;
  }

  const getNavigator = () => {
    if (Platform.isTV) {
      return (
        <div className="tv-root">
          <AppNavigator />
        </div>
      );
    }

    return <AppNavigator />;
  };

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} {...persistGateProps}>
        <ThemeProvider theme={theme}>
          <GluestackUIProvider config={config}>
            <SocketProvider>
              <StreamProvider>
                <PlayerProvider>
                  <StatusBar barStyle="light-content" backgroundColor="transparent" translucent />
                  <GlobalProvider>
                    <BottomSheetProvider>
                      <ModalProvider>
                        {getNavigator()}
                        {!Platform.isTV && Platform.OS !== 'web' && <AssessmentModal />}
                      </ModalProvider>
                    </BottomSheetProvider>
                  </GlobalProvider>
                </PlayerProvider>
              </StreamProvider>
            </SocketProvider>
          </GluestackUIProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
