import { Box, ButtonText, Center, Spinner, Text, View } from '@gluestack-ui/themed';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { BackGroundImageView } from 'screens/Login/styles';
import { isMobileOrSmallScreen } from 'utils/deviceInfo';
import { calcSizeForDevice, customSmallNumbersString, verifyIsSameDay } from 'utils/index';

import NewGradientButton from 'components/Button/NewGradienteButton';
import { usePlayer } from 'components/Player/context';

export interface TransmitionCatchProps {
  endTime?: string;
  startTime?: string;
}

const wrongTimeImageLarge =
  'https://img.band.uol.com.br/image/2024/07/01/transmissionwrongtime-15530.png';
const transmissionWrongTimeMobile =
  'https://img.band.uol.com.br/image/2024/07/01/transmissionwrongtimemobile-155350.png';
const backgroundTimeEndLarge =
  'https://img.band.uol.com.br/image/2024/07/01/backgroundtimeend-155434.png';
const backgroundTimeEndMobile =
  'https://img.band.uol.com.br/image/2024/07/01/backgroundtimeendmobile-15550.png';

const TransmitionCatch: React.FC<TransmitionCatchProps> = (props) => {
  const linkTo = useLinkTo();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    title: '',
    description: '',
    image: '',
  });
  const { width } = useWindowDimensions();
  const { TransmissionScreen } = useGlobalConfig();
  const navigation = useNavigation();
  const { setHasTransmissionError } = usePlayer();

  useEffect(() => {
    return navigation.addListener('beforeRemove', async () => {
      setHasTransmissionError(false);
    });
  }, []);

  useEffect(() => {
    setHasTransmissionError(true);
  }, []);

  useEffect(() => {
    if (props?.endTime) {
      setData({
        title: 'A TRANSMISSÃO\nFOI ENCERRADA',
        description: 'Agradecemos por assistir! Continue conectado para futuras transmissões.',
        image: isMobileOrSmallScreen(width)
          ? TransmissionScreen?.endedTransmissionImage_mobile.url ?? backgroundTimeEndMobile
          : TransmissionScreen?.endedTransmissionImage_large.url ?? backgroundTimeEndLarge,
      });
      setIsLoading(false);
    } else if (props?.startTime) {
      const isCurrentDay = verifyIsSameDay(new Date(), new Date(props?.startTime));
      const dueDate = new Date(props?.startTime);

      const hours = dueDate.getHours();
      const customHours = customSmallNumbersString(hours.toString().length, hours);

      const minutes = dueDate.getMinutes();
      const customMinutes = customSmallNumbersString(minutes.toString().length, minutes);

      const day = dueDate.getDate();
      const customDay = customSmallNumbersString(day.toString().length, day);

      const startTime = !isCurrentDay
        ? `DIA ${customDay} ÀS ${customHours}H${customMinutes}`
        : `ÀS ${customHours}H${customMinutes}`;

      setData({
        title: `A TRANSMISSÃO\nINICIA ${startTime}`,
        description:
          'Prepare-se para uma experiência incrível! A transmissão ainda não está disponível, fique ligado.',
        image: isMobileOrSmallScreen(width)
          ? TransmissionScreen?.wrongTimeImage_mobile.url ?? transmissionWrongTimeMobile
          : TransmissionScreen?.wrongTimeImage_large.url ?? wrongTimeImageLarge,
      });
      setIsLoading(false);
    }
  }, [props]);

  return (
    <View flex={1} bg="#161233">
      {isLoading ? (
        <Center h="$full">
          <Spinner size="large" />
        </Center>
      ) : (
        <BackGroundImageView source={{ uri: data.image }}>
          <Box
            padding={calcSizeForDevice(16)}
            height="$full"
            alignItems="center"
            justifyContent="center">
            <View
              padding={calcSizeForDevice(16)}
              paddingHorizontal={calcSizeForDevice(isMobileOrSmallScreen(width) ? 5 : 16)}
              alignItems="center">
              <Text
                fontWeight="$bold"
                color="white"
                textAlign="center"
                textShadowColor="#00000077"
                textShadowOffset={{ width: 0, height: 3 }}
                textShadowRadius={10}
                fontSize={calcSizeForDevice(isMobileOrSmallScreen(width) ? 34 : 80, 100)}
                lineHeight={calcSizeForDevice(isMobileOrSmallScreen(width) ? 44 : 97, 126)}>
                {data.title}
              </Text>

              <Text
                fontSize={calcSizeForDevice(isMobileOrSmallScreen(width) ? 18 : 24, 34)}
                lineHeight={calcSizeForDevice(isMobileOrSmallScreen(width) ? 28 : 32, 42)}
                fontWeight="$bold"
                color="white"
                size="lg"
                textAlign="center"
                width={isMobileOrSmallScreen(width) ? 'auto' : '65%'}
                textShadowColor="#00000077"
                textShadowOffset={{ width: 0, height: 2 }}
                textShadowRadius={10}
                mt={calcSizeForDevice(isMobileOrSmallScreen(width) ? 32 : 48)}
                mb={calcSizeForDevice(isMobileOrSmallScreen(width) ? 54 : 32)}>
                {data.description}
              </Text>
              <Box w={300} mx={width > 500 ? undefined : 'auto'}>
                <NewGradientButton
                  useTVFocus
                  style={{ height: 54, width: '100%' }}
                  onPress={() => linkTo('/')}>
                  <ButtonText fontWeight="bold" color="#070d31" size="sm">
                    Voltar para página inicial
                  </ButtonText>
                </NewGradientButton>
              </Box>
            </View>
          </Box>
        </BackGroundImageView>
      )}
    </View>
  );
};

export default TransmitionCatch;
