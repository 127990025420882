export const chunkArrayIntoN = <T>(array: T[], n: number) => {
  const size = Math.ceil(array.length / n);
  return Array.from({ length: n }, (v, i) => array.slice(i * size, i * size + size));
};

export const shuffleArray = (array: any[]) => {
  const arrayCopy = [...array];

  for (let i = arrayCopy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
  }

  return arrayCopy;
};
