import { Image, Pressable, Text, View } from '@gluestack-ui/themed';
import { NavigationContainerRefWithCurrent, Route } from '@react-navigation/native';
import { useBottomSheet } from 'context/BottomSheetContext';
import { useGlobalConfig } from 'context/GlobalContext';
import { useModalConfig } from 'context/ModalContext';
import * as WebBrowser from 'expo-web-browser';
import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import { AttachStep } from 'react-native-spotlight-tour';
import { useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { MAX_MENU_ITEMS } from 'utils/constants';
import { Platform } from 'utils/platform';

import bandBet from 'assets/navbar/bandbet.png';
import bandBetWhite from 'assets/navbar/bandbet_white.png';
import bandcom from 'assets/navbar/bandcom.png';
import bandcomWhite from 'assets/navbar/bandcom_white.png';
import bandplacar from 'assets/navbar/bandplacar.png';
import bandplacarWhite from 'assets/navbar/bandplacar_white.png';
import bandshop from 'assets/navbar/bandshop.png';
import bandshopWhite from 'assets/navbar/bandshop_white.png';
import newco from 'assets/navbar/newco.png';
import newcoWhite from 'assets/navbar/newco_white.png';

interface MenuItem {
  productImage?: string;
  productUrl?: string;
  name: string;
  icon: string;
  selectedIcon: string;
  isNewTab?: boolean;
}

const BottomMenu: React.FC<{ navigationRef?: NavigationContainerRefWithCurrent<any> }> = ({
  navigationRef,
}) => {
  const [selected, setSelected] = useState<number | undefined>(undefined);
  const { handleToggleSheet, visible, setCurrentUrl, setCurrentMenu } = useBottomSheet();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const { BandMenu } = useGlobalConfig();
  const { toggleModal, isModalVisible } = useModalConfig();
  const { isKid } = useSelector((state: RootState) => state.profile);
  const currentRoute: Route<string> | undefined = navigationRef?.current?.getCurrentRoute();

  const getIcon = (name: string, white: boolean) => {
    switch (name) {
      case 'Bandbet':
        return white ? bandBetWhite : bandBet;
      case 'Band.com.br':
        return white ? bandcomWhite : bandcom;
      case 'Bandplacar':
        return white ? bandplacarWhite : bandplacar;
      case 'Bandshop':
        return white ? bandshopWhite : bandshop;
      case 'Newco Play':
        return white ? newcoWhite : newco;
      default:
        return white ? bandcomWhite : bandcom;
    }
  };

  const isBottomMenuVisible = () => {
    if (
      isKid ||
      Platform.isTV ||
      !menuItems?.[0]?.selectedIcon ||
      currentRoute?.name === 'Login' ||
      currentRoute?.name === 'ForceUpdate'
    )
      return false;

    const isMobilePlatform = Platform.isMobileWeb() || ['android', 'ios'].includes(Platform.OS);
    return isMobilePlatform;
  };

  const handleCustomItem = (e: BandMenuProducts) => {
    return {
      productImage: e.productImage?.url,
      productUrl: e.productUrl,
      name: e.name,
      icon: e.icon?.url,
      selectedIcon: e.selectedIcon?.url,
      isNewTab: !!e?.isNewTab,
    };
  };

  useEffect(() => {
    const menuLength = BandMenu?.product.length ?? 0;
    const customMenu = [];

    if (menuLength > MAX_MENU_ITEMS) {
      customMenu.push(
        ...(BandMenu?.product.slice(0, MAX_MENU_ITEMS - 1).map((e) => {
          return handleCustomItem(e);
        }) ?? [])
      );

      customMenu?.push({
        productImage: undefined,
        productUrl: undefined,
        name: 'Outros',
        icon: 'https://img.band.uol.com.br/image/2024/12/09/moremenuicon-91744.png',
        selectedIcon:
          'https://img.band.uol.com.br/image/2024/12/09/moremenuiconselected-152650.png',
      });

      setMenuItems(customMenu);
    } else {
      customMenu.push(
        ...(BandMenu?.product.map((e) => {
          return handleCustomItem(e);
        }) ?? [])
      );

      setMenuItems(customMenu);
    }
  }, [BandMenu]);

  useEffect(() => {
    if (!isModalVisible && !visible) setSelected(undefined);
  }, [visible, isModalVisible]);

  useEffect(() => {
    if (isBottomMenuVisible()) {
      // logEventActionName(
      //   { label: 'impressão', component: 'navbar', reference: 'impressão' },
      //   'NavBar_Outros'
      // );
    }
  }, [visible]);

  useEffect(() => {
    if (isModalVisible) {
      // logEventActionName(
      //   { label: 'clique', component: 'navbar', reference: 'open_menu' },
      //   'NavBar_Outros'
      // );

      return;
    }

    // logEventActionName(
    //   { label: 'clique', component: 'navbar', reference: 'close_menu' },
    //   'View_NavBar'
    // );
  }, [isModalVisible]);

  const handleOpenUrl = async (item: any, index: number) => {
    const url = item.productUrl;

    if (!url) {
      visible && handleToggleSheet();
      setSelected(selected === index ? undefined : index);
      return toggleModal();
    }

    // logEventActionName(
    //   { label: 'clique', component: 'navbar', reference: url },
    //   sanitizeWord(`NavBar_${item.name}`)
    // );

    if (Platform.OS === 'web' || item.isNewTab) {
      visible && handleToggleSheet();
      await WebBrowser.openBrowserAsync(url);
    } else {
      setCurrentMenu({ value: index, name: item.name, image: item.icon });
      isModalVisible && toggleModal();

      !visible && handleToggleSheet(currentRoute?.name !== 'Program');
      setSelected(index);
      setCurrentUrl(url);
    }
  };

  const renderItem = (item: any, index: number) => (
    <Pressable
      onPress={() => {
        handleOpenUrl(item, index);
      }}
      paddingHorizontal={8}
      alignItems="center"
      gap={5}>
      <Image
        source={getIcon(item.name, selected !== index)}
        width={26}
        height={26}
        alt={`Ícone do item ${item.name}`}
      />
      <Text
        textAlign="center"
        fontSize={10}
        lineHeight={16}
        fontWeight="400"
        color={selected === index ? '#888888' : '$white'}>
        {item.name}
      </Text>
    </Pressable>
  );

  if (!isBottomMenuVisible()) return null;

  return (
    <AttachStep
      index={1}
      children={
        <View
          zIndex={4}
          mt="auto"
          h={Platform.OS !== 'ios' ? 70 : 90}
          backgroundColor="#252836"
          borderTopWidth={1}
          borderTopColor="#A8B4FF80"
          alignItems="center"
          flexDirection="row">
          <FlatList
            data={menuItems}
            keyExtractor={(_, index) => String(index)}
            renderItem={({ item, index }) => renderItem(item, index)}
            contentContainerStyle={{
              flex: 1,
              justifyContent: 'space-around',
              paddingHorizontal: 20,
            }}
            style={{
              paddingBottom: Platform.OS !== 'ios' ? 0 : 10,
            }}
            showsHorizontalScrollIndicator={false}
            scrollEnabled={false}
            horizontal
          />
        </View>
      }
    />
  );
};

export default React.memo(BottomMenu);
