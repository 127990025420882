import { createSlice } from '@reduxjs/toolkit';

type RatingState = {
  suppressRatingModal: boolean;
  futureDays: Date | null;
  daysUntilModalOpens: number;
  shouldItOpen: boolean;
};

const calculateFutureDate = (daysUntilModalOpens: number): Date => {
  const today = new Date();
  const futureDayToSet = today.setDate(today.getDate() + daysUntilModalOpens);
  return new Date(futureDayToSet);
};

const ratingSlice = createSlice({
  name: 'rating',
  initialState: {
    suppressRatingModal: false,
    futureDays: null,
    daysUntilModalOpens: 3,
    shouldItOpen: false,
  } as RatingState,
  reducers: {
    setFiveDaysFromNow: (state, { payload }) => {
      state.futureDays = payload.futureDays;
    },
    setAvoidRatingModal: (state, { payload }) => {
      state.suppressRatingModal = payload.suppressRatingModal;
    },
    setFutureModalOpenDate: (state, { payload }) => {
      state.futureDays = calculateFutureDate(payload.quantityOfFutureDay);
    },
    setForceToBeClose: (state) => {
      state.shouldItOpen = false;
    },
    setForceToBeOpen: (state) => {
      state.shouldItOpen = true;
    },
    checkShouldBeOpen: (state) => {
      const today = new Date();
      if (state.suppressRatingModal) {
        return;
      }
      if (state.futureDays) {
        const checkDay = new Date(today) > new Date(state.futureDays);
        if (checkDay) {
          state.shouldItOpen = true;
        }
      } else {
        state.futureDays = calculateFutureDate(5);
      }
    },
  },
});

export const {
  setFiveDaysFromNow,
  setAvoidRatingModal,
  setForceToBeOpen,
  setForceToBeClose,
  setFutureModalOpenDate: setFutureRatingModal,
  checkShouldBeOpen,
} = ratingSlice.actions;
export default ratingSlice.reducer;
