import { Dimensions, PixelRatio } from 'react-native';
import { Platform } from 'utils/platform';

interface SCREEN_SIZE_PROPS {
  width: number;
  height: number;
  scale: number;
  fontSize: number;
}
export const SCREEN_SIZE = (): SCREEN_SIZE_PROPS => {
  const size = Dimensions.get('screen');
  return {
    width: size.width,
    height: size.height,
    scale: size.scale,
    fontSize: size.fontScale,
  };
};

export function parseUrl(url: string): { query: { [key: string]: string } } {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const query: { [key: string]: string } = {};
  let match;
  while ((match = regex.exec(url))) {
    query[match[1]] = decodeURIComponent(match[2]);
  }
  return {
    query,
  };
}

export const getUrlPath = (url: string): string => {
  const urlPath = url
    .replace(/^(expo|bandplay)[^:]*:/, '')
    .replace(/^https?:\/\/(www\.)?bandplay\.com/, '')
    .replace(/^\/{2,}/, '/');

  return urlPath.charAt(0) === '/' ? urlPath : '/' + urlPath;
};

export const getRem = (size: number): number => {
  const BASEFONTSIZE = 16;
  const ratio = PixelRatio.getFontScale();
  return size * ratio * BASEFONTSIZE;
};

export function secondsToTime(seconds: number) {
  if (isNaN(seconds) || seconds < 0) {
    return '-';
  }

  seconds = Math.floor(seconds);

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursString = hours < 10 ? '0' + hours : hours.toString();
  const minutesString = minutes < 10 ? '0' + minutes : minutes.toString();
  const secondsString =
    remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds.toString();

  return `${hoursString}:${minutesString}:${secondsString}`;
}

export function hourFormatterBySeconds(seconds: number) {
  const horas = Math.floor(seconds / 3600);

  const minutos = Math.floor((seconds % 3600) / 60);

  const horasFormatadas = horas > 0 ? horas.toString().padStart(2) + 'h' : '';

  const minutosFormatados = minutos.toString().padStart(2) + 'min';

  return horasFormatadas + minutosFormatados;
}

export function textTrim(text: string, maxLength: number) {
  return text.length > maxLength ? text.slice(0, maxLength).replace(/[?!\s]+$/, '') + '...' : text;
}

export const calcHeightWithWidth = (width: number, aspectRatio: number) =>
  (1 / aspectRatio) * width;

/**
 * This function calculates the size value according to the screen size and SO of TV
 * @param fullhdValue [number] size value for fullhd
 * @returns [number] size value according to screen - HD, FullHD or AndroidTV
 */
export const calcSizeForTv = (fullhdValue: number) => {
  if (Platform.isWebTV) {
    const screenWidth =
      window.innerWidth || window.document.body.clientWidth || window.screen.width;
    const HD_THRESHOLD = 1440;

    if (screenWidth < HD_THRESHOLD) {
      return fullhdValue / 1.5;
    }
  } else if (Platform.isAndroidTV) {
    return fullhdValue / 2;
  }

  return fullhdValue;
};

/**
 * This function calculates the size value according to the type of device
 * @param defaultValue [number] size value for mobile and web
 * @param tvFullhdValue [number: opcional] size value for FullHD TV; if this value is not specified, it will use defaultValue for the calculation
 * @returns [number] size value according to device - Mobile/Web or TV
 */
export const calcSizeForDevice = (defaultValue: number, tvFullhdValue: number = defaultValue) =>
  Platform.isTV ? calcSizeForTv(tvFullhdValue) : defaultValue;

export const verifyIsSameDay = (date1: Date, date2: Date): boolean => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  return firstDate.getDate() === secondDate.getDate();
};

export const customSmallNumbersString = (length: number, data: number | string) => {
  return length < 2 ? `0${data}` : data;
};

export const isEventTimePassed = (date: Date): boolean => {
  const now = new Date();

  return date > now;
};

export const sanitizeWord = (name: string) => {
  return name.replace(/[^a-zA-Z0-9_]/g, '_').slice(0, 40);
};
