import { Box, Button, ButtonText, Image, Text, VStack, View } from '@gluestack-ui/themed';
import {
  CommonActions,
  NavigationProp,
  useFocusEffect,
  useIsFocused,
  useLinkTo,
  useNavigation,
} from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { useLogInMutation } from 'services/backend';
import { RootState } from 'store/index';
import { parseUrl } from 'utils/index';
import { Platform } from 'utils/platform';

import CTVLoginScreen from './index.ctv';
import { BackGroundImageView } from './styles';

import Logo from 'assets/loginLogo.png';

import NewGradientButton from 'components/Button/NewGradienteButton';
import IsLoading from 'components/isLoading';

WebBrowser.maybeCompleteAuthSession();

const ImageGrid = (props: { LoginScreen?: LoginScreenImage }) => {
  const { LoginScreen } = props;

  return (
    <Box
      gap="$4"
      flexWrap="wrap"
      alignItems="center"
      flexDirection="row"
      justifyContent="space-evenly">
      <Image
        w={80}
        height={80}
        rounded="$md"
        alt={LoginScreen?.loginImage_6?.title}
        source={{ uri: LoginScreen?.loginImage_6?.url }}
      />
      <Image
        w={100}
        height={100}
        rounded="$md"
        alt={LoginScreen?.loginImage_2?.title}
        source={{ uri: LoginScreen?.loginImage_2?.url }}
      />
      <Image
        w={100}
        height={150}
        rounded="$md"
        alt={LoginScreen?.loginImage_1?.title}
        source={{ uri: LoginScreen?.loginImage_1?.url }}
      />
      <Image
        w={100}
        height={100}
        rounded="$md"
        alt={LoginScreen?.loginImage_5?.title}
        source={{ uri: LoginScreen?.loginImage_5?.url }}
      />
      <Image
        w={100}
        height={150}
        rounded="$md"
        alt={LoginScreen?.loginImage_3?.title}
        source={{ uri: LoginScreen?.loginImage_3?.url }}
      />
      <Image
        w={85}
        height={85}
        rounded="$md"
        alt={LoginScreen?.loginImage_4?.title}
        source={{ uri: LoginScreen?.loginImage_4?.url || `` }}
      />
    </Box>
  );
};

const LoginScreenComponent: React.FC<RootStackScreenProps<'Login'>> = (props) => {
  const { route } = props;
  const linkTo = useLinkTo();
  const analytics = useAnalytics();
  const [logIn] = useLogInMutation();
  const { LoginScreen, dataIsLoading } = useGlobalConfig();
  const [isLoginLoading, setIsLoginLoading] = React.useState(false);
  const { loginBaseUrl, loginClientId } = Constants.expoConfig?.extra || {};

  const handleLogin = (prefer: 'signup' | 'signin' = 'signin') => {
    return async () => {
      if (prefer === 'signin') analytics.triggerLoginActionEvent();
      else analytics.triggerCreateAccountActionEvent();

      const redirectUri = Linking.createURL('/entrar');
      const encodedUri = encodeURIComponent(redirectUri);

      const loginUrl = `${loginBaseUrl}/${
        prefer === 'signup' ? 'signup?' : ''
      }authorize?client_id=${loginClientId}&response_type=code&redirect_uri=${encodedUri}&prefer=${prefer}&session=strict`;

      const res = await WebBrowser.openAuthSessionAsync(loginUrl, redirectUri);

      if (res.type === 'success') {
        const { query } = parseUrl(res.url);
        const { code } = query;
        setIsLoginLoading(true);

        try {
          await logIn({ code }).unwrap().catch(console.error);
          if (route.params?.redirect) linkTo(route.params.redirect);
          else linkTo('/');
        } finally {
          setIsLoginLoading(false);
        }
      }
    };
  };

  if (!LoginScreen || dataIsLoading || isLoginLoading) {
    return (
      <BackGroundImageView source={{ uri: LoginScreen?.loginBg?.url }}>
        <VStack
          backgroundColor="transparent"
          flex={1}
          height="$full"
          width="$full"
          position="absolute"
          alignItems="center"
          justifyContent="center">
          <IsLoading />
        </VStack>
      </BackGroundImageView>
    );
  }

  return (
    <View flex={1} bg="#161233">
      <BackGroundImageView source={{ uri: LoginScreen?.loginBg?.url }}>
        <View flex={1} w="$full" maxWidth={370} alignItems="center" justifyContent="space-evenly">
          <Image source={Logo} w={200} h={50} alt="Bandplay" />
          <ImageGrid LoginScreen={LoginScreen} />

          <VStack w="100%" space="2xl" alignItems="center">
            <Text color="$white" fontSize="$xl" lineHeight="$xl" textAlign="center" bold>
              {LoginScreen?.description}
            </Text>

            <VStack width="$full" space="lg">
              <NewGradientButton height={54} onPress={handleLogin()} width="$full">
                <ButtonText textAlign="center" fontWeight="bold" color="#070d31" fontSize={16}>
                  Já tenho conta
                </ButtonText>
              </NewGradientButton>

              <Button
                height={54}
                borderWidth={2}
                backgroundColor="transparent"
                borderColor="#2240ff"
                onPress={handleLogin('signup')}
                rounded="$3xl">
                <ButtonText textAlign="center" fontWeight="bold" color="$white" fontSize={16}>
                  Criar conta gratuita
                </ButtonText>
              </Button>
            </VStack>
          </VStack>
        </View>
      </BackGroundImageView>
    </View>
  );
};

export default function (props: any) {
  const isFocused = useIsFocused();
  const analytics = useAnalytics();
  const [hardResetKey, setHardResetKey] = React.useState<number>();
  const user = useSelector((state: RootState) => state.auth);
  const navigation = useNavigation<NavigationProp<any>>();

  React.useEffect(() => {
    setHardResetKey(new Date().getTime());
  }, [isFocused]);

  React.useEffect(() => {
    if (user.isAuthenticated && isFocused) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: 'Home' }],
        })
      );
    }
  }, [user, navigation, isFocused]);

  useFocusEffect(
    useCallback(() => {
      analytics.triggerLoginScreenPageViewEvent();
    }, [])
  );

  return Platform.isTV ? (
    <CTVLoginScreen key={hardResetKey} {...props} />
  ) : (
    <LoginScreenComponent key={hardResetKey} {...props} />
  );
}
