import { Box } from '@gluestack-ui/themed';
import React, { memo, useEffect, useRef } from 'react';
import { Animated, FlatList, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { useGetHighlightsQuery } from 'services/cms';
import { RootState } from 'store/index';
import { calcSizeForTv } from 'utils/index';

import CarouselIndicators from 'components/Carousel/CarouselIndicators';
import CarouselItem from 'components/Carousel/CarouselItem';
import FocusView from 'components/FocusView';

const Carousel: React.FC<CmsHomeHero> = (props) => {
  const flatListRef = useRef<FlatList | null>(null);
  const scrollX = useRef(new Animated.Value(0)).current;
  const profile = useSelector((state: RootState) => state.profile);

  const { data, isLoading, refetch } = useGetHighlightsQuery(props.searchPrograms, {
    pollingInterval: 900000,
  });

  const { width: screenWidth, height: rawScreenHeight } = useWindowDimensions();
  const screenHeight = rawScreenHeight > 748 ? 748 : rawScreenHeight;
  const halfScreenHeight = screenHeight / 2;

  const scrollEventConfig = [{ nativeEvent: { contentOffset: { x: scrollX } } }];
  const onScroll = Animated.event(scrollEventConfig, { useNativeDriver: false });

  const MemoizedCarouselItem = memo(({ item, loading, link }: any) => (
    <Box flex={1} w={screenWidth - calcSizeForTv(108)} h={calcSizeForTv(screenHeight) + 100}>
      <CarouselItem item={item} loading={loading} link={link} />
    </Box>
  ));

  useEffect(() => {
    if (data?.items.length) {
      flatListRef.current?.scrollToIndex({ index: 0, animated: true });
      scrollX.setValue(0);
    }
  }, [data, scrollX]);

  useEffect(() => {
    refetch();
  }, [profile.ageRating]);

  const scrollToItem = (n: number) => {
    flatListRef.current?.scrollToIndex({ animated: true, index: n });
  };

  if (!data?.items.length) {
    return null;
  }

  return (
    <FocusView>
      <Box
        height={calcSizeForTv(screenHeight) + 100}
        mb={calcSizeForTv(14)}
        style={{ marginBottom: -(calcSizeForTv(halfScreenHeight) - 50) }}>
        <FlatList
          horizontal
          bounces
          pagingEnabled
          ref={flatListRef}
          data={data?.items}
          onScroll={onScroll}
          renderItem={({ item }) => (
            <MemoizedCarouselItem item={item} loading={isLoading} link={item.more?.link} />
          )}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ marginBottom: 0 }}
          keyExtractor={(item, index) => `${item.title}_${index}`}
          viewabilityConfig={{ itemVisiblePercentThreshold: 50 }}
        />

        <Box
          height="$0"
          position="absolute"
          bottom={calcSizeForTv(halfScreenHeight) + 70}
          left={calcSizeForTv(24)}>
          <CarouselIndicators
            data={data?.items ?? []}
            scrollX={scrollX}
            scrollToItem={scrollToItem}
          />
        </Box>
      </Box>
    </FocusView>
  );
};

export default Carousel;
