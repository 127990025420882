import { Box, ButtonText, Text, View, useBreakpointValue } from '@gluestack-ui/themed';
import { useLinkTo } from '@react-navigation/native';
import withLink, { WithLinkProps } from 'hoc/withLink';
import React, { Dispatch, SetStateAction } from 'react';
import { AgeRatingKey } from 'utils/ageRating';
import { calcSizeForDevice, calcSizeForTv, getUrlPath } from 'utils/index';
import { Platform } from 'utils/platform';

import NewGradientButton from 'components/Button/NewGradienteButton';
import FocusView from 'components/FocusView';
import HeroImage from 'components/Hero/HeroImage';
import CarouselGradient from 'components/NewCarrousel/carouselGradient';
import Rating from 'components/Rating';
import Skeleton from 'components/Skeleton';
import VideoPreview from 'components/VideoPreview';
import { WebDiv } from 'components/WebDiv';

type CarouselItemProps = {
  item: CmsHighlight;
  index?: number;
  currentIndex?: number;
  highlights?: CmsApiHightlightList;
  scroll?: any;
  loading: boolean;
  isCarouselFocused?: boolean;
  setIsCarouselFocused?: Dispatch<SetStateAction<boolean>>;
};

const CarouselItem: React.FC<CarouselItemProps> = React.memo(
  ({ item, currentIndex, loading, setIsCarouselFocused }) => {
    const linkTo = useLinkTo();
    const redirectLink = getUrlPath(item?.more?.link || '');
    const carouselRef = React.useRef(null);
    const [isCarouselBtnFocused, setIsCarouselBtnFocused] = React.useState(false);
    const [isPreviewPlaying, setIsPreviewPlaying] = React.useState(false);

    const videoPreviewUrl = item?.videoPreview;

    const paddingX = useBreakpointValue({ xl: calcSizeForDevice(24), lg: '$6', base: '$4' });

    const titleSize = useBreakpointValue({
      xl: calcSizeForDevice(36, 48),
      lg: '$4xl',
      base: '$3xl',
    });

    const descriptionSize = useBreakpointValue({
      xl: calcSizeForDevice(16, 24),
      lg: '$md',
      base: '$xs',
    });

    const contentOffset = useBreakpointValue({
      xl: calcSizeForDevice(64),
      lg: '$16',
      base: Platform.isAndroidTV ? calcSizeForDevice(64) : undefined,
    });

    React.useEffect(() => {
      setIsPreviewPlaying(false);

      const timer = setTimeout(() => {
        setIsPreviewPlaying(true);
      }, 1500);

      return () => clearTimeout(timer);
    }, [currentIndex, isCarouselBtnFocused]);

    React.useEffect(() => {
      setIsCarouselFocused?.(isCarouselBtnFocused);
    }, [isCarouselBtnFocused]);

    React.useEffect(() => {
      if (Platform.isWebTV) {
        const observer = new MutationObserver((mutations: any) => {
          let isFocused = false;

          for (const mutation of mutations) {
            if (mutation.target.classList.contains('focused')) {
              isFocused = true;
            }
          }

          setIsCarouselBtnFocused(isFocused);
        });

        if (carouselRef.current) {
          observer.observe(carouselRef.current, {
            subtree: true,
            childList: true,
            attributes: true,
            attributeFilter: ['class'],
          });
        }

        return () => observer.disconnect();
      }
    }, [carouselRef]);

    if (loading) return <Skeleton variant="hero" />;

    return (
      <View position="relative" w="$full" h="$full">
        <Box w="$full" h="$full" position="absolute" top="$0" left="$0" zIndex={1}>
          {videoPreviewUrl && isPreviewPlaying && isCarouselBtnFocused ? (
            <VideoPreview
              videoPreview={videoPreviewUrl}
              poster={item.image?.url!}
              isPlaying={isPreviewPlaying}
              setIsPlaying={setIsPreviewPlaying}
            />
          ) : (
            !!item.image?.url && <HeroImage title={item.title!} source={item.image?.url!} />
          )}
          <CarouselGradient />
        </Box>

        <Box zIndex={3} p={paddingX}>
          <Box flexDirection="column" gap={calcSizeForDevice(8, 16)} pt={contentOffset}>
            <Text
              fontSize={titleSize}
              color="$white"
              lineHeight={calcSizeForDevice(32, 60)}
              fontWeight="$semibold">
              {item?.title}
            </Text>
            <Rating ageRatingKey={item.rating as AgeRatingKey} />
            <Text
              fontSize={descriptionSize}
              lineHeight={calcSizeForDevice(16, 32)}
              my={calcSizeForDevice(5, 15)}
              color="$white">
              {item?.description}
            </Text>

            {item?.more?.title && (
              <WebDiv ref={carouselRef}>
                <FocusView>
                  <Box w={calcSizeForTv(300)}>
                    <NewGradientButton
                      useTVFocus
                      hasTVPreferredFocus
                      onBtnFocus={() => setIsCarouselBtnFocused?.(true)}
                      onBtnBlur={() => setIsCarouselBtnFocused?.(false)}
                      style={{ height: calcSizeForTv(54), width: '100%' }}
                      onPress={() => linkTo(redirectLink)}>
                      <ButtonText
                        fontWeight="bold"
                        color="#070d31"
                        textTransform="uppercase"
                        fontSize={calcSizeForTv(16)}>
                        {item?.more?.title || 'Assistir agora'}
                      </ButtonText>
                    </NewGradientButton>
                  </Box>
                </FocusView>
              </WebDiv>
            )}
          </Box>
        </Box>
      </View>
    );
  }
);

export default function CarouselItemProxy(props: CarouselItemProps & WithLinkProps) {
  if (Platform.isTV) return <CarouselItem {...props} />;
  const Component = withLink(CarouselItem, 'Hero');
  return <Component {...props} />;
}
