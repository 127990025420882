import { Box } from '@gluestack-ui/themed';
import { useLinkTo } from '@react-navigation/native';
import userRequired from 'hoc/userRequired';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { backendApiSlice } from 'services/backend';
import { logoutAction } from 'store/actions';
import { RootState } from 'store/index';
import { toggleKidProfile } from 'store/slices/profileSlice';

import Button from 'components/Button';
import useEasterEgg from 'components/EasterEgg/utils/useEasterEgg';
import FocusView from 'components/FocusView';
import ModalAgeRating from 'components/Header/ModalAgeRating';
import { MenuTvItem } from 'components/SidebarMenu';
import { SIDEBAR_MENU_TV } from 'components/SidebarMenu/menu-tv';

const TvSubMenuArea: React.FC = () => {
  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  const { isDevMenuActive } = useEasterEgg({});
  const profile = useSelector((state: RootState) => state.profile);
  const [isAgeRatingOpen, setIsAgeRatingOpen] = React.useState(false);

  function onHandleItemPress(item: MenuTvItem) {
    switch (item.name) {
      case 'age-rating':
        setIsAgeRatingOpen(true);
        break;
      case 'kids-mode':
        dispatch(toggleKidProfile());
        if (profile.isKid) {
          dispatch(logoutAction());
          dispatch(backendApiSlice.util.resetApiState());
        }
        linkTo(`/?toggleKidProfile=${new Date().getTime()}`);
        break;
      case 'loggout':
        dispatch(logoutAction());
        dispatch(backendApiSlice.util.resetApiState());
        linkTo('/');
        break;
      default:
        linkTo(item?.link || '');
    }
  }

  if (isAgeRatingOpen) {
    return (
      <ModalAgeRating isOpen={isAgeRatingOpen} toggleModal={() => setIsAgeRatingOpen(false)} />
    );
  }

  return (
    <FocusView trapFocusUp trapFocusRight style={{ flex: 1 }}>
      <Box
        flex={1}
        py={30}
        alignItems="center"
        justifyContent={profile.isKid ? 'center' : 'center'}>
        {profile.isKid
          ? SIDEBAR_MENU_TV.map(({ submenu }) =>
              submenu
                ?.filter((item) => item?.name === 'kids-mode' || item?.name === 'contact')
                .map((FilterItem) => (
                  <Button
                    style={{ marginBottom: 30 }}
                    key={FilterItem?.name}
                    useTVFocus
                    role="link"
                    variant="ghost"
                    onPress={() => onHandleItemPress(FilterItem)}
                    iconType={FilterItem?.icon as IconsName}>
                    {FilterItem?.label
                      ? profile.isKid && FilterItem?.name === 'kids-mode'
                        ? 'Sair do Modo Infantil'
                        : FilterItem?.label
                      : FilterItem?.label}
                  </Button>
                ))
            )
          : SIDEBAR_MENU_TV.map(({ submenu }) =>
              submenu?.map(
                (item, index) =>
                  (!item.easterEgg || isDevMenuActive) && (
                    <Button
                      useTVFocus
                      hasTVPreferredFocus={index === 0}
                      style={{ marginBottom: 20 }}
                      key={item?.name}
                      role="link"
                      variant="ghost"
                      onPress={() => onHandleItemPress(item)}
                      iconType={item?.icon as IconsName}>
                      {item?.label}
                    </Button>
                  )
              )
            )}
      </Box>
    </FocusView>
  );
};

export default userRequired(TvSubMenuArea);
