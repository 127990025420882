const weekdays = [
  'domingo',
  'segunda-feira',
  'terça-feira',
  'quarta-feira',
  'quinta-feira',
  'sexta-feira',
  'sábado',
];

/**
 * Returns the fullname of the weekday in PT-BR
 * @param {number} indexOfWeekday - Index of the weekday that can be obtained using Date.getDay() (0 = Sunday, 6 = Saturday)
 * @returns {string} Full name of the weekday in PT-BR (e.g., "segunda-feira")
 */
export const getWeekdayName = (indexOfWeekday: number): string => {
  return weekdays[indexOfWeekday];
};

/**
 * Returns the abbreviation (first 3 characters) of the weekday name.
 * @param {number} indexOfWeekday - Index of the weekday that can be obtained using Date.getDay() (0 = Sunday, 6 = Saturday)
 * @returns {string} Abbreviated weekday name (e.g., "seg").
 */
export const getWeekdayAbbreviation = (indexOfWeekday: number): string => {
  return getWeekdayName(indexOfWeekday).substring(0, 3);
};

/**
 * Converts a Date to a string with the H separator format '21h20' (hours and minutes),
 * @param {Date} date - The Date object that contains the time to be formatted.
 * @returns {string} The time in the format '21h20', with two digits for both hours and minutes.
 */
export const formatTimeWithHSeparator = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}h${formattedMinutes}`;
};

/**
 * Checks if the provided date is the same as the current date (today).
 * Compares only the date (year, month, and day), ignoring the time.
 * @param {Date} date - The Date object to check.
 * @returns {boolean} True if the provided date is today, otherwise false.
 */
export const isToday = (date: Date): boolean => {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};
