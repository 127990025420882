import { Pressable, Text, View } from '@gluestack-ui/themed';
import { NavigationContainerRefWithCurrent } from '@react-navigation/native';
import { useBottomSheet } from 'context/BottomSheetContext';
import * as WebBrowser from 'expo-web-browser';
import { useRef } from 'react';
import { Platform, Linking } from 'react-native';
import { WebView } from 'react-native-webview';

import { BottomSheet } from '../BottomSheet/BottomSheet';
import LiveMenuButton from './LiveMenuButton';

import { Icons } from 'components/Icons';

export const LiveSheet: React.FC<{ navigationRef?: NavigationContainerRefWithCurrent<any> }> = ({
  navigationRef,
}) => {
  const { currentMenu, handleToggleSheet, currentUrl, visible, liveMenuItems } = useBottomSheet();
  const webViewRef = useRef<WebView>(null);

  const handleWebBrowser = async (url: string) => {
    await WebBrowser.openBrowserAsync(url);
  };

  const countAndAddSlash = (input: string): string => {
    let count = 0;
    for (let char of input) {
      if (char === '/') {
        count++;
      }
    }
    if (count < 3) {
      input += '/';
    }
    return input;
  };

  const handleNavigationStateChange = (navState: any) => {
    if (navState.url.includes('https://login')) {
      handleToggleSheet();
      Linking.openURL(navState.url);
      return;
    }

    if (navState.url !== countAndAddSlash(currentUrl) && !navState.loading) {
      webViewRef?.current?.stopLoading?.();
      handleWebBrowser(navState.url);
      webViewRef?.current?.goBack();
    }
  };

  const handleComponent = () => {
    return (
      <View
        h={60}
        bg="#F5F7FA"
        borderTopLeftRadius={16}
        borderTopRightRadius={16}
        borderBottomColor="#D1D2D7"
        borderBottomWidth={1}>
        <View h={5} w={40} borderRadius={5} backgroundColor="#070D314D" alignSelf="center" />

        <View paddingHorizontal={20} flexDirection="row" pt={12}>
          <View flexDirection="row" flex={1} alignItems="center">
            <LiveMenuButton disabled>
              <Text fontSize={16} fontWeight="600" lineHeight={24} marginRight={15}>
                {currentMenu?.name}
              </Text>
              {!!liveMenuItems.length && <Icons.ChevronDown color={'#555555'} />}
            </LiveMenuButton>
          </View>
          <Pressable onPress={handleToggleSheet}>
            <Icons.Xmark color={'#555555'} />
          </Pressable>
        </View>
      </View>
    );
  };

  if (!visible) {
    return <></>;
  }

  return (
    <BottomSheet navigationRef={navigationRef}>
      <View flex={1} bg={'$white'}>
        {handleComponent()}
        {Platform.OS === 'web' ? (
          <iframe src={currentUrl} style={{ flex: 1 }} />
        ) : (
          <WebView
            ref={webViewRef}
            nestedScrollEnabled
            onNavigationStateChange={handleNavigationStateChange}
            startInLoadingState={true}
            source={{ uri: currentUrl }}
            style={{ flex: 1 }}
          />
        )}
      </View>
    </BottomSheet>
  );
};
