import { useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { Platform } from 'utils/platform';

import { AnalyticsBaseCustomEvent } from './types';
import { useAnalyticsDriver } from './useAnalyticsDriver';

const getPlatform = () => {
  if (Platform.isTV) return 'tv';
  if (Platform.OS === 'web') return 'web';
  if (['android', 'ios'].includes(Platform.OS)) return 'native';
  return 'unidentified';
};

const getPlatformSpecification = () => {
  if (Platform.isWebTV) return 'ctv';
  if (Platform.isAndroidTV) return 'android_tv';
  if (Platform.isFireTV) return 'fire_tv';
  if (Platform.isMobileWeb('ios') || Platform.OS === 'ios') return 'ios';
  if (Platform.isMobileWeb('android') || Platform.OS === 'android') return 'android';
  if (Platform.OS === 'web') return 'desktop';
  return 'unidentified';
};

const useRouteSafe = () => {
  try {
    return useRoute();
  } catch {
    return {
      name: 'unavailable',
      params: null,
    };
  }
};

export const useAnalytics = () => {
  const route = useRouteSafe();
  const analyticsDriver = useAnalyticsDriver();
  const user = useSelector((state: RootState) => state.auth);
  const profile = useSelector((state: RootState) => state.profile);

  const userId = user.bandId;
  const kidsMode = profile.isKid;
  const getClientId = analyticsDriver.getAppInstanceId as () => Promise<string>;

  const platform = getPlatform();
  const platformSpecification = getPlatformSpecification();

  const pageId = route.name;
  const pageParams = Object.entries(route.params || {})
    .map((k, v) => `${k}=${v}`)
    .join('&');

  const analyticsLogEventWithDebugger = async (
    name: 'pageview' | 'action' | 'view_inicial',
    params?: AnalyticsBaseCustomEvent
  ) => {
    analyticsDriver.logEvent(name, params);
    const enableLog = false;

    if (__DEV__ && enableLog) {
      const obj = {
        ...params,
        date: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
      };

      const options = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: { 'Content-Type': 'application/json' },
      };

      await fetch('http://localhost:3008/logs', options);
    }
  };

  return {
    triggerHomeScreenPageViewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'home_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina inicial',
      });
    },

    triggerUpdateAppActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'action_update_app',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de update do app',
      });
    },

    triggerWatchScreenPageViewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'watch_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina "assista"',
      });
    },

    triggerListenScreenPageViewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'listen_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina "ouça"',
      });
    },

    triggerLiveScreenPageViewEvent: async (data: {
      programTitle: string;
      programAgeRating: string;
      programId: string;
    }) => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'live_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina "ao vivo"',
        program_id: data.programId,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
      });
    },

    triggerMovieScreenPageViewEvent: async (data: {
      programId: string;
      programTitle: string;
      programAgeRating: string;
      programGenre: string;
      programDirector: string;
      programCast: string;
      programCategory: string;
      programAdUnit: string;
    }) => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'movie_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina de um filme em especifico',
        program_id: data.programId,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_genre: data.programGenre,
        program_director: data.programDirector,
        program_cast: data.programCast,
        program_category: data.programCategory,
        program_ad_unit: data.programAdUnit,
      });
    },

    triggerPodcastScreenPageViewEvent: async (data: {
      programId: string;
      programTitle: string;
      programAgeRating: string;
      programGenre: string;
      programCategory: string;
      programAdUnit: string;
    }) => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'podcast_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina de um podcast em especifico',
        program_id: data.programId,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_genre: data.programGenre,
        program_category: data.programCategory,
        program_ad_unit: data.programAdUnit,
      });
    },

    triggerRadioScreenPageViewEvent: async (data: {
      programId: string;
      programTitle: string;
      programAgeRating: string;
      programGenre: string;
      programCategory: string;
      programAdUnit: string;
    }) => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'radio_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina de um programa de radio em especifico',
        program_id: data.programId,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_genre: data.programGenre,
        program_category: data.programCategory,
        program_ad_unit: data.programAdUnit,
      });
    },

    triggerSeasonalScreenPageViewEvent: async (data: {
      programId: string;
      programTitle: string;
      programAgeRating: string;
      programGenre: string;
      programDirector: string;
      programCast: string;
      programCategory: string;
      programAdUnit: string;
      programSeason: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'seasonal_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina de uma serie em especifico',
        program_id: data.programId,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_genre: data.programGenre,
        program_director: data.programDirector,
        program_cast: data.programCast,
        program_category: data.programCategory,
        program_ad_unit: data.programAdUnit,
        program_season: data.programSeason,
        program_episode_title: data.programEpTitle,
      });
    },

    triggerFaqScreenPageViewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'faq_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina de perguntas frequentes',
      });
    },

    triggerLegalTermsScreenPageViewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'legal_terms_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina de termos legais',
      });
    },

    triggerContactUsScreenPageViewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'contact_us_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina de contato',
      });
    },

    triggerCategoryScreenPageViewEvent: async (data: {
      categoryId: string;
      categoryName: string;
    }) => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'category_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina de uma categoria especifica',
        category_id: data.categoryId,
        category_name: data.categoryName,
      });
    },

    triggerFavoritesScreenPageViewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'favorites_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina minha lista',
      });
    },

    triggerOnboardingScreenPageViewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'onboarding_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina de onboarding',
      });
    },

    triggerSearchScreenPageViewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'search_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina de pesquisa',
      });
    },

    triggerLoginScreenPageViewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'login_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina de apresentacao de login',
      });
    },

    triggerMyProfileScreenPageViewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'my_profile_screen_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visita a pagina meu perfil',
      });
    },

    // @TODO: precisa ser consumido nos componentes
    triggerHeaderLogoActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'header_logo_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com logo no header',
      });
    },

    // @TODO: precisa ser consumido nos componentes
    triggerNavigationBackActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'navigation_back_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao de voltar navegacao',
      });
    },

    triggerLoginActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'login_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de fazer login',
      });
    },

    triggerCreateAccountActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'create_account_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de criar nova conta',
      });
    },

    triggerLogoutActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'logout_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao fazer logout',
      });
    },

    triggerKeepKidsModeActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'keep_kids_mode_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao para continuar no modo infantil',
      });
    },

    triggerLoginAgainActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'login_again_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao para fazer login novamente e sair do modo infantil',
      });
    },

    // @TODO: precisa ser consumido nos componentes
    triggerSeeMoreActionEvent: async (data: { shelfTitle: string; navigationTarget: string }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'see_more_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de ver mais nas prateleiras',
        shelf_title: data.shelfTitle,
        navigation_target: data.navigationTarget,
      });
    },

    triggerMediaEnableVideoActionEvent: async (data: {
      programTitle: string;
      programAgeRating: string;
      programId: string;
      programSeason?: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'media_enable_video_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao habilitar video nos podcasts e radio',
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
        program_season: data.programSeason,
        program_ep_title: data.programEpTitle,
      });
    },

    triggerMediaDisableVideoActionEvent: async (data: {
      programTitle: string;
      programAgeRating: string;
      programId: string;
      programSeason?: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'media_disable_video_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao desabilitar video nos podcasts e radio',
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
        program_season: data.programSeason,
        program_ep_title: data.programEpTitle,
      });
    },

    triggerMediaPlayActionEvent: async (data: {
      mediaType: 'audio' | 'video';
      programTitle: string;
      programAgeRating: string;
      programId: string;
      programSeason?: string;
      programEpTitle?: string;
      ClickWatchTrailer?: boolean;
      ClickToExternalLink?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'media_play_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao "play" de um player',
        media_type: data.mediaType,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
        program_season: data.programSeason,
        program_ep_title: data.programEpTitle,
        button_assistirEP_max: data.ClickToExternalLink,
        button_assistirTrailer_max: data.ClickWatchTrailer,
      });
    },

    triggerPlayerEvent: async (data: {
      mediaType: 'audio' | 'video';
      programTitle: string;
      programAgeRating: string;
      programId: string;
      programSeason?: string;
      programEpTitle?: string;
      ClickWatchTrailer?: boolean;
      ClickToExternalLink?: string;
    }) => {
      await analyticsLogEventWithDebugger('view_inicial', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'view_inicial',
        action_id: 'player_view_inicial',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao "play" de um player',
        media_type: data.mediaType,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
        program_season: data.programSeason,
        program_ep_title: data.programEpTitle,
        button_assistirEP_max: data.ClickToExternalLink,
        button_assistirTrailer_max: data.ClickWatchTrailer,
      });
    },

    triggerMediaPauseActionEvent: async (data: {
      mediaType: 'audio' | 'video';
      programTitle: string;
      programAgeRating: string;
      programId: string;
      programSeason?: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'media_pause_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao "pause" de um player',
        media_type: data.mediaType,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
        program_season: data.programSeason,
        program_ep_title: data.programEpTitle,
      });
    },

    triggerMediaPreviousActionEvent: async (data: {
      mediaType: 'audio' | 'video';
      programTitle: string;
      programAgeRating: string;
      programId: string;
      programSeason?: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'media_previous_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao "previous" de um player',
        media_type: data.mediaType,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
        program_season: data.programSeason,
        program_ep_title: data.programEpTitle,
      });
    },

    triggerMediaNextActionEvent: async (data: {
      mediaType: 'audio' | 'video';
      programTitle: string;
      programAgeRating: string;
      programId: string;
      programSeason?: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'media_next_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao "next" de um player',
        media_type: data.mediaType,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
        program_season: data.programSeason,
        program_ep_title: data.programEpTitle,
      });
    },

    // @TODO: precisa ser consumido nos componentes
    triggerMediaFullscreenActionEvent: async (data: {
      mediaType: 'audio' | 'video';
      playerCurrentStatus: 'fullscreen' | 'windowed';
      playerDesiredStatus: 'fullscreen' | 'windowed';
      programTitle: string;
      programAgeRating: string;
      programId: string;
      programSeason?: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'media_fullscreen_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao "fullscreen" de um player',
        media_type: data.mediaType,
        player_current_status: data.playerCurrentStatus,
        player_desired_status: data.playerDesiredStatus,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
        program_season: data.programSeason,
        program_ep_title: data.programEpTitle,
      });
    },

    triggerMediaWatchedActionEvent: async (data: {
      mediaType: 'audio' | 'video';
      mediaDuration: number;
      mediaCurrentTime: number;
      programTitle: string;
      programAgeRating: string;
      programId: string;
      programSeason?: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'media_watched_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'uma media especifica foi assistida por um determinado tempo',
        media_type: data.mediaType,
        media_duration: data.mediaDuration,
        media_current_time: data.mediaCurrentTime,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
        program_season: data.programSeason,
        program_ep_title: data.programEpTitle,
      });
    },

    triggerMediaAutonextCancelActionEvent: async (data: {
      mediaType: 'audio' | 'video';
      programTitle: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'media_autonext_cancel_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de cancelar autonext',
        media_type: data.mediaType,
        program_title: data.programTitle,
        program_ep_title: data.programEpTitle,
      });
    },

    triggerMediaAutonextImmediateActionEvent: async (data: {
      mediaType: 'audio' | 'video';
      programTitle: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'media_autonext_immediate_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao ir para o proximo episodio antes do timer acabar',
        media_type: data.mediaType,
        program_title: data.programTitle,
        program_ep_title: data.programEpTitle,
      });
    },

    triggerMediaAutonextTimerActionEvent: async (data: {
      mediaType: 'audio' | 'video';
      programTitle: string;
      programAgeRating: string;
      programId: string;
      programSeason?: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'media_autonext_timer_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'o timer do autonext atingiu seu limite e a proxima media sera executada',
        media_type: data.mediaType,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
        program_season: data.programSeason,
        program_ep_title: data.programEpTitle,
      });
    },

    // @TODO: precisa ser consumido nos componentes
    triggerCarouselNextActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'carousel_next_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao de ir para o proximo slide de um carrossel',
      });
    },

    // @TODO: precisa ser consumido nos componentes
    triggerCarouselPreviousActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'carousel_previous_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao de ir para o slide anterior de um carrossel',
      });
    },

    // @TODO: precisa ser consumido nos componentes
    triggerUseChromecastActionEvent: async (data: {
      triggerLocation: 'menu' | 'player';
      programTitle?: string;
      programAgeRating?: string;
      programId?: string;
      programSeason?: string;
      programEpTitle?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'use_chromecast_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao de sincronizar com chromecast',
        trigger_location: data.triggerLocation,
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
        program_season: data.programSeason,
        program_ep_title: data.programEpTitle,
      });
    },

    // @TODO: precisa ser consumido nos componentes
    triggerCardClickActionEvent: async (data: {
      cardNavigationTarget: string;
      cardImage: string;
      cardTitle: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'card_click_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'card',
        action_reference: 'interacao com um card especifico',
        card_navigation_target: data.cardNavigationTarget,
        card_image: data.cardImage,
        card_title: data.cardTitle,
      });
    },

    triggerReviewPageviewEvent: async () => {
      await analyticsLogEventWithDebugger('pageview', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'pageview',
        action_id: 'review_pageview',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'visualizacao do modal de review',
      });
    },

    triggerReviewLikeActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'review_like_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de like no modal de review',
      });
    },

    triggerReviewDislikeActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'review_dislike_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de dislike no modal de review',
      });
    },

    triggerChangeToKidsModeActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'change_to_kids_mode_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de mudar para modo infantil no menu principal',
      });
    },

    triggerExitFromKidsModeActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'exit_from_kids_mode_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de sair do modo infantil no menu principal',
      });
    },

    triggerGoToMyProfileActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'go_to_my_profile_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de ir para o meu perfil no menu principal',
      });
    },

    triggerGoToFavoritesActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'go_to_favorites_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de ir para os favoritos no menu principal',
      });
    },

    triggerGoToAgeRatingConfigurationActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'go_to_age_rating_configuration_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference:
          'interacao com botao de ir para as configuracoes de faixa etaria no menu principal',
      });
    },

    triggerGoToFaqActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'go_to_faq_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de ir para a pagina de ajuda no menu principal',
      });
    },

    triggerGoToLoginActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'go_to_login_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com botao de ir para a pagina de login no menu principal',
      });
    },

    triggerLiveMenuItemActionEvent: async (data: {
      liveMenuItemName: string;
      liveMenuItemUrl?: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'live_menu_item_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'labeled_button',
        action_reference: 'interacao com item do live menu',
        live_menu_item_name: data.liveMenuItemName,
        live_menu_item_url: data.liveMenuItemUrl,
      });
    },

    triggerOnEnterPictureInPictureActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'on_enter_picture_in_picture_action_event',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'evento disparado quando pip é ativado',
      });
    },

    triggerOnExitPictureInPictureActionEvent: async () => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'on_exit_picture_in_picture_action_event',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'auto',
        action_reference: 'evento disparado quando pip é desativado',
      });
    },

    triggerAddToFavoritesActionEvent: async (data: {
      programTitle: string;
      programAgeRating: string;
      programId: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'add_to_favorites_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao de adicionar programa aos favoritos',
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
      });
    },

    triggerRemoveToFavoritesActionEvent: async (data: {
      programTitle: string;
      programAgeRating: string;
      programId: string;
    }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'remove_to_favorites_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao de remover programa dos favoritos',
        program_title: data.programTitle,
        program_age_rating: data.programAgeRating,
        program_id: data.programId,
      });
    },

    triggerShelfScrollActionEvent: async (data: { scrollSide: 'left' | 'right' }) => {
      await analyticsLogEventWithDebugger('action', {
        client_id: await getClientId(),
        user_id: userId,
        platform: platform,
        platform_specification: platformSpecification,
        action_type: 'action',
        action_id: 'shelf_scroll_action',
        page_id: pageId,
        page_params: pageParams,
        kids_mode: kidsMode,
        action_trigger_type: 'icon_button',
        action_reference: 'interacao com botao de scrolar prateleira',
        scroll_side: data.scrollSide,
      });
    },
  };
};
