import { Box, Image, Text } from '@gluestack-ui/themed';
import { Slider } from '@miblanchard/react-native-slider';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useKeepAwake } from 'expo-keep-awake';
import userRequired from 'hoc/userRequired';
import { default as AnimatedLottieView, default as LottieView } from 'lottie-react-native';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { ActivityIndicator, AppState, View, useWindowDimensions } from 'react-native';
import { useAnalytics } from 'services/analytics/useAnalytics';
import theme from 'theme';
import { ProgramType } from 'utils/enum';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import TrackPlayer, { Event, useProgress, useSetupPlayer, useTrackPlayerEvents } from './player';

import LottieImage from 'assets/Lottie/playing-radio.json';

import Button from 'components/Button';
import FocusView from 'components/FocusView';
import { Icons } from 'components/Icons';
import { usePlayer } from 'components/Player/context';
import IsLoading from 'components/isLoading';

type RadioProps = {
  streamUrl?: string;
  autoplay?: boolean;
  program?: CmsProgram;
  podcast?: ApiConteudoSpreakerEpisode[];
  selectedIndex?: number;
  handleSelectedIndex?: (ep: number) => void;
  jsxItem?: ReactNode;
  openVideo?: boolean;
  setCloseVideo?: any;
  setOpenVideo?: any;
};

const events = [Event.PlaybackState, Event.PlaybackError, Event.RemotePlay, Event.RemotePause];

const Radio: React.FC<RadioProps> = ({
  program,
  podcast,
  selectedIndex,
  handleSelectedIndex,
  jsxItem,
  openVideo,
  setCloseVideo,
}) => {
  const navigation = useNavigation();
  const { isPlaying: isVideoPlaying, pause } = usePlayer();

  const [isPlaying, setIsPlaying] = useState<undefined | boolean>(undefined);
  const { position, duration } = useProgress();
  const [selectedPodcast, setSelectedPodcast] = useState<ApiConteudoSpreakerEpisode>();
  const [webProgress, setWebProgress] = useState({ duration: 0, position: 0 });
  const isPlayerReady = useSetupPlayer();
  const refLottie = useRef<AnimatedLottieView>(null);
  const { width } = useWindowDimensions();
  const isMobileOrSmallScreen = Platform.OS !== 'web' || width < 996;
  const isFocused = useIsFocused();
  const TogglePlay = isPlaying ? Icons.SolidPauseCircular : Icons.SolidPlayCircular;

  const analytics = useAnalytics();
  const isRadio = program?.type === ProgramType.RADIO;
  const isPodcast = program?.type === ProgramType.PODCAST;
  const [isPlayerLoading, setIsPlayerLoading] = useState(true);

  const trackDuration = (Platform.isWebTV ? webProgress?.duration : duration) || 0;
  const trackPosition = (Platform.isWebTV ? webProgress?.position : position) || 0;

  const getRoleForTv = (condition: boolean) => (condition ? 'button' : 'none');
  const handleSliderSlidingComplete = (value: number[]) => TrackPlayer.seekTo(value[0]);

  const handlePlaySound = async () => {
    if (!openVideo) {
      setIsPlaying(true);
      if (!Platform.isTV) refLottie.current?.play();
      await TrackPlayer.play();
    }
  };

  const handlePauseSound = async () => {
    await TrackPlayer.pause();
    setIsPlaying(false);
    if (!Platform.isTV) refLottie.current?.pause();
  };

  const togglePlaySound = () => {
    if (isPlaying) return handlePauseSound();
    return handlePlaySound();
  };

  function formatDuration(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  async function handlePrevEpisode() {
    const prevEp = selectedIndex !== undefined ? selectedIndex - 1 : undefined;

    if (prevEp !== undefined && prevEp >= 0) {
      analytics.triggerMediaPreviousActionEvent({
        mediaType: 'audio',
        programId: program?._id!,
        programTitle: program?.title!,
        programAgeRating: program?.rating!,
        programEpTitle: selectedPodcast?.title,
      });

      handleSelectedIndex?.(prevEp);
    }
  }

  async function handleNextEpisode() {
    const nextEp = selectedIndex !== undefined ? selectedIndex + 1 : undefined;

    if (podcast?.length && nextEp && nextEp < podcast?.length) {
      analytics.triggerMediaNextActionEvent({
        mediaType: 'audio',
        programId: program?._id!,
        programTitle: program?.title!,
        programAgeRating: program?.rating!,
        programEpTitle: selectedPodcast?.title,
      });

      handleSelectedIndex?.(nextEp);
    }
  }

  useEffect(() => {
    if (isVideoPlaying || openVideo) {
      handlePauseSound();
    }
  }, [isVideoPlaying, openVideo]);

  useEffect(() => {
    if (openVideo || isVideoPlaying) {
      handlePauseSound();
    }
  }, []);

  if (Platform.isAndroidTV) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useKeepAwake();
  }

  useEffect(() => {
    if (Platform.OS !== 'web') {
      const checkIfRadioIsPlaying = async () => {
        const state = await TrackPlayer.getState();
        setIsPlayerLoading(state === 'buffering');

        //for android devices
        if (Platform.OS === 'android' && state === 'paused') {
          setIsPlaying(false);
          refLottie.current?.pause();
        }
      };

      checkIfRadioIsPlaying();

      const subscription = TrackPlayer.addEventListener(Event.PlaybackState, () => {
        checkIfRadioIsPlaying();
      });

      //for ios devices
      const subscriptionRemoteDuck = TrackPlayer.addEventListener(Event.RemoteDuck, () => {
        setIsPlaying(false);
        refLottie.current?.pause();
      });

      return () => {
        subscription.remove();
        subscriptionRemoteDuck.remove();
      };
    }
  }, [program, selectedPodcast, isPlayerReady, openVideo, isFocused]);

  useEffect(() => {
    if (!isPodcast) return;

    const handleEndOfPodcast = async () => {
      try {
        const currentDuration = Number(duration.toFixed(0)) - 1;
        const currentPosition = Number(position.toFixed(0));

        if (duration > 0 && currentDuration <= currentPosition) {
          handlePauseSound();
          await TrackPlayer.seekTo(0);

          if (!!podcast?.length && selectedIndex !== podcast?.length - 1) {
            setTimeout(() => {
              handleNextEpisode();
            }, 1000);
          }
        }
      } catch (e) {}
    };

    handleEndOfPodcast();
  }, [position, selectedPodcast]);

  useTrackPlayerEvents(events, (event) => {
    if (event.type === Event.PlaybackError) {
      handleChangeTrack();
    }

    if (event.type === Event.RemotePlay) {
      setCloseVideo?.();
      pause();
      if (!isPlaying && !openVideo) {
        handlePlaySound();
      }
    }
    if (event.type === Event.RemotePause) {
      if (isPlaying) {
        handlePauseSound();
      }
    }
  });

  useEffect(() => {
    if (selectedIndex !== undefined) {
      setSelectedPodcast(podcast?.[selectedIndex]);
    }
  }, [selectedIndex, podcast]);

  useEffect(() => {
    const unsubscrible = navigation.addListener('beforeRemove', async () => {
      setIsPlaying(false);
      await TrackPlayer.reset();
    });

    return unsubscrible;
  }, [navigation]);

  useEffect(() => {
    const unsubscribeBlur = navigation.addListener('blur', async () => {
      if (Platform.OS === 'web' || Platform.isTV) {
        setIsPlaying(false);
        await TrackPlayer.reset();
      }
    });

    return unsubscribeBlur;
  }, [navigation]);

  useEffect(() => {
    if (Platform.isAndroidTV) {
      AppState.addEventListener('change', async (nextAppState) => {
        if (nextAppState === 'background') {
          handlePauseSound();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (Platform.isWebTV) {
      const getWebPosition = async () => {
        try {
          const progressData = await TrackPlayer.getProgress();

          if (progressData && progressData.position) {
            setWebProgress(progressData);
          }
        } catch (error) {
          console.error('Error getting track progress:', error);
        }
      };

      getWebPosition();

      const interval = setInterval(getWebPosition, 1000);

      return () => clearInterval(interval);
    }
  }, []);

  const handleChangeTrack = async () => {
    if (!openVideo) {
      if ((selectedPodcast?.streamUrl || program?.radioId) && isPlayerReady) {
        if (Platform.OS !== 'web') {
          await TrackPlayer.setVolume(1);
        }

        const track: any = {
          url: selectedPodcast?.streamUrl ?? program?.radioId,
          title: selectedPodcast?.title ?? program?.title,
          artist: program?.title ?? '',
          type: program?.type === ProgramType.PODCAST ? undefined : 'hls',
          contentType: program?.type === ProgramType.PODCAST ? 'audio/mpeg' : 'audio/mp4',
          userAgent:
            'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 Safari/537.36',
          artwork: program?.imageVertical?.url ?? '',
        };

        setIsPlaying(false);
        await TrackPlayer.reset();
        await TrackPlayer.add([track]);

        if (Platform.isAndroidTV) {
          await TrackPlayer.setVolume(1);
        }

        handlePlaySound();
      }
    }
  };

  useEffect(() => {
    if ((selectedPodcast !== undefined || program?.radioId) && !openVideo && isFocused) {
      handleChangeTrack();
    }
  }, [program, selectedPodcast, isPlayerReady, openVideo, isFocused]);

  return (
    <FocusView>
      <Box
        nativeID="radio-container"
        mx={calcSizeForDevice(40)}
        alignItems="center"
        alignSelf="center"
        justifyContent="center"
        w="90%">
        <View
          style={{
            marginVertical: calcSizeForDevice(40),
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}>
          {(!isMobileOrSmallScreen || !openVideo) && program?.type === ProgramType.RADIO && (
            <Image
              source={{ uri: program?.imageHorizontal?.url }}
              width={calcSizeForDevice(178)}
              height={calcSizeForDevice(178)}
              borderRadius={8}
              mb={calcSizeForDevice(30)}
            />
          )}
          <Text
            color="white"
            fontSize={calcSizeForDevice(20, 30)}
            lineHeight={calcSizeForDevice(30, 40)}
            fontFamily={theme.fonts.inter.bold}
            mb={calcSizeForDevice(10, 20)}>
            {Platform.isTV && !isRadio ? podcast?.[selectedIndex!]?.title : program?.title}
          </Text>
          {jsxItem}
          {!openVideo && Platform.isTV && !isPlayerReady && (
            <Box mt="$8">
              <IsLoading />
            </Box>
          )}
        </View>
        {!openVideo && (Platform.isTV || Platform.OS !== 'web') && isPlayerReady && (
          <>
            <Slider
              containerStyle={{ width: '100%' }}
              minimumValue={0}
              maximumValue={trackDuration}
              value={isRadio ? trackDuration : trackPosition}
              onSlidingComplete={!isRadio ? handleSliderSlidingComplete : () => {}}
              minimumTrackTintColor={isRadio ? 'red' : 'white'}
              thumbTintColor="white"
              renderThumbComponent={isRadio ? () => <></> : undefined}
              maximumTrackTintColor={isRadio ? 'red' : 'white'}
            />
            <Box flexDirection="row" justifyContent="space-between" width="$full">
              <Text
                fontSize={calcSizeForDevice(12, 20)}
                color={isRadio ? 'red' : 'white'}
                fontFamily={isRadio ? theme.fonts.inter.bold : theme.fonts.inter.thin}>
                {isRadio ? 'Ao Vivo' : formatDuration(trackPosition)}
              </Text>

              {(isRadio || isPodcast) && Platform.OS !== 'web' && isPlayerLoading ? (
                <ActivityIndicator size="small" color="#FFFFFF" />
              ) : (
                !Platform.isTV && (
                  <LottieView
                    style={{ width: 30, height: 25 }}
                    // Find more Lottie files at https://lottiefiles.com/featured
                    source={LottieImage}
                    autoPlay={isPlaying}
                    ref={refLottie}
                  />
                )
              )}

              {!isRadio && (
                <Text
                  fontSize={calcSizeForDevice(12, 20)}
                  color={isRadio ? 'red' : 'white'}
                  fontFamily={isRadio ? theme.fonts.inter.bold : theme.fonts.inter.thin}>
                  {formatDuration(trackDuration)}
                </Text>
              )}
            </Box>
            <Box flexDirection="row" justifyContent="space-between" alignItems="center" mt={15}>
              {!!podcast?.length && (
                <Button
                  useTVFocus
                  variant="clean"
                  role={getRoleForTv(selectedIndex !== 0)}
                  onPress={handlePrevEpisode}
                  disabled={!isPlayerReady}>
                  <Icons.SolidBackwardStep
                    width={calcSizeForDevice(70, 90).toString()}
                    height={calcSizeForDevice(70, 90).toString()}
                    color={selectedIndex === 0 ? '#efefef4d' : '#FFF'}
                  />
                </Button>
              )}

              <Button
                variant="clean"
                role="link"
                useTVFocus
                hasTVPreferredFocus
                onPress={togglePlaySound}>
                <TogglePlay
                  width={calcSizeForDevice(70, 90).toString()}
                  height={calcSizeForDevice(70, 90).toString()}
                />
              </Button>

              {!!podcast?.length && (
                <Button
                  variant="clean"
                  useTVFocus
                  role={getRoleForTv(isPlayerReady)}
                  onPress={handleNextEpisode}
                  disabled={!isPlayerReady}>
                  <Icons.SolidFowardStep
                    width={calcSizeForDevice(70, 90).toString()}
                    height={calcSizeForDevice(70, 90).toString()}
                    color={selectedIndex === podcast.length - 1 ? '#efefef4d' : '#FFF'}
                  />
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
    </FocusView>
  );
};

export default userRequired(Radio);
