import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';
import { useAnalytics } from 'services/analytics/useAnalytics';

import TvHelpScreenLayout from 'components/Help/TvHelpScreenLayout.ctv';
import TvQrContentLayout from 'components/Help/TvQRContentLayout.ctv';

const TvLegalTermsScreen: React.FC = () => {
  const analytics = useAnalytics();

  useFocusEffect(
    useCallback(() => {
      analytics.triggerLegalTermsScreenPageViewEvent();
    }, [])
  );

  return (
    <TvHelpScreenLayout>
      <TvQrContentLayout
        title="Termos e Condições"
        url="bandplay.com/termos"
        qrCodeValue="https://bandplay.com/termos"
      />
    </TvHelpScreenLayout>
  );
};

export default TvLegalTermsScreen;
