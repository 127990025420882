import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';
import { useAnalytics } from 'services/analytics/useAnalytics';

import TvHelpScreenLayout from 'components/Help/TvHelpScreenLayout.ctv';
import TvQrContentLayout from 'components/Help/TvQRContentLayout.ctv';

const TvMyProfile: React.FC = () => {
  const analytics = useAnalytics();

  useFocusEffect(
    useCallback(() => {
      analytics.triggerMyProfileScreenPageViewEvent();
    }, [])
  );

  return (
    <TvHelpScreenLayout>
      <TvQrContentLayout
        title="Meu perfil"
        url="login.band.com.br"
        qrCodeValue="https://login.band.com.br/"
      />
    </TvHelpScreenLayout>
  );
};

export default TvMyProfile;
