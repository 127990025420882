import {
  NavigationContainerRefWithCurrent,
  ParamListBase,
  Route,
  RouteProp,
} from '@react-navigation/native';
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  MAX_TRANSLATE_Y,
  MID_TRANSLATE_Y,
  MID_TRANSLATE_Y_WEB,
  MIN_TRANSLATE_Y,
  SCREEN_HEIGHT,
  useBottomSheet,
} from 'context/BottomSheetContext';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Platform } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, { runOnJS, useAnimatedStyle, withSpring } from 'react-native-reanimated';

import useSuperiorNavbar from 'components/Header/helpers/useSuperiorNavbar';

type BottomSheetProps = {
  children: React.ReactNode;
  navigationRef?: NavigationContainerRefWithCurrent<any>;
};

export const BottomSheet: React.FC<BottomSheetProps> = ({ children, navigationRef }) => {
  const { translateY, context, setVisible } = useBottomSheet();
  const currentRoute: Route<string> | undefined = navigationRef?.current?.getCurrentRoute();
  const hasSuperiorNavbar = useSuperiorNavbar(currentRoute as RouteProp<ParamListBase, string>);
  const isProgramRoute = currentRoute?.name === 'Program';
  const shouldAddGap = isProgramRoute && !hasSuperiorNavbar;
  const screenGap = shouldAddGap ? 40 : 0;

  const styles = useMemo(() => {
    return createStyles(screenGap);
  }, [screenGap]);

  const gesture = Gesture.Pan()
    .onStart(() => {
      context.value = { y: translateY.value };
    })
    .onUpdate((e) => {
      translateY.value = e.translationY + context.value.y;
      translateY.value = Math.max(translateY.value, -MAX_TRANSLATE_Y);
    })
    .onEnd(() => {
      const MID_TRANSLATE_Y_CUSTOM = Platform.OS === 'web' ? MID_TRANSLATE_Y_WEB : MID_TRANSLATE_Y;

      if (
        translateY.value > -MID_TRANSLATE_Y_CUSTOM * 1.3 &&
        translateY.value < -MID_TRANSLATE_Y_CUSTOM * 0.5
      ) {
        translateY.value = withSpring(-MID_TRANSLATE_Y_CUSTOM);
      } else if (translateY.value > -MIN_TRANSLATE_Y) {
        translateY.value = withSpring(SCREEN_HEIGHT);
        runOnJS(setVisible)(false);
      } else if (translateY.value < -MIN_TRANSLATE_Y) {
        translateY.value = withSpring(-MAX_TRANSLATE_Y);
      }
    });

  const reanimatedBottomStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }],
    };
  });

  return (
    <GestureDetector gesture={gesture}>
      <Animated.View style={[styles?.bottomsheet_container, reanimatedBottomStyle]}>
        {children}
      </Animated.View>
    </GestureDetector>
  );
};

const createStyles = (screenGap: number) =>
  StyleSheet.create({
    bottomsheet_container: {
      width: '100%',
      height:
        SCREEN_HEIGHT -
        (Platform.OS !== 'ios' ? 70 : 90) -
        HEADER_HEIGHT -
        FOOTER_HEIGHT +
        screenGap,
      backgroundColor: '#F5F7FA',
      position: 'absolute',
      top: SCREEN_HEIGHT / (Platform.OS === 'web' ? 1.5 : 1.31) - screenGap,
      zIndex: 3,
      borderTopRightRadius: 25,
      borderTopLeftRadius: 25,
      paddingTop: 20,
    },
  });
