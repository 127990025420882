import { useGlobalConfig } from 'context/GlobalContext';
import { TourStep, offset } from 'react-native-spotlight-tour';
import { useDispatch } from 'react-redux';
import { setHasCompletedNavigationTour } from 'store/slices/userDeviceSessionData';

import SpotlightComponent from '.';

export const useTourSteps = (): TourStep[] => {
  const { spotlightData } = useGlobalConfig();
  const dispatch = useDispatch();
  return [
    {
      shape: 'rectangle',
      floatingProps: {
        placement: 'bottom',
        middleware: [
          offset({
            crossAxis: 1,
            mainAxis: 5,
          }),
        ],
      },
      render: (props) => (
        <SpotlightComponent
          arrowPosition="top"
          arrowAlign="center"
          spotlightTitle={spotlightData?.[0].spotlightTitle}
          spotlightDescription={spotlightData?.[0].spotlightDescription}
          {...props}
        />
      ),
      onBackdropPress: 'continue',
    },
    {
      shape: 'rectangle',
      floatingProps: {
        placement: 'top',
        middleware: [
          offset({
            crossAxis: 1,
            mainAxis: 5,
          }),
        ],
      },
      render: (props) => (
        <SpotlightComponent
          arrowPosition="bottom"
          arrowAlign="center"
          isLastItem
          spotlightTitle={spotlightData?.[1].spotlightTitle}
          spotlightDescription={spotlightData?.[1].spotlightDescription}
          {...props}
        />
      ),
      onBackdropPress({ stop }) {
        dispatch(setHasCompletedNavigationTour());
        stop();
      },
    },
  ];
};
