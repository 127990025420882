import { Box, HStack, View } from '@gluestack-ui/themed';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import { useSocket } from 'context/SocketContext';
import { useMobileOrDesktop } from 'hoc/mobileOrDesktop';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useWindowDimensions } from 'react-native';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { useGetWatchingListQuery } from 'services/backend';
import { useGetMediasQuery, useLazyGetMediasQuery } from 'services/cms';
import theme from 'theme';
import { ProgramType } from 'utils/enum';
import { environmentUrl } from 'utils/environment';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import Button from 'components/Button';
import Hero from 'components/Hero';
import Player from 'components/Player';
import { usePlayer } from 'components/Player/context';
import ProgramContent from 'components/Program/Content';
import ProgramEpisodes from 'components/Program/Episodes';
import ProgramHeader from 'components/Program/Header';
import ProgramSave from 'components/Program/Save';
import ProgramShared from 'components/Program/Shared';
import { useProgram } from 'components/Program/context';
import IsLoading from 'components/isLoading';

const parseEpisodes = (
  rawEpisodes: CmsApiMediaList | undefined,
  _episodesWatchedData: BackendGenericList<BackendWatching> | undefined
) => {
  if (!rawEpisodes || !rawEpisodes.items) return undefined;

  const baseWhatchedDataItems = _episodesWatchedData?.items || [];

  const episodesWatchedData = Object.values(
    baseWhatchedDataItems.reduce((acc: Record<string, BackendWatching>, curr: BackendWatching) => {
      if (!acc[curr.trackId] || new Date(curr.updatedAt) > new Date(acc[curr.trackId].updatedAt)) {
        acc[curr.trackId] = curr;
      }

      return acc;
    }, {})
  );

  const episodesWithWatchedData = rawEpisodes.items.map((ep) => {
    const relatedWatchedData = episodesWatchedData
      ? episodesWatchedData.find((wd) => wd.mediaId === ep._id)
      : undefined;

    const percentageWatched = relatedWatchedData
      ? (relatedWatchedData.watchedTime * 100) / (relatedWatchedData?.duration ?? 0)
      : 0;

    return { ...ep, percentageWatched };
  });

  return { ...rawEpisodes, items: episodesWithWatchedData as any };
};

type SeasonalScreenProps = RootStackScreenProps<'Program'> & {
  actual: any;
  selected: any;
  sharePath: any;
  setSelected: any;
  isFetching: boolean;
  program: CmsProgram;
  episodes: BackendGenericList<BackendWatching> | undefined;
  episodesWatchedData: BackendGenericList<BackendWatching> | undefined;
};

const SeasonalScreen: React.FC<SeasonalScreenProps> = (props) => {
  const {
    route,
    actual,
    program,
    selected,
    episodes,
    sharePath,
    navigation,
    isFetching,
    setSelected,
    episodesWatchedData,
  } = props;

  const { watch } = useSocket();
  const analytics = useAnalytics();
  const isFocused = useIsFocused();
  const { width } = useWindowDimensions();
  const { ads, colors } = useGlobalConfig();
  const [getMediasQuery] = useLazyGetMediasQuery();
  const [isActive, setIsActive] = useState(false);
  const [mediaId, setMediaId] = useState<string>();
  const [startTime, setStartTime] = useState<number>();
  const [movingToNextEp, setMovingToNextEp] = useState<boolean>(false);
  const [episode, setEpisode] = useState<CmsApiItem<CmsApiMediaList>>();
  const [dropDownSeasonsOrganization, setDropDownSeasonsOrganization] = useState(program?.seasons);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);

  const {
    play,
    mute,
    pause,
    isVisible,
    isPlaying,
    isStreaming,
    isFullScreen,
    setIsVisible,
    setNextTrack,
  } = usePlayer();

  useEffect(() => {
    if (!isFocused) {
      setMediaId(undefined);
      setEpisode(undefined);
      setSelected(undefined);
    }
  }, [isFocused]);

  const setEpisodeProxy = (value: any) => {
    if (Platform.isAndroidTV) setIsVisible(true);
    setEpisode(value);
    play();
  };

  useEffect(() => {
    if (program?.season_order === 'order') {
      setDropDownSeasonsOrganization([...program.seasons].reverse());
      return;
    }

    setDropDownSeasonsOrganization(program?.seasons);
  }, []);

  useEffect(() => {
    if (route.params.mediaId && episodes) {
      const ep = episodes.items.find((e) => e.mediaId === route.params.mediaId)!;
      setEpisode(ep as any);
    }
  }, [episodes, route.params.mediaId]);

  useEffect(() => {
    return navigation.addListener('beforeRemove', async () => {
      if (isPlaying && !isStreaming) pause();
      mute(false);
    });
  }, [navigation, isPlaying, isStreaming, pause]);

  useFocusEffect(
    useCallback(() => {
      setIsActive(true);

      return () => {
        if (!isStreaming) pause();
        setIsActive(false);
      };
    }, [isStreaming])
  );

  useFocusEffect(
    useCallback(() => {
      analytics.triggerSeasonalScreenPageViewEvent({
        programId: program._id,
        programTitle: program.title,
        programAgeRating: program.rating,
        programAdUnit: program.ads?.adunit?.val!,
        programCast: program.cast,
        programCategory: program.category.map((tag) => tag.name).join(', '),
        programDirector: program.director,
        programGenre: program.genre.map((genre) => genre.name).join(', '),
        programSeason: actual?.name,
        programEpTitle: episode?.title,
      });
    }, [program, actual])
  );

  const playerProps: PlayerProps | undefined = useMemo(() => {
    if (!program) {
      return undefined;
    }

    const objLogPage = {
      season: actual?.name,
      page_title: program?.title,
      midia: '',
    };

    episode?.title && (objLogPage.midia = episode?.title);

    const media: PlayerMedia = {
      title: episode ? episode.title : program.title,
      image: [
        program.imageVertical?.url!,
        program.imageHorizontal?.url!,
        episode?.imageVertical?.url,
        episode?.imageHorizontal?.url,
      ],
      duration: episode ? episode.duration : program.duration,
      programId: program._id,
      programTitle: program.title,
      programUrl: program.url,
      mediaId: episode?._id,
      ratingValue: program.rating,
      ratingDescription: program.rateDescription,
      videoTracks: [],
    };

    const mediaIdDub = episode?.mediaIdDub || program.mediaIdDub;

    if (mediaIdDub) {
      media.videoTracks.push({
        language: 'dub',
        trackId: mediaIdDub,
      });
    }

    media.videoTracks.push({ language: 'origin', trackId: episode?.mediaId || program.mediaId });

    return {
      media,
      isLive: program.type == ProgramType.LIVE || program.type == ProgramType.RADIO,
      adTagUrl:
        ads?.disableAds || program.ads?.disableAds
          ? undefined
          : ads?.vast.replace(
              /iu=([^&]+)/,
              (_, original) => `iu=${program.ads?.adunit?.val ?? original}`
            ),
      adsExpirationTime: ads?.adsExpirationTime,
    };
  }, [program, mediaId, episode]);

  useEffect(() => {
    (async () => {
      let nextEp: CmsMedia | undefined;

      const orderedEps = episodes?.items.sort((a: any, b: any) => b.order - a.order) || [];
      const maxSeasonIndex = program.seasons.length;
      const seasonEpisodesMaxIndex = orderedEps.length! - 1;
      const episodeIndex = orderedEps.findIndex((ep) => ep._id === episode?._id)!;

      if (
        Number.isNaN(episodeIndex) ||
        Number.isNaN(maxSeasonIndex) ||
        Number.isNaN(seasonEpisodesMaxIndex)
      ) {
        return;
      }

      if (episodeIndex <= 0 && actual) {
        const currentSeasonId = actual?._id;
        const currentSeasonIndex = program.seasons.findIndex((s) => s._id === currentSeasonId);

        if (currentSeasonIndex + 1 >= maxSeasonIndex) {
          setNextTrack(undefined);
          return;
        }

        const nextSeasonEps = await getMediasQuery({
          seasons: [program.seasons[currentSeasonIndex + 1]],
          program: program?._id,
          sort: program?.order,
        }).unwrap();

        const parsedEps = parseEpisodes(nextSeasonEps, episodesWatchedData);
        const orderedParsedEps = parsedEps?.items.sort((a: any, b: any) => b.order - a.order) || [];
        nextEp = orderedParsedEps[orderedParsedEps.length - 1];
        setSelected(program.seasons[currentSeasonIndex + 1].id);
      } else {
        nextEp = orderedEps?.[episodeIndex - 1] as any;
      }

      if (nextEp) {
        setNextTrack({
          program: program.title,
          cover: nextEp?.imageHorizontal?.url!,
          description: nextEp?.summary!,
          play: () => {
            setMovingToNextEp(true);

            try {
              setEpisodeProxy(nextEp);
              setMediaId(nextEp?.mediaId);
            } finally {
              setTimeout(() => setMovingToNextEp(false), 2000);
            }
          },
          title: nextEp?.title!,
          displayTimeRemaining: program.autoNextDisplayTimeRemaining,
        });
      } else {
        setNextTrack(undefined);
      }
    })();
  }, [episode]);

  useEffect(() => {
    if (!playerProps || playerProps.isLive) {
      return;
    }

    watch(playerProps).then((data) => {
      if (data) {
        setStartTime(data.watchedTime);
      }
      setIsDataReady(true);
    });
  }, [playerProps]);

  const Video = useMemo(
    () =>
      mediaId !== undefined && isActive && playerProps !== undefined ? (
        <Player {...playerProps} startTime={startTime} autoplay isFullScreen={isFullScreen} />
      ) : undefined,
    [mediaId, isActive, playerProps, startTime]
  );

  const [VideoMobile, VideoDesktop] = useMobileOrDesktop(Video, Video);

  if (Platform.isAndroidTV && isVisible) {
    return VideoDesktop;
  }

  return (
    <>
      {VideoMobile !== undefined ? (
        <View style={{ marginTop: isFullScreen ? 0 : Platform.isMobileWeb() ? 45 : 80 }}>
          {Video}
        </View>
      ) : undefined}
      {Platform.isTV && movingToNextEp
        ? createPortal(
            <View
              left={0}
              w="$full"
              h="$full"
              bg="#070D31"
              zIndex={999}
              justifyContent="center"
              position={'fixed' as any}>
              <IsLoading />
            </View>,
            document.querySelector('#root')!
          )
        : null}
      {(Platform.isAndroidTV || Platform.OS === 'web' || !isFullScreen) && (
        <Hero
          program={program}
          hideCover={mediaId !== undefined}
          numItems={1}
          renderItem={() => (
            <View
              py={calcSizeForDevice(24, 18)}
              backgroundColor={
                Platform.OS !== 'web' && !Platform.isWebTV
                  ? colors || theme.colors.base.darkBlue
                  : width < 1024
                  ? theme.colors.base.darkBlue
                  : ''
              }>
              <ProgramEpisodes
                episodes={episodes as any}
                seasons={dropDownSeasonsOrganization}
                setEpisode={setEpisodeProxy}
                setMediaId={setMediaId}
                setSelected={setSelected}
                selected={selected}
                actual={actual}
                isLoading={isFetching}
              />
            </View>
          )}>
          <Hero.Header
            program={program}
            mediaId={mediaId}
            Aside={
              VideoDesktop != undefined && !Platform.isAndroidTV ? (
                <Box aspectRatio={1 / 0.56} width={Platform.isTV ? 0 : '80%'}>
                  {Video}
                </Box>
              ) : undefined
            }>
            <ProgramHeader />
            {!isDataReady ? (
              <View width={calcSizeForDevice(300, 400)} height={calcSizeForDevice(40, 52)}>
                <IsLoading />
              </View>
            ) : (
              <Button
                hasTVPreferredFocus
                role="link"
                useTVFocus
                style={{
                  ...(Platform.OS === 'web' || Platform.isTV
                    ? {
                        width: calcSizeForDevice(300, 400),
                        display: mediaId && !Platform.isTV ? 'none' : undefined,
                        height: calcSizeForDevice(40, 52),
                      }
                    : { display: mediaId ? 'none' : undefined }),
                }}
                textStyle={{ fontSize: calcSizeForDevice(18, 24) }}
                iconStyle={{ width: calcSizeForDevice(24, 30), height: calcSizeForDevice(24, 30) }}
                iconType="Play"
                variant="gradient"
                size="md"
                isFullWidth={Platform.OS !== 'web' && !Platform.isTV}
                onPress={() => {
                  analytics.triggerMediaPlayActionEvent({
                    mediaType: 'video',
                    programId: program._id,
                    programTitle: program.title,
                    programAgeRating: program.rating,
                  });

                  if (mediaId) {
                    if (Platform.isAndroidTV) setIsVisible(true);
                    play();
                    return;
                  }

                  if (episode) {
                    setMediaId(episode.mediaId);
                  } else {
                    setEpisode(episodes?.items[0] as any);

                    if (actual) {
                      setMediaId(episodes?.items[0].mediaId);
                    } else {
                      setMediaId(program?.mediaId);
                    }
                  }

                  if (Platform.isAndroidTV) setIsVisible(true);
                  play();
                }}>
                {startTime ? 'Continuar assistindo' : 'Assistir'}
              </Button>
            )}
            <HStack
              alignItems="center"
              space="md"
              marginTop={calcSizeForDevice(10, 14)}
              marginBottom={calcSizeForDevice(10, 20)}>
              <ProgramSave />
              <ProgramShared path={sharePath} />
            </HStack>
          </Hero.Header>

          <ProgramContent episode={episode} />
        </Hero>
      )}
    </>
  );
};

const SeasonalScreenPerformaticShield: React.FC<RootStackScreenProps<'Program'>> = (props) => {
  const { route } = props;

  const program = useProgram();
  const baseUrl = environmentUrl();
  const [actual, setActual] = useState<any>();
  const [selected, setSelected] = useState<string>();
  const [sharePath, setSharePath] = useState<string | undefined>(undefined);

  const { data: episodeInfo, isLoading: isEpisodeInfoLoading } = useGetMediasQuery(
    { mediaId: route.params.mediaId },
    { skip: route.params.mediaId === undefined || route.params.mediaId === '' }
  );

  const { data: rawEpisodes } = useGetMediasQuery(
    { seasons: actual?._id, program: program?._id, sort: program?.order },
    { skip: !actual || program?._id === undefined }
  );

  const { data: episodesWatchedData, isFetching: isFetchingWatchedData } = useGetWatchingListQuery(
    { programId: program?._id, inProgress: undefined },
    { skip: program?._id === undefined }
  );

  const episodes = useMemo<BackendGenericList<BackendWatching> | undefined>(
    () => parseEpisodes(rawEpisodes, episodesWatchedData),
    [rawEpisodes, episodesWatchedData]
  );

  const isFetching = useMemo(
    () => isFetchingWatchedData || isEpisodeInfoLoading,
    [isFetchingWatchedData, isEpisodeInfoLoading]
  );

  useEffect(() => {
    const epInfoId = episodeInfo?.items?.[0]?.seasons?.[0]?.id;

    if (route.params.seasonId && epInfoId) {
      setSelected(epInfoId);

      if (Platform.OS === 'web') {
        const title = episodeInfo?.items?.[0]?.title
          .replaceAll('-', '')
          .replaceAll('/', '-')
          .replaceAll(' ', '-');

        const actualSeason = program?.seasons?.find((i) => epInfoId === i.id || epInfoId === i._id);

        setTimeout(() => {
          const mediaId = route.params.mediaId;
          const seasonId = actualSeason?.id;
          const historyUrl = `${baseUrl}/${(program as any).id}/${seasonId}/${title}/${mediaId}`;
          const sharePathUrl = `/${(program as any).id}/${actualSeason?.id}/${title}/${mediaId}`;
          window.history.pushState(null, null as any, historyUrl);
          setSharePath(sharePathUrl);
        }, 500);
      }
    }

    if (route.params.seasonId) {
      if (Platform.OS === 'web') {
        window.history.pushState(null, null as any, `${baseUrl}/${route.params.programId}`);
      } else {
        setSelected(route.params.seasonId);
      }
    }

    setSelected(program?.seasons?.[program.seasons.length - 1]?.id);
  }, [route.params.mediaId, episodeInfo, program]);

  useEffect(() => {
    if (program?.seasons && selected) {
      const actualSeason = program?.seasons?.find(
        (item) => selected === item.id || selected === item._id
      );

      if (!actualSeason) {
        const seasonOrder =
          program?.season_order === '-order' || program?.season_order === undefined
            ? program?.seasons?.[0]
            : program?.seasons?.[program?.seasons.length - 1];

        setSelected(seasonOrder?.id);
      }

      setActual(actualSeason);
    }
  }, [program?.seasons, selected]);

  if (isFetching) return null;

  return (
    <SeasonalScreen
      {...props}
      actual={actual}
      program={program}
      episodes={episodes}
      selected={selected}
      sharePath={sharePath}
      isFetching={isFetching}
      setSelected={setSelected}
      episodesWatchedData={episodesWatchedData}
    />
  );
};

export default SeasonalScreenPerformaticShield;
