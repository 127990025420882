import { Box, Spinner } from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useCallback, useEffect, useState } from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { useGetPodcastQuery } from 'services/apiConteudo';
import { calcSizeForDevice } from 'utils/index';

import Card from 'components/Card';
import Hero from 'components/Hero';
import ProgramContent from 'components/Program/Content';
import { useProgram } from 'components/Program/context';
import Radio from 'components/Radio/Radio';

const PodcastScreen: React.FC<RootStackScreenProps<'Program'>> = () => {
  const program = useProgram();
  const analytics = useAnalytics();
  const [selected, setSelected] = useState<number>(0);

  const [page, setPage] = useState(1);
  const [items, setItems] = useState<any[]>([]);

  const { data: podcast, isFetching } = useGetPodcastQuery({ id: program.podcastId, page });

  const handlePodcast = (actual: number) => {
    setSelected(actual);
  };

  const HandleOnEndReached = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    setItems((old) => [...old, ...(podcast?.data.spreaker_episodes || [])]);
  }, [podcast]);

  useFocusEffect(
    useCallback(() => {
      analytics.triggerPodcastScreenPageViewEvent({
        programId: program._id,
        programTitle: program.title,
        programAgeRating: program.rating,
        programAdUnit: program.ads?.adunit?.val!,
        programCategory: program.category.map((tag) => tag.name).join(', '),
        programGenre: program.genre.map((genre) => genre.name).join(', '),
      });
    }, [])
  );

  if (!podcast) return null;

  return (
    <Hero program={program}>
      <Box>
        <Hero.Header program={program}>
          <Radio
            program={program}
            podcast={items}
            selectedIndex={selected}
            handleSelectedIndex={handlePodcast}
          />

          <ProgramContent podcast={podcast} selectedIndex={selected} />
        </Hero.Header>
      </Box>

      <FlatList
        contentContainerStyle={{ gap: 10 }}
        extraData={podcast}
        data={items}
        removeClippedSubviews={true}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(i, index) => `${i}-${index}`}
        renderItem={({ item, index }) => (
          <TouchableOpacity key={index} onPress={() => handlePodcast(index)}>
            <Card
              type="mediumBoxed"
              title={item.title}
              uri={item.image}
              isPlaying={index === selected}
            />
          </TouchableOpacity>
        )}
        onEndReached={() => HandleOnEndReached()}
        onEndReachedThreshold={0.1}
        ListFooterComponent={() => (
          <>
            {isFetching && (
              <Box my={calcSizeForDevice(50)}>
                <Spinner size={'large'} />
              </Box>
            )}
          </>
        )}
      />
    </Hero>
  );
};

export default PodcastScreen;
