import React, { ReactNode, createContext, useContext, useState } from 'react';

interface ModalContextType {
  closeModal: () => void;
  toggleModal: () => void;
  isModalVisible: boolean;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const closeModal = () => setModalVisible(false);
  const toggleModal = () => setModalVisible(!isModalVisible);

  return (
    <ModalContext.Provider value={{ isModalVisible, toggleModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalConfig = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) throw new Error('useModalConfig must be used within a ModalProvider');
  return context;
};
