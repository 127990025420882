import { useBreakpointValue } from '@gluestack-style/react';
import { useAnalytics } from 'services/analytics/useAnalytics';
import {
  useCreateFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetFavoritesQuery,
} from 'services/backend';
import { calcSizeForDevice } from 'utils/index';

import { useProgram } from './context';

import Button from 'components/Button';

type SaveProps = { program?: CmsProgram; variant?: 'vertical' | 'horizontal' };

const ProgramSave: React.FC<SaveProps> = ({ program, variant = 'vertical' }) => {
  const programContext = useProgram();
  const programData = program || programContext;
  const defaultHorizontalButtonContent = 'Adicionar à lista';

  const horizontalButtonContent = useBreakpointValue({
    base: '',
    lg: defaultHorizontalButtonContent,
    xl: defaultHorizontalButtonContent,
  });

  const analytics = useAnalytics();
  const { data } = useGetFavoritesQuery();
  const findInStorage = data?.items.find((item) => item.programId === programData._id);

  const [createFavorite] = useCreateFavoriteMutation();
  const [deleteFavorite] = useDeleteFavoriteMutation();

  const handleToggleFavorite = () => {
    if (findInStorage) {
      analytics.triggerRemoveToFavoritesActionEvent({
        programId: programData._id!,
        programTitle: programData.title!,
        programAgeRating: program?.rating!,
      });

      deleteFavorite(findInStorage._id).unwrap();
      return;
    }

    analytics.triggerAddToFavoritesActionEvent({
      programId: programData._id!,
      programTitle: programData.title!,
      programAgeRating: program?.rating!,
    });

    createFavorite({
      title: programData.title,
      url: programData.url,
      image: programData.imageVertical?.url!,
      programId: programData._id,
      kids: !!programData.kids,
      rating: programData.rating,
    });
  };

  if (variant === 'horizontal') {
    return (
      <Button
        rounded
        variant="outline"
        borderColor="blue"
        textStyle={{ fontSize: 14 }}
        onPress={handleToggleFavorite}
        iconType={findInStorage ? 'SolidBookmark' : 'Bookmark'}
        iconStyle={{ width: calcSizeForDevice(14, 16), height: calcSizeForDevice(14, 16) }}>
        {horizontalButtonContent}
      </Button>
    );
  }

  return (
    <Button
      useTVFocus
      role="link"
      onPress={handleToggleFavorite}
      variant="ghost"
      size="sm"
      iconType={findInStorage ? 'SolidBookmark' : 'Bookmark'}
      iconStyle={{ width: calcSizeForDevice(24, 30), height: calcSizeForDevice(24, 30) }}
      textStyle={{ fontSize: calcSizeForDevice(14, 20), marginTop: calcSizeForDevice(10) }}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        paddingHorizontal: calcSizeForDevice(5),
        paddingVertical: calcSizeForDevice(10),
        height: calcSizeForDevice(58, 80),
        marginVertical: calcSizeForDevice(20),
      }}>
      Minha lista
    </Button>
  );
};

export default ProgramSave;
