import { Box, Button as IBaseButton, Image, Text, VStack } from '@gluestack-ui/themed';
import { useAnalytics } from 'services/analytics/useAnalytics';

import BandHeaderLogo from 'assets/Header/logoHeader.png';

import { BaseModal, ModalProps } from 'components/Modal';

type ModalConfirmExitKidsProps = Partial<ModalProps> & {
  toggleModal: () => void;
  handleKidLogout: () => void;
};

const ModalConfirmExitKids: React.FC<ModalConfirmExitKidsProps> = ({
  toggleModal,
  handleKidLogout,
  ...rest
}) => {
  const analytics = useAnalytics();

  const loginAgain = () => {
    analytics.triggerLoginAgainActionEvent();
    handleKidLogout();
  };

  const keepInKidsMode = () => {
    analytics.triggerKeepKidsModeActionEvent();
    toggleModal();
  };

  return (
    <BaseModal
      {...rest}
      onClose={() => toggleModal()}
      modalHeader={<Image h={22} w={100} source={BandHeaderLogo} alt="Band Logo" />}
      modalBody={
        <Box flex={1} flexDirection="column" justifyContent="space-between">
          <Box mt={15} mb={15}>
            <Text mb={15} color={'$white'} fontSize={'$lg'} fontWeight={'$bold'}>
              Ao sair do Modo Infantil toda a programação sem filtro de classificação indicativa
              voltará a ser exibida.
            </Text>
            <Text color={'$white'} fontSize={'$lg'} mt={6}>
              Para garantir a segurança de acesso, será necessário logar novamente.
            </Text>
          </Box>
        </Box>
      }
      modalFooter={
        <VStack
          alignItems={'center'}
          space={'md'}
          w="$full"
          justifyContent="center"
          marginVertical={10}
          flexDirection="column">
          <IBaseButton w="$full" bgColor="#2543BE" onPress={loginAgain} variant="solid">
            <Text fontSize={14} fontWeight={'bold'} color="white" textTransform="capitalize">
              Fazer login novamente
            </Text>
          </IBaseButton>
          <IBaseButton
            w="$full"
            sx={{
              _pressed: { background: 'transparent' },
              ':hover': { background: 'transparent' } as any,
            }}
            variant="outline"
            borderWidth={0}
            onPress={keepInKidsMode}>
            <Text fontSize={14} fontWeight={'bold'} color="#839CFF" textTransform="capitalize">
              Continuar no Modo Infantil
            </Text>
          </IBaseButton>
        </VStack>
      }
    />
  );
};

export default ModalConfirmExitKids;
