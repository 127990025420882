import { Box, useBreakpointValue } from '@gluestack-ui/themed';
import withLink from 'hoc/withLink';
import { useMemo } from 'react';
import { calcHeightWithWidth, calcSizeForDevice } from 'utils/index';

import TitleCard from './TitleCard';

import FastImage from 'components/FastImage';

const MidCard: React.FC<CardProps> = ({ uri, crops, title, imgTitle, reference, ...rest }) => {
  const midCardAspectRatio = 2;
  const overrideBaseWidth = rest.override?.cardWidth?.base;
  const overrideLgWidth = rest.override?.cardWidth?.lg || rest.override?.cardWidth?.base;
  const overrideXlWidth = rest.override?.cardWidth?.xl || rest.override?.cardWidth?.base;

  const midCardWidth = useBreakpointValue({
    lg: overrideLgWidth || 159,
    base: overrideBaseWidth || 106,
    xl: overrideXlWidth || calcSizeForDevice(159, 260),
  });

  const midCardHeight = useMemo(
    () => calcHeightWithWidth(midCardWidth, midCardAspectRatio),
    [midCardWidth, midCardAspectRatio]
  );

  return (
    <Box
      width={midCardWidth}
      height={midCardHeight}
      backgroundColor="#070d31"
      borderWidth={3}
      borderColor="#2240FF"
      borderRadius={8}
      overflow="hidden"
      {...rest}>
      {uri ? (
        <FastImage source={{ uri }} style={{ width: '100%', height: '100%' }} />
      ) : (
        <TitleCard title={title || imgTitle} reference={reference} />
      )}
    </Box>
  );
};

export default withLink(MidCard, 'MidCard');
