import { View } from '@gluestack-ui/themed';
import React, { useState } from 'react';
import { FlatList } from 'react-native';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import SectionArrows from './components/SectionArrows';
import SectionHeaderComponent from './components/SectionHeader';
import RankingItem from './components/SectionRankingItem';
import TvRankingItem from './components/SectionTvRankingItem';
import { useHorizontalPagination } from './helpers/pagination';

import FocusView from 'components/FocusView';
import { WebDiv } from 'components/WebDiv';

type RenderItemProps = {
  item: CmsCard;
  index: number;
};

const SectionRanking: React.FC<CmsHomeSectionRanking & { onLoaded?: () => void }> = ({
  editorialCards,
  rankQuantity,
  title: sectionTitle,
  onLoaded,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const rankingQuantity = rankQuantity ?? 5;

  const rankingItems =
    editorialCards?.length >= rankingQuantity ? editorialCards?.slice(0, rankingQuantity) : [];

  const { ref, onScroll, goForward, renderItem, goBackward, canGoForward, canGoBackward } =
    useHorizontalPagination({
      renderItem: ({ item, index }: RenderItemProps) => (
        <RankingItem
          link={item?.link}
          item={item}
          index={index}
          hoveredIndex={hoveredIndex}
          setHoveredIndex={setHoveredIndex}
        />
      ),
      items: rankingItems,
    });

  const renderTvItem = ({ item, index }: RenderItemProps) => (
    <TvRankingItem item={item} index={index} />
  );

  React.useEffect(() => {
    setTimeout(() => onLoaded?.(), 0);
  }, []);

  if (!rankingItems || rankingItems.length === 0) return null;

  return (
    <View ml={24}>
      <WebDiv>
        <FocusView>
          <SectionHeaderComponent title={sectionTitle} />
          <FlatList
            ref={ref}
            data={rankingItems}
            renderItem={Platform.isTV ? renderTvItem : renderItem}
            keyExtractor={(item, index) => `${item?.title}_${index.toString()}`}
            contentContainerStyle={{
              marginLeft: calcSizeForDevice(-60),
              marginRight: calcSizeForDevice(60),
            }}
            onScroll={onScroll}
            horizontal
            showsHorizontalScrollIndicator={false}
          />
        </FocusView>
      </WebDiv>
      {!Platform.isTV && (
        <SectionArrows side="left" onPress={goBackward} isVisible={canGoBackward} />
      )}

      {!Platform.isTV && (
        <SectionArrows side="right" onPress={goForward} isVisible={canGoForward} />
      )}
    </View>
  );
};

export default React.memo(SectionRanking);
