import { Box, Button as IBaseButton, Image, Pressable, Text, View } from '@gluestack-ui/themed';
import { useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { AgeRatingDefinitions } from 'utils/ageRating';
import { calcSizeForDevice } from 'utils/index';

import { useAgePreference } from './useAgePreference';

import BandHeaderLogo from 'assets/Header/logoHeader.png';

import { BaseModal, ModalProps } from 'components/Modal';
import Rating from 'components/Rating';
import IsLoading from 'components/isLoading';

type ModalConfirmExitKidsProps = Partial<ModalProps> & { toggleModal: () => void };

const ModalAgeRating: React.FC<ModalConfirmExitKidsProps> = ({ toggleModal, ...rest }) => {
  const { ageRating: savedAgeRatingPreference } = useSelector((state: RootState) => state.profile);
  const {
    isAgeRatingKeyActive,
    availableAgeRatingKeys,
    setAgeRatingPreference,
    commitAgeRatingPreference,
    isAgeRatingPreferenceLoaded,
    ageRatingPreferenceHelperText,
  } = useAgePreference({ toggleModal, isOpen: rest.isOpen });

  const handleCancelAgeRating = () => {
    setAgeRatingPreference(savedAgeRatingPreference);
    toggleModal();
  };

  const body = (
    <Box py={calcSizeForDevice(16, 40)} flexDirection="column" justifyContent="space-between">
      <Text
        fontSize={calcSizeForDevice(28, 36)}
        lineHeight={calcSizeForDevice(28, 36)}
        color="#7A8CFF"
        fontWeight="$semibold">
        Classificação etária
      </Text>

      <Text color="$white" fontSize={calcSizeForDevice(16, 24)} my={calcSizeForDevice(16, 24)}>
        Selecione a classificação etária para este perfil
      </Text>

      {!isAgeRatingPreferenceLoaded ? (
        <View py={20}>
          <IsLoading />
        </View>
      ) : (
        <View
          sx={{
            '@lg': { flexDirection: 'column' },
            '@base': { flexDirection: 'column-reverse' },
          }}>
          <Text fontSize={calcSizeForDevice(16, 24)} mt={calcSizeForDevice(24)} color="$white">
            {ageRatingPreferenceHelperText}
          </Text>

          <View sx={{ '@base': { flexDirection: 'column' }, '@lg': { flexDirection: 'row' } }}>
            {availableAgeRatingKeys.map((key, index) => {
              const isActive = isAgeRatingKeyActive(key);
              const ageRatingDefinition = AgeRatingDefinitions[key];

              return (
                <Pressable
                  mr={calcSizeForDevice(0, 50)}
                  p={calcSizeForDevice(0, 10)}
                  flex={1}
                  key={key}
                  onFocus={(e) => e.target.blur()}
                  onPress={() => setAgeRatingPreference(key)}
                  sx={{ '@base': { flexDirection: 'row' }, '@lg': { flexDirection: 'column' } }}>
                  <View
                    position="relative"
                    sx={{
                      '@base': { justifyContent: 'flex-start', alignItems: 'center' },
                      '@lg': { justifyContent: 'center', alignItems: 'flex-start' },
                    }}>
                    <Pressable
                      my={calcSizeForDevice(20)}
                      role="button"
                      onFocus={(e) => e.target.blur()}
                      onPress={() => setAgeRatingPreference(key)}
                      p={calcSizeForDevice(5)}>
                      <View
                        w={calcSizeForDevice(35, 70)}
                        h={calcSizeForDevice(35, 70)}
                        padding={calcSizeForDevice(5, 10)}
                        borderWidth={calcSizeForDevice(5)}
                        position="relative"
                        borderColor="#2543BE"
                        borderRadius="$full">
                        {isActive && <View w="$full" h="$full" borderRadius="$full" bg="#2543BE" />}
                      </View>
                    </Pressable>

                    {isActive && index > 0 && (
                      <View
                        position="absolute"
                        sx={{
                          '@base': {
                            w: 5,
                            h: '$full',
                            top: '-100%',
                            pt: 75,
                          },
                          '@lg': {
                            w: '$full',
                            h: calcSizeForDevice(5, 10),
                            top: calcSizeForDevice(40, 55),
                            pt: 0,
                            left: '-100%',
                            pl: calcSizeForDevice(45, 15),
                          },
                        }}>
                        <View backgroundColor="#2543BE" w="$full" h="$full" />
                      </View>
                    )}
                  </View>

                  <View flex={1} my={calcSizeForDevice(20)} ml={calcSizeForDevice(10)}>
                    <Rating size="lg" ageRatingKey={key} append="title" />
                    <Text
                      color="$white"
                      fontSize={calcSizeForDevice(16, 24)}
                      lineHeight={calcSizeForDevice(24, 28)}
                      mt={calcSizeForDevice(20)}>
                      {ageRatingDefinition.description}
                    </Text>
                  </View>
                </Pressable>
              );
            })}
          </View>
        </View>
      )}
    </Box>
  );

  const footer = (
    <View
      gap={calcSizeForDevice(16, 32)}
      w="$full"
      alignItems="center"
      sx={{ '@sm': { flexDirection: 'row' }, '@base': { flexDirection: 'column' } }}>
      <IBaseButton
        role="button"
        paddingVertical={calcSizeForDevice(10, 30)}
        variant="solid"
        bgColor="#2543BE"
        onPress={commitAgeRatingPreference}>
        <Text
          fontSize={calcSizeForDevice(16, 24)}
          fontWeight="bold"
          color="white"
          textTransform="capitalize">
          Salvar definição
        </Text>
      </IBaseButton>

      <IBaseButton
        role="button"
        paddingVertical={calcSizeForDevice(10, 30)}
        borderWidth={0}
        variant="outline"
        onPress={handleCancelAgeRating}
        sx={{ ':hover': { backgroundColor: 'transparent' } }}>
        <Text
          fontSize={calcSizeForDevice(16, 24)}
          fontWeight="bold"
          color="#839CFF"
          textTransform="capitalize">
          Cancelar
        </Text>
      </IBaseButton>
    </View>
  );

  return (
    <BaseModal
      {...rest}
      size="full"
      modalBody={body}
      onClose={() => toggleModal()}
      modalFooter={isAgeRatingPreferenceLoaded && footer}
      modalHeader={
        <Image
          h={calcSizeForDevice(22, 44)}
          w={calcSizeForDevice(100, 200)}
          source={BandHeaderLogo}
          alt="Band Logo"
        />
      }
    />
  );
};

export default ModalAgeRating;
