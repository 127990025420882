import { Box, useBreakpointValue } from '@gluestack-ui/themed';
import withLink from 'hoc/withLink';
import { useMemo } from 'react';
import { calcHeightWithWidth, calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import TitleCard from './TitleCard';

import FastImage from 'components/FastImage';

const MediumCard: React.FC<CardProps & { isRelated?: boolean; style?: object }> = ({
  uri,
  title,
  imgTitle,
  reference,
  ...rest
}) => {
  const mediumCardAspectRatio = 3 / 4;
  const overrideBaseWidth = rest.override?.cardWidth?.base;
  const overrideXsWidth = rest.override?.cardWidth?.xs || rest.override?.cardWidth?.base;
  const overrideSmWidth = rest.override?.cardWidth?.sm || rest.override?.cardWidth?.base;
  const overrideLgWidth = rest.override?.cardWidth?.lg || rest.override?.cardWidth?.base;
  const overrideXlWidth = rest.override?.cardWidth?.xl || rest.override?.cardWidth?.base;

  const mediumCardWidth = useBreakpointValue({
    base: overrideBaseWidth || 135,
    xs: overrideXsWidth || 150,
    sm: overrideSmWidth || Platform.isAndroidTV ? 130 : 174,
    lg: overrideLgWidth || 189,
    xl: overrideXlWidth || calcSizeForDevice(201, rest.isRelated ? 201 : 260),
  });

  const mediumCardHeight = useMemo(
    () => calcHeightWithWidth(mediumCardWidth, mediumCardAspectRatio),
    [mediumCardWidth, mediumCardAspectRatio]
  );

  return (
    <Box
      width={mediumCardWidth}
      height={mediumCardHeight}
      borderRadius={8}
      overflow="hidden"
      {...rest}>
      {uri ? (
        <FastImage
          size="sm"
          aspect="3x4"
          source={{ uri }}
          style={{ width: '100%', height: '100%', borderRadius: 8, overflow: 'hidden' }}
        />
      ) : (
        <TitleCard title={title || imgTitle} reference={reference} />
      )}
    </Box>
  );
};

export default withLink(MediumCard, 'MediumCard');
