import { View } from '@gluestack-ui/themed';
import { useFocusEffect, useIsFocused, useRoute } from '@react-navigation/native';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useGlobalConfig } from 'context/GlobalContext';
import useHandlerError from 'hooks/useHandlerError';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useCallback, useEffect, useMemo } from 'react';
import ErrorHandler, { ErrorHandlerType } from 'screens/ErrorHandler';
import TransmitionCatch from 'screens/TransmitionCatch';
import { useGetProgramQuery } from 'services/cms';
import { ProgramType } from 'utils/enum';
import { isEventTimePassed } from 'utils/index';
import { Platform } from 'utils/platform';

import ExternalProgram from './ExternalProgram';
import LiveScreen from './LiveScreen';
import MovieScreen from './MovieScreen';
import PodcastScreen from './PodcastScreen';
import TvPodcastScreen from './PodcastScreen.ctv';
import RadioScreen from './RadioScreen';
import SeasonalScreen from './SeasonalScreen';

import FocusView from 'components/FocusView';
import Header from 'components/Header';
import useSuperiorNavbar from 'components/Header/helpers/useSuperiorNavbar';
import LiveMenuProvider from 'components/LiveMenu';
import { NoCopyright } from 'components/NoCopyright';
import { usePlayer } from 'components/Player/context';
import ProgramContext from 'components/Program/context';

type ProgramScreenProps = RootStackScreenProps<'Program'> & {
  program: CmsProgram;
  error: FetchBaseQueryError | SerializedError | undefined;
};

const headerNavigation = (hasSuperiorNavbar: boolean) => {
  if (Platform.isTV) {
    return <></>;
  }

  return (
    <View position="absolute" top={0} left={0} right={0} zIndex={1}>
      <Header transparent={!hasSuperiorNavbar} />
    </View>
  );
};

const ProgramScreen: React.FC<ProgramScreenProps> = (props) => {
  const { program, error } = props;
  useHandlerError(!!error);
  const route = useRoute();
  const hasSuperiorNavbar = useSuperiorNavbar(route);

  const screens: { [key in CmsProgram['type']]?: React.FC<RootStackScreenProps<'Program'>> } = {
    [ProgramType.LIVE as string]: LiveScreen,
    [ProgramType.MOVIE as string]: MovieScreen,
    [ProgramType.RADIO as string]: RadioScreen,
    [ProgramType.PODCAST as string]: Platform.isTV ? TvPodcastScreen : PodcastScreen,
    [ProgramType.DOCUMENTARY as string]: SeasonalScreen,
    [ProgramType.PROGRAM as string]: SeasonalScreen,
    [ProgramType.TV_SHOW as string]: SeasonalScreen,
    [ProgramType.TV_SHOW as string]: SeasonalScreen,
    [ProgramType.EXTERNALPROGRAM]: ExternalProgram,
  };

  const Screen = screens[program.type];
  if (!Screen) return null;

  const verifyTransmission = () => {
    const header = headerNavigation(hasSuperiorNavbar);

    if (program.noCopyright) {
      return (
        <>
          {header}
          <NoCopyright />
        </>
      );
    }

    const content = (
      <>
        {header}
        <Screen {...props} />
      </>
    );

    return program.type === ProgramType.LIVE ? (
      <LiveMenuProvider>{content}</LiveMenuProvider>
    ) : (
      content
    );
  };

  return (
    <FocusView trapFocusUp trapFocusRight style={{ flex: 1 }}>
      <ProgramContext.Provider value={program}>{verifyTransmission()}</ProgramContext.Provider>
    </FocusView>
  );
};

const ProgramScreenPerformaticShield: React.FC<RootStackScreenProps<'Program'>> = (props) => {
  const { mute, error, hasTransmissionError } = usePlayer();
  const { setColor } = useGlobalConfig();
  const {
    data: program,
    error: programError,
    isLoading,
    isError,
  } = useGetProgramQuery(props.route.params.programId);
  const isFocused = useIsFocused();
  const { hasInternet } = useGlobalConfig();
  const eventDateStart = program?.eventDateStart;
  const route = useRoute();
  const hasSuperiorNavbar = useSuperiorNavbar(route);
  const header = headerNavigation(hasSuperiorNavbar);

  useFocusEffect(
    useCallback(() => {
      setColor(program?.ads?.brandColor);
    }, [program])
  );

  useEffect(() => {
    if (!program) return;

    mute(false);
    setColor(program.ads?.brandColor);
    props.navigation.setOptions({ title: program.title });
  }, [program]);

  const typeMessage = useMemo<ErrorHandlerType | undefined>(() => {
    if (!isFocused || ['ERR_BAD_RESPONSE', 'ERR_BAD_REQUEST'].includes(error?.code as string))
      return undefined;

    if (!program && programError) {
      return ErrorHandlerType.NOT_FOUND;
    }

    if (error === ErrorHandlerType.NETWORK_ERROR) {
      return undefined;
    }

    return error ?? undefined;
  }, [isFocused, program, programError, error, isError, hasInternet]);

  if (isLoading) {
    return null;
  }

  if (eventDateStart && isEventTimePassed(new Date(eventDateStart))) {
    return (
      <>
        {header}
        <TransmitionCatch startTime={eventDateStart} />
      </>
    );
  }

  if (!program || programError || !!typeMessage || hasTransmissionError) {
    return (
      <>
        <ErrorHandler error={typeMessage} />
      </>
    );
  }

  return <ProgramScreen {...props} program={program} error={programError} />;
};

export default ProgramScreenPerformaticShield;
