import React, { memo, useMemo } from 'react';
import { useLazyGetWatchingListQuery } from 'services/backend';
import { useLazyGetProgramGroupQuery } from 'services/cms';
import { PROGRAM_GROUP_UNPUBLISHED_LIST_ID } from 'utils/constants';
import { Platform } from 'utils/platform';

import Section from '.';

type SearchWatchingListExternalProps = CmsHomeSection & {
  onLoaded?: () => void;
  waitToRender?: boolean;
  requestTVFocus?: boolean;
};

type SearchWatchingListInternalProps = CmsHomeSection &
  SearchWatchingListExternalProps & {
    useLazyGetWatchingListQueryData: {
      isLoading: boolean;
      fetchData: () => any;
      blockedList: string[];
      data?: BackendGenericList<BackendWatching>;
    };
  };

const SearchWatchingList: React.FC<SearchWatchingListInternalProps> = memo(
  (props) => {
    const { data, blockedList, isLoading } = props.useLazyGetWatchingListQueryData;

    const items = useMemo(() => {
      if (data) {
        return data.items
          .filter((item) => !blockedList.includes(item.programId))
          .filter((item) => {
            const watchedTime = item.watchedTime ?? 0;
            const duration = item.duration ?? 0;

            if ((watchedTime * 100) / duration < 95) return true;
          })
          .map<CmsCard & { percentageWatched: number }>((item) => {
            const title = item?.title.replaceAll('-', '').replaceAll('/', '-').replaceAll(' ', '-');

            return {
              title: item.title || '',
              image: { url: item.image[3] ?? item.image[1] },
              link: `${item.programUrl}/undefined/${title}/${item?.trackId}`,
              percentageWatched: (item.watchedTime * 100) / (item?.duration ?? 0),
            };
          });
      }

      return [];
    }, [data, blockedList]);

    React.useEffect(() => {
      if (isLoading) return;
      setTimeout(() => props?.onLoaded?.(), 0);
    }, [isLoading, data]);

    if (Platform.isAndroidTV && (isLoading || props.waitToRender)) {
      return null;
    }

    return <Section {...props} horizontal items={items} loading={isLoading} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.waitToRender === nextProps.waitToRender &&
      prevProps.useLazyGetWatchingListQueryData.data ===
        nextProps.useLazyGetWatchingListQueryData.data &&
      prevProps.useLazyGetWatchingListQueryData.isLoading ===
        nextProps.useLazyGetWatchingListQueryData.isLoading
    );
  }
);

/**
 * NOTA: Sem este shield, o componente terá inumeras re-renderizações por conta do useGetHomeQuery,
 * que atualiza varias propriedades que não utilizamos. Dessa forma, somente quando os
 * campos que nos interessam mudar é que um re-render será causado
 */
const SearchWatchingListPerformaticShield: React.FC<SearchWatchingListExternalProps> = (props) => {
  const [_fetchData, watchingListQuery] = useLazyGetWatchingListQuery();
  const [_fetchProgramGroup, programGroupQuery] = useLazyGetProgramGroupQuery();
  const fetchData = async () => _fetchData({ inProgress: true });
  const fetchProgramGroup = async () => _fetchProgramGroup(PROGRAM_GROUP_UNPUBLISHED_LIST_ID);

  const composedIsLoading = useMemo(() => {
    return (
      watchingListQuery.isLoading ||
      watchingListQuery.isUninitialized ||
      programGroupQuery.isLoading ||
      programGroupQuery.isUninitialized
    );
  }, [
    watchingListQuery.isLoading,
    watchingListQuery.isUninitialized,
    programGroupQuery.isLoading,
    programGroupQuery.isUninitialized,
  ]);

  const blockedList = useMemo(
    () => programGroupQuery?.data?.items?.[0]?.group || [],
    [programGroupQuery.data]
  );

  React.useEffect(() => {
    fetchData();
    fetchProgramGroup();
  }, []);

  return (
    <SearchWatchingList
      {...props}
      useLazyGetWatchingListQueryData={{
        fetchData,
        blockedList: blockedList,
        isLoading: composedIsLoading,
        data: watchingListQuery.data,
      }}
    />
  );
};

export default SearchWatchingListPerformaticShield;
