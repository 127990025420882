import { View, useBreakpointValue } from '@gluestack-ui/themed';
import withLink from 'hoc/withLink';
import React, { useMemo } from 'react';
import { calcHeightWithWidth } from 'utils/index';

import MediumCard from 'components/Card/MediumCard';
import { getIconByName } from 'components/Icons';

export type RankingItemProps = {
  item: any;
  index: number;
  hoveredIndex?: number | null;
  setHoveredIndex?: (index: number | null) => void;
};

const RankingItem: React.FC<RankingItemProps> = ({
  item,
  index,
  hoveredIndex,
  setHoveredIndex,
}) => {
  const xValue = useBreakpointValue({ base: 99, xs: 109, sm: 127, lg: 138, xl: 146 });
  const xTwoDigitsValue = useBreakpointValue({ base: 90, sm: 100, lg: 130 });
  const svgWidthValue = useBreakpointValue({ base: 135, xs: 150, sm: 174, lg: 189, xl: 201 });
  const svgWidthTwoDigitsValue = useBreakpointValue({ base: 205, xs: 228, sm: 263, lg: 302 });

  const mediumCardAspectRatio = 3 / 4;

  const svgHeightValue = useMemo(
    () => calcHeightWithWidth(svgWidthValue, mediumCardAspectRatio),
    [svgWidthValue, mediumCardAspectRatio]
  );

  const getIconName = (currentIndex: number) =>
    `Number${(currentIndex + 1).toString().padStart(2, '0')}` as IconsName;

  const IconComponent = getIconByName(getIconName(index));

  if (!IconComponent) {
    return null;
  }

  return (
    <View
      position="relative"
      display="flex"
      flexDirection="row"
      alignItems="center"
      onPointerEnter={() => setHoveredIndex?.(index)}
      onPointerLeave={() => setHoveredIndex?.(null)}>
      <View
        height={0.8 * svgHeightValue}
        width={index >= 9 ? svgWidthTwoDigitsValue : svgWidthValue}
        position="absolute"
        right={index >= 9 ? xTwoDigitsValue : xValue}>
        <IconComponent color="#2240FF" innerColor={hoveredIndex === index ? '#001075' : 'none'} />
      </View>
      <View pl={index >= 9 ? svgWidthTwoDigitsValue : svgWidthValue}>
        <MediumCard
          uri={item?.image?.url}
          link={item?.link}
          title={item?.title || ''}
          imgTitle={item?.image?.title || ''}
        />
      </View>
    </View>
  );
};

export default withLink(RankingItem, 'RankingItem');
