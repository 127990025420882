import { Text, View } from '@gluestack-ui/themed';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import useHandlerError from 'hooks/useHandlerError';
import { useIsAppFocused } from 'hooks/useIsAppFocused';
import React, { forwardRef, memo, useImperativeHandle } from 'react';
import * as ReactNative from 'react-native';
import { useDispatch } from 'react-redux';
import { backendApiSlice } from 'services/backend';
import { cmsApiSlice, useGetHomeQuery } from 'services/cms';
import theme from 'theme';
import { calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';

import DynamicList from './List';

import IsLoading from 'components/isLoading';

export type DynamicRef = {
  refresh: () => Promise<void>;
};

export type DynamicExternalProps = {
  id: string;
  onError?: () => void;
};

export type DynamicInnerProps = {
  useGetHomeData: {
    data?: CmsHome;
    refetch: () => any;
    isFetching: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  };
};

const Dynamic = memo(
  forwardRef<DynamicRef, DynamicExternalProps & DynamicInnerProps>((props, ref) => {
    const dispatch = useDispatch();

    const { data, isFetching, error, refetch } = props.useGetHomeData;

    const handleRefresh = async () => {
      dispatch(cmsApiSlice.util.invalidateTags(['Program', 'Highlights', 'GlobalConfig']));
      dispatch(backendApiSlice.util.invalidateTags(['Watching']));
      refetch();
    };

    useImperativeHandle(ref, () => ({ refresh: handleRefresh }), []);
    useHandlerError(!!error);

    if (!data || isFetching) {
      if (!Platform.isAndroidTV) return null;

      return (
        <View flex={1} justifyContent="center">
          <IsLoading />
        </View>
      );
    }

    return (
      <View flex={1} mt={null}>
        {Platform.isTV && data.title === 'Ouça' && (
          <Text
            color="white"
            fontSize={calcSizeForTv(48)}
            lineHeight={calcSizeForTv(60)}
            fontFamily={theme.fonts.inter.bold}
            mb={calcSizeForTv(60)}
            mt={calcSizeForTv(120)}
            pl={calcSizeForTv(24)}>
            {data.title}
          </Text>
        )}
        <DynamicList
          items={data.modules}
          initialNumToRender={Platform.isTV ? 5 : undefined}
          refreshControl={
            <ReactNative.RefreshControl
              colors={['#fff']}
              progressBackgroundColor="#19285e"
              tintColor="#95a4da"
              refreshing={isFetching}
              onRefresh={handleRefresh}
            />
          }
        />
      </View>
    );
  }),
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.useGetHomeData.data === nextProps.useGetHomeData.data &&
      prevProps.useGetHomeData.isFetching === nextProps.useGetHomeData.isFetching &&
      prevProps.useGetHomeData.error === nextProps.useGetHomeData.error
    );
  }
);

/**
 * NOTA: Sem este shield, o componente terá inumeras re-renderizações por conta do useGetHomeQuery,
 * que atualiza varias propriedades que não utilizamos. Dessa forma, somente quando os
 * campos que nos interessam mudar é que um re-render será causado
 */
const DynamicPerformaticShield = forwardRef<DynamicRef, DynamicExternalProps>((props, ref) => {
  const { isFocused, notFocusedSince } = useIsAppFocused();
  const pollingConfig = { pollingInterval: 300000 };

  const queryData = useGetHomeQuery(props.id, pollingConfig);
  const { data, refetch, isFetching, error } = queryData;

  React.useEffect(() => {
    const currentTime = Date.now();
    const normalizedNotFocusedSince = notFocusedSince || currentTime;
    const timestampDiff = currentTime - normalizedNotFocusedSince;
    if (isFocused && timestampDiff >= 20000) refetch();
  }, [isFocused]);

  return <Dynamic ref={ref} useGetHomeData={{ data, refetch, isFetching, error }} {...props} />;
});

export default DynamicPerformaticShield;
