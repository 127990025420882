import { useProgram } from './context';

import SearchProgram from 'components/Section/SearchProgram';

const ProgramRelated: React.FC = () => {
  const program = useProgram();

  return (
    <SearchProgram
      isRelated
      horizontal
      loading={false}
      waitToRender={false}
      title={program?.related?.title ?? 'Relacionados'}
      more={program?.related?.more ?? undefined}
      _name="section"
      variant={program?.related?.variant || 'section.medium'}
      showTitle={program?.related?.showTitle}
      ignoreId={program._id}
      searchPrograms={
        program.related?.searchPrograms?.category
          ? { category: program.related?.searchPrograms.category.map((c) => c._id) }
          : { 'genre.id': program?.genre?.[0].id }
      }
      sortRelatedPrograms={program?.related?.searchPrograms?.sort}
    />
  );
};

export default ProgramRelated;
