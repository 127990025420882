import { Text, View } from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { useGetHelpQuery } from 'services/cms';
import theme from 'theme';

import HelpPageLayout from 'components/Help/HelpPageLayout';
import IsLoading from 'components/isLoading';

const LegalTermsScreen: React.FC = () => {
  const analytics = useAnalytics();
  const { data, isLoading } = useGetHelpQuery();

  useFocusEffect(
    React.useCallback(() => {
      analytics.triggerLegalTermsScreenPageViewEvent();
    }, [])
  );

  if (isLoading) {
    return (
      <View flex={1} alignItems="center" justifyContent="center">
        <IsLoading />
      </View>
    );
  }

  if (!data) {
    // TODO: definir comportamento ao receber erros da API
    return null;
  }

  return (
    <HelpPageLayout title={data.termsConditions.title}>
      <View px="$4">
        <Text color={theme.colors.base.white}>{data.termsConditions.text}</Text>
      </View>
    </HelpPageLayout>
  );
};

export default LegalTermsScreen;
