export const MY_LIST_ITEM: CmsHomeSectionMyList = {
  title: 'Minha Lista',
  limit: 15,
  more: {
    title: 'Ver mais',
    link: '/favoritos',
  },
  _name: 'sectionMyList',
};

export const MAX_MENU_ITEMS = 5;

export const PROGRAM_GROUP_UNPUBLISHED_LIST_ID = '67978452cdf102613f5a422a';
