import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Constants from 'expo-constants';
import { PipelineEnvironment } from 'utils/environment';
import { Platform } from 'utils/platform';
import { handleBaseQuery } from 'utils/request';

const cmsApiURL = () => {
  if (Platform.OS === 'web') {
    switch (Constants.expoConfig?.extra?.environment) {
      case PipelineEnvironment.CMS1:
        return Constants.expoConfig?.extra?.cmsM1Api;
      case PipelineEnvironment.CMS2:
        return Constants.expoConfig?.extra?.cmsM2Api;
      default:
        return Constants.expoConfig?.extra?.cmsApi;
    }
  }

  return Constants.expoConfig?.extra?.cmsApi;
};

export const cmsApiSlice = createApi({
  reducerPath: 'cmsApi',
  baseQuery: handleBaseQuery(fetchBaseQuery({ baseUrl: cmsApiURL() })),
  tagTypes: ['Program', 'Highlights', 'GlobalConfig', 'Home'],
  endpoints: (builder) => ({
    getHome: builder.query<CmsHome, string>({
      query: (id) => `/api/v1/BandplayHome/${id}/h`,
      providesTags: (result, error, arg) => [{ type: 'Home', id: arg }],
    }),
    getPrograms: builder.query<CmsApiProgramList, CmsApiProgramParams>({
      query: (params) => ({
        url: `/api/v1/BandplayProgram`,
        params: { ...params, limit: Platform.isTV ? 8 : params?.limit },
      }),
    }),
    getProgramGroup: builder.query<any, string>({
      query: (id) => ({
        url: `/api/v1/BandplayProgramGroup`,
        params: { _id: id },
      }),
    }),
    getProgram: builder.query<CmsProgram, string>({
      query: (id) => `/api/v1/BandplayProgram/${id}`,
    }),
    getTag: builder.query<CmsTag, string>({
      query: (id) => `/api/v1/BandplayTags/${id}`,
    }),
    getMedias: builder.query<CmsApiMediaList, CmsApiMediaParams>({
      query: (params) => ({
        url: `/api/v1/BandplayMedia`,
        params: { limit: 60, ...params },
      }),
    }),
    getMedia: builder.query<CmsMedia, string>({
      query: (id) => `/api/v1/BandplayMedia/${id}`,
    }),
    getHighlights: builder.query<CmsApiHightlightList, CmsApiHightlightParams>({
      query: (params) => ({
        url: `/api/v1/BandplayHighlights`,
        params: { sort: 'order', ...params },
      }),
    }),
    getHelp: builder.query<CmsHelp, void>({
      query: () => `/api/v1/BandplayHelp/ajuda`,
    }),
    getGlobalConfig: builder.query<GlobalConfig, void>({
      query: () => `/api/v1/BandplayConfig/config`,
      providesTags: () => [{ type: 'GlobalConfig' }],
    }),
  }),
});

export const {
  useGetHomeQuery,
  useGetProgramsQuery,
  useGetProgramQuery,
  useGetProgramGroupQuery,
  useGetTagQuery,
  useGetMediasQuery,
  useGetMediaQuery,
  useGetHighlightsQuery,
  useGetHelpQuery,
  useGetGlobalConfigQuery,
  useLazyGetProgramsQuery,
  useLazyGetHighlightsQuery,
  useLazyGetMediasQuery,
  useLazyGetProgramGroupQuery,
} = cmsApiSlice;
