import { Heading, View, useBreakpointValue } from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { useGetHelpQuery } from 'services/cms';
import theme from 'theme';
import { capitalize } from 'utils/string';

import Grid from 'components/Grid';
import ContactBlock, { ContactBlockConfig } from 'components/Help/ContactBlock';
import HelpPageLayout from 'components/Help/HelpPageLayout';
import IsLoading from 'components/isLoading';

type ContactUsConfig = { modes: ContactBlockConfig[]; socialMedia: ContactBlockConfig[] };

const ContactUsScreen: React.FC = () => {
  const analytics = useAnalytics();
  const { data, isLoading } = useGetHelpQuery();
  const contentSpacing = useBreakpointValue({ xl: '$8', lg: '$8', base: '$4' });
  const titleFontSize = useBreakpointValue({ xl: '$3xl', lg: '$3xl', base: '$lg' });
  const contentColumns = useBreakpointValue({ xl: 7, lg: 5, md: 4, sm: 2, base: 2 });
  const blockRenderer = (config: ContactBlockConfig) => <ContactBlock {...config} />;

  useFocusEffect(
    React.useCallback(() => {
      analytics.triggerContactUsScreenPageViewEvent();
    }, [])
  );

  if (isLoading) {
    return (
      <View flex={1} alignItems="center" justifyContent="center">
        <IsLoading />
      </View>
    );
  }

  if (!data) {
    // TODO: definir comportamento ao receber erros da API
    return null;
  }

  const blocksConfig: ContactUsConfig = {
    modes: [
      {
        icon: 'email',
        description: 'Envie um e-mail',
        title: data.contact.email,
        link: `mailto:${data.contact.email}`,
      },
      {
        icon: 'wpp',
        description: 'Entre em contato via',
        title: 'Whatsapp',
        link: `http://api.whatsapp.com/send?phone=55${data.contact.whatsapp?.replace(/\D/g, '')}`,
      },
    ],
    socialMedia: data?.socialMidia.midiaItems.map((midia) => ({
      icon: midia.socialMidiaName as ContactBlockConfig['icon'],
      title: capitalize(midia.socialMidiaName),
      link: midia.SocialMidiaLink,
    })),
  };

  return (
    <HelpPageLayout>
      <View gap={contentSpacing} px="$4">
        <Heading fontSize={titleFontSize} color={theme.colors.base.white}>
          {data?.contact.title}
        </Heading>

        <Grid data={blocksConfig.modes} renderItem={blockRenderer} numColumns={contentColumns} />
      </View>

      <View gap={contentSpacing} py="$8" px="$4">
        <Heading fontSize={titleFontSize} color={theme.colors.base.white}>
          {data?.socialMidia.title}
        </Heading>

        <Grid
          renderItem={blockRenderer}
          numColumns={contentColumns}
          data={blocksConfig.socialMedia}
        />
      </View>
    </HelpPageLayout>
  );
};

export default ContactUsScreen;
