import { ParamListBase, RouteProp } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';

type RouteParams = { screen?: string; path?: string; [key: string]: string | undefined };

type RouteMap = { [key: string]: RouteParams[] };

export const NAVBAR_ITEMS = [
  { name: 'Home', screen: 'Home', label: 'Início', params: {} },
  { name: 'Live', screen: 'Program', label: 'Ao Vivo', params: { programId: 'band-ao-vivo' } },
  { name: 'Videos', screen: 'Videos', label: 'Assista', params: { id: 'assista' } },
  { name: 'Listen', screen: 'Listen', label: 'Ouça', params: { id: 'ouca' } },
];

const useSuperiorNavbar = (route: RouteProp<ParamListBase, string>) => {
  const routeMap = useMemo(() => {
    return NAVBAR_ITEMS.reduce<RouteMap>((accumulator, currentItem) => {
      accumulator[currentItem.screen] = accumulator[currentItem.screen] || [];
      accumulator[currentItem.screen].push(currentItem.params || {});
      return accumulator;
    }, {});
  }, [NAVBAR_ITEMS]);

  const hasSuperiorNavbar = useCallback(
    (currentRoute: RouteProp<ParamListBase, string>) => {
      const paramOptions = routeMap[currentRoute?.name];

      if (!paramOptions) return false;

      return paramOptions.some(
        (params) =>
          params &&
          Object.keys(params).every((key) => params[key] === (currentRoute as any)?.params?.[key])
      );
    },
    [routeMap]
  );

  return hasSuperiorNavbar(route);
};

export default useSuperiorNavbar;
