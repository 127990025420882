import React, { createContext, useContext, useState } from 'react';

const StreamContext = createContext<StreamContextProps>({} as StreamContextProps);

export const StreamProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isConnected, _setConnected] = useState(false);
  const [castState, _setCastState] = useState<string>('false');

  const castDialog = () => {};
  const showControls = () => {};
  const closeSession = () => {};
  const closeMedia = async () => {};
  const loadMedia = async (_request: any) => {};

  return (
    <StreamContext.Provider
      value={{
        client: null,
        isConnected,
        status,
        streamPosition: null,
        device: null,
        castState,
        castDialog,
        showControls,
        closeSession,
        loadMedia,
        closeMedia,
      }}>
      {children}
    </StreamContext.Provider>
  );
};

export const useStream = () => useContext(StreamContext);

export default StreamContext;
