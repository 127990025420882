import { useSocket } from 'context/SocketContext';
import { useEffect } from 'react';
import { useAnalytics } from 'services/analytics/useAnalytics';

import { usePlayer } from './context';

import { useProgram } from 'components/Program/context';

let UPDATE_WHILE_WATCHING_TIMER: NodeJS.Timeout;

const PlayerSocketUpdater: React.FC = () => {
  const program = useProgram();
  const analytics = useAnalytics();
  const { socket } = useSocket();
  const { ref: videoRef, isPlaying, isReady, isLive, isShowingAds } = usePlayer();

  const emitUpdateEvent = (eventName: string) => {
    const currentTime = Math.ceil(videoRef.current?.progress?.().currentTime || 0);

    if (currentTime > 0 && !isLive) {
      socket.emit(eventName, currentTime);

      analytics.triggerMediaWatchedActionEvent({
        mediaCurrentTime: currentTime,
        mediaDuration: program.duration || 0,
        mediaType: 'video',
        programAgeRating: program.rating,
        programTitle: program.title,
        programId: program._id,
      });
    }
  };

  const startWhileWatchingUpdater = (eventName: string) => {
    clearInterval(UPDATE_WHILE_WATCHING_TIMER);
    UPDATE_WHILE_WATCHING_TIMER = setInterval(() => emitUpdateEvent(eventName), 1000);
  };

  useEffect(() => {
    const isPlayingAndNotShowingAds = isPlaying && !isShowingAds;
    const eventName = isPlayingAndNotShowingAds ? 'play' : 'pause';

    if (!isReady) return;
    if (isPlayingAndNotShowingAds) startWhileWatchingUpdater(eventName);
    else clearInterval(UPDATE_WHILE_WATCHING_TIMER);
    emitUpdateEvent(eventName);

    return () => clearInterval(UPDATE_WHILE_WATCHING_TIMER);
  }, [socket, videoRef, isReady, isPlaying, isShowingAds]);

  return null;
};

export default PlayerSocketUpdater;
