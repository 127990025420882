import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Box,
  Button as ButtonGlue,
  ButtonIcon,
} from '@gluestack-ui/themed';
import * as React from 'react';
import { useWindowDimensions } from 'react-native';
import { useSharedValue } from 'react-native-reanimated';
import Carousel, { ICarouselInstance, Pagination } from 'react-native-reanimated-carousel';
import { useSelector } from 'react-redux';
import { useGetHighlightsQuery } from 'services/cms';
import { RootState } from 'store/index';

import CarouselItem from './carouselItem';

import Skeleton from 'components/Skeleton';

const NewCarousel: React.FC<CmsHomeHero> = (props) => {
  const ref = React.useRef<ICarouselInstance>(null);
  const { width: PAGE_WIDTH, height: PAGE_HEIGHT } = useWindowDimensions();
  const progress = useSharedValue<number>(0);
  const profile = useSelector((state: RootState) => state.profile);

  const { data, isLoading, refetch } = useGetHighlightsQuery(props?.searchPrograms, {
    pollingInterval: 900000,
  });

  const onPressPagination = (index: number) => {
    ref.current?.scrollTo({
      count: index - progress.value,
      animated: true,
    });
  };
  const onPressToLeft = () => {
    ref.current?.prev();
  };
  const onPressToRight = () => {
    ref.current?.next();
  };

  React.useEffect(() => {
    refetch();
  }, [profile.ageRating]);

  if (isLoading || !data) return <Skeleton variant="hero" />;

  return (
    <Box alignItems="center" flex={1} mb={-30}>
      {PAGE_WIDTH > 500 ? (
        <Carousel
          ref={ref}
          width={PAGE_WIDTH / 1.2}
          loop
          style={{
            zIndex: 999,
            width: PAGE_WIDTH,
            alignItems: 'center',
            justifyContent: 'center',
            height: 650,
            marginBottom: -20,
          }}
          onProgressChange={progress}
          autoPlay
          autoPlayInterval={10000}
          data={data.items}
          mode="parallax"
          enabled={false}
          modeConfig={{
            parallaxScrollingScale: 0.95,
            parallaxScrollingOffset: 50,
            parallaxAdjacentItemScale: 0.95,
          }}
          renderItem={({ item, index }: any) => (
            <Box position="relative" flex={1}>
              <CarouselItem
                item={item}
                currentIndex={ref?.current?.getCurrentIndex()}
                index={index}
              />

              <Pagination.Basic
                progress={progress}
                data={data.items}
                dotStyle={{
                  borderRadius: 7,
                  backgroundColor: 'white',
                  width: 14,
                  height: 14,
                }}
                activeDotStyle={{
                  backgroundColor: '#6386FF',
                }}
                containerStyle={{ position: 'absolute', top: '90%', left: '50%', gap: 8 }}
                horizontal
                onPress={onPressPagination}
              />
            </Box>
          )}
        />
      ) : (
        <Box>
          <Carousel
            ref={ref}
            width={PAGE_WIDTH}
            loop
            height={PAGE_HEIGHT < 680 ? (PAGE_HEIGHT < 600 ? 400 : 450) : 550}
            onProgressChange={progress}
            onConfigurePanGesture={(e) => {
              e.activeOffsetX([-10, 10]);
              e.failOffsetY([-5, 5]);
            }}
            data={data.items}
            renderItem={({ item }: any) => (
              <Box h="$full" w="$full">
                <CarouselItem item={item} />
              </Box>
            )}
          />
          <Pagination.Basic
            progress={progress}
            data={data.items}
            dotStyle={{
              borderRadius: 6,
              backgroundColor: '#6386FF',
              width: 12,
              height: 12,
            }}
            activeDotStyle={{
              backgroundColor: 'white',
            }}
            containerStyle={{
              position: 'absolute',
              bottom: 10,
              height: 100,
              justifyContent: 'center',
              alignItems: 'center',
              gap: 5,
              width: '100%',
            }}
            horizontal
          />
        </Box>
      )}
      {PAGE_WIDTH > 500 && (
        <>
          <ButtonGlue
            position="absolute"
            backgroundColor="rgba(7, 13, 49, 0.4)"
            borderWidth={2}
            borderColor="#fff"
            rounded="$full"
            top="50%"
            left="2%"
            height={56}
            width={56}
            p={0}
            onPress={() => onPressToLeft()}>
            <ButtonIcon size="lg" color="#fff" as={ArrowLeftIcon} />
          </ButtonGlue>

          <ButtonGlue
            position="absolute"
            backgroundColor="rgba(7, 13, 49, 0.4)"
            borderWidth={2}
            borderColor="#fff"
            rounded="$full"
            top="50%"
            p={0}
            right="2%"
            height={56}
            width={56}
            onPress={() => onPressToRight()}>
            <ButtonIcon size="lg" color="#fff" as={ArrowRightIcon} />
          </ButtonGlue>
        </>
      )}
    </Box>
  );
};

export default NewCarousel;
