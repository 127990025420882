import { Box, Text, useBreakpointValue } from '@gluestack-ui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import withLink from 'hoc/withLink';
import { memo, useMemo } from 'react';
import { calcHeightWithWidth, calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import TitleCard from './TitleCard';

import { EventDateTag } from 'components/EventDateTag';
import FastImage from 'components/FastImage';

const BigCard: React.FC<CardProps> = ({ title, tag, uri, jsxButton, lines, ...rest }) => {
  const bigCardAspectRatio = 9 / 5;
  const overrideBaseWidth = rest.override?.cardWidth?.base;
  const overrideSmWidth = rest.override?.cardWidth?.sm || rest.override?.cardWidth?.base;
  const overrideXlWidth = rest.override?.cardWidth?.xl || rest.override?.cardWidth?.base;
  const itemStartDate = rest.startDate ? new Date(rest.startDate) : null;

  const bigCardWidth = useBreakpointValue({
    xl: overrideXlWidth || calcSizeForDevice(370, 550),
    sm: overrideSmWidth || Platform.isAndroidTV ? 275 : 333,
    base: overrideBaseWidth || rest.override?.cardWidth?.base || 280,
  });

  const cardInfoPadding = useBreakpointValue({
    lg: 15,
    base: 12,
    xl: calcSizeForDevice(15),
  });

  const textSize = useBreakpointValue({
    base: Platform.isAndroidTV ? calcSizeForDevice(24) : 16,
    lg: 20,
    xl: calcSizeForDevice(20, 24),
  });

  const lineHeight = useBreakpointValue({
    base: Platform.isAndroidTV ? calcSizeForDevice(32) : 20,
    lg: 24,
    xl: calcSizeForDevice(24, 32),
  });

  const tagTextSize = useBreakpointValue({
    base: Platform.isAndroidTV ? calcSizeForDevice(12) : 10,
    lg: 12,
    xl: calcSizeForDevice(12),
  });

  const bigCardHeight = useMemo(
    () => calcHeightWithWidth(bigCardWidth, bigCardAspectRatio),
    [bigCardWidth, bigCardAspectRatio]
  );

  return (
    <Box width={bigCardWidth} height={bigCardHeight} borderRadius={8} overflow="hidden" {...rest}>
      {uri ? (
        <>
          <FastImage
            size="md"
            aspect="16x9"
            source={{ uri }}
            style={{ width: '100%', height: '100%', borderRadius: 8, overflow: 'hidden' }}
          />

          {(Boolean(title) || Boolean(tag)) && (
            <Box
              width="$full"
              height="$full"
              position="absolute"
              backgroundColor="rgba(0, 0, 0, 0.5)"
              p={cardInfoPadding}>
              {tag && (
                <Box
                  borderWidth={1}
                  borderColor="white"
                  borderRadius={5}
                  px={5}
                  mb={5}
                  alignSelf="flex-start">
                  <Text fontSize={tagTextSize} color="white" fontWeight="600">
                    {tag}
                  </Text>
                </Box>
              )}
              {Boolean(title) && (
                <Text
                  fontSize={textSize}
                  lineHeight={lineHeight}
                  fontWeight="700"
                  color="white"
                  numberOfLines={lines?.title}>
                  {title}
                </Text>
              )}
            </Box>
          )}

          {itemStartDate && <EventDateTag startDate={itemStartDate} />}

          {Boolean((rest as any)?.item?.percentageWatched) && (
            <LinearGradient
              start={{ x: 0, y: 1 }}
              end={{ x: 0, y: 0 }}
              locations={[0.1, 0.95]}
              style={{
                zIndex: 1,
                width: '100%',
                height: '100%',
                position: 'absolute',
                padding: cardInfoPadding,
              }}
              colors={['#00000080', 'transparent']}>
              <Box
                backgroundColor="rgba(217, 217, 217, 0.33)"
                borderBottomLeftRadius={calcSizeForDevice(3)}
                borderBottomRightRadius={calcSizeForDevice(3)}
                w="$full"
                h={calcSizeForDevice(4)}
                mt="auto">
                <Box
                  h={calcSizeForDevice(4)}
                  backgroundColor="rgba(217, 217, 217, 1)"
                  borderBottomLeftRadius={calcSizeForDevice(3)}
                  width={`${(rest as any).item.percentageWatched}%` as any}
                />
              </Box>
            </LinearGradient>
          )}

          {Boolean(jsxButton) && (
            <LinearGradient
              start={{ x: 0, y: 1 }}
              end={{ x: 0, y: 0 }}
              locations={[0.1, 0.95]}
              style={{
                zIndex: 1,
                width: '100%',
                height: '100%',
                position: 'absolute',
                padding: calcSizeForDevice(15),
              }}
              colors={['#000000E6', 'transparent']}
            />
          )}
        </>
      ) : (
        <TitleCard title={title || rest?.imgTitle} reference={rest?.reference} />
      )}
    </Box>
  );
};

const BigCardWithLink = memo(withLink(BigCard, 'BigCard'));

const BigCardProxy: React.FC<CardProps> = (props) => {
  if (!props.jsxButton) return <BigCardWithLink {...props} />;

  return (
    <Box position="relative">
      <BigCardWithLink {...props} />

      <Box
        mt="auto"
        position="absolute"
        alignSelf="flex-end"
        bottom={calcSizeForDevice(15)}
        right={calcSizeForDevice(15)}>
        {props.jsxButton}
      </Box>
    </Box>
  );
};

export default BigCardProxy;
