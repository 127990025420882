import { Box, Text } from '@gluestack-ui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import withLink from 'hoc/withLink';
import React from 'react';
import theme from 'theme';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

const SectionHeaderComponent: React.FC<Partial<CmsHomeSection>> = ({ separator, title, more }) => {
  const TextWithLink = withLink(Text, 'section-link');

  return (
    <>
      {separator && (
        <LinearGradient
          colors={theme.gradient_colors.primary}
          start={{ x: 0.1, y: 0.1 }}
          end={{ x: 1, y: 0 }}
          locations={[0.1, 0.2, 0.5, 1]}
          style={{ height: calcSizeForDevice(1) }}
        />
      )}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        pt={Platform.OS !== 'web' ? 10 : calcSizeForDevice(30, 40)}
        pb={Platform.OS !== 'web' ? 10 : calcSizeForDevice(20, 40)}
        px={0}>
        <Text color="white" fontSize={calcSizeForDevice(18, 28)} fontWeight="$bold">
          {title}
        </Text>
        {!!more?.link && !Platform.isTV && (
          <Box>
            <TextWithLink
              color="#839cff"
              link={more.link}
              fontWeight="$bold"
              fontSize={calcSizeForDevice(14)}>
              {more.title || 'ver mais'}
            </TextWithLink>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SectionHeaderComponent;
