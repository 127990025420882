import { useFocusEffect } from '@react-navigation/native';
import { HomeTabScreenProps } from 'navigation/tabs/HomeTab';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { RootState } from 'store/index';

import Dynamic, { DynamicRef } from 'components/Dynamic';
import FocusView from 'components/FocusView';

type HomeId = 'home-principal' | 'home-infantil' | 'assista' | 'ouca';
const KIDS_HOME: HomeId = 'home-infantil';
const ADULTS_HOME: HomeId = 'home-principal';

const HomeScreen: React.FC<HomeTabScreenProps<'Home' | 'Videos' | 'Listen' | 'Generic'>> = ({
  route,
}) => {
  const analytics = useAnalytics();
  const dynamicRef = useRef<DynamicRef>(null);
  const auth = useSelector((state: RootState) => state.auth);
  const profile = useSelector((state: RootState) => state.profile);
  const id: HomeId = (route.params?.id as HomeId) || (profile.isKid && KIDS_HOME) || ADULTS_HOME;

  useFocusEffect(
    React.useCallback(() => {
      if (id === 'assista') {
        analytics.triggerWatchScreenPageViewEvent();
        return;
      }

      if (id === 'ouca') {
        analytics.triggerListenScreenPageViewEvent();
        return;
      }

      // home-infantil e home-principal
      analytics.triggerHomeScreenPageViewEvent();
    }, [auth.isAuthenticated])
  );

  return (
    <FocusView trapFocusUp trapFocusRight style={{ flex: 1 }}>
      <Dynamic
        id={id}
        ref={dynamicRef}
        key={`${id}:${profile.ageRating}:${auth.isAuthenticated}`}
      />
    </FocusView>
  );
};

export default HomeScreen;
