import { Box, Text, useBreakpointValue } from '@gluestack-ui/themed';
import withLink from 'hoc/withLink';
import LottieView from 'lottie-react-native';
import { Platform, TouchableOpacity } from 'react-native';
import theme from 'theme';
import { calcSizeForDevice } from 'utils/index';

import LottieImage from 'assets/Lottie/playing.json';

import FastImage from 'components/FastImage';
import { Icons } from 'components/Icons';

const BoxedMediumCard: React.FC<CardProps> = ({ uri, title, subtitle, isPlaying, lines }) => {
  const textContainerWidth = useBreakpointValue({ base: '80%', lg: 'auto' });

  return (
    <Box
      flexDirection="row"
      height={calcSizeForDevice(62)}
      backgroundColor="rgba(255,255,255,0.2)"
      mb={calcSizeForDevice(20)}
      borderRadius={5}>
      {!!uri && (
        <FastImage source={{ uri }} style={{ height: '100%', width: calcSizeForDevice(64) }} />
      )}
      <Box
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        m={calcSizeForDevice(10)}>
        <Box width={textContainerWidth} flexDirection="column" justifyContent="center">
          {Boolean(title) && (
            <Text
              color="white"
              fontSize={calcSizeForDevice(14)}
              lineHeight={calcSizeForDevice(16)}
              fontWeight="700"
              numberOfLines={lines?.title || 2}>
              {title}
            </Text>
          )}
          {Boolean(subtitle) && (
            <Text
              color="white"
              fontSize={calcSizeForDevice(11)}
              numberOfLines={lines?.description || 1}>
              {subtitle}
            </Text>
          )}
        </Box>
        <TouchableOpacity role="link" style={{ alignItems: 'center' }}>
          {isPlaying && Platform.OS !== 'web' ? (
            <LottieView
              autoPlay
              style={{
                width: 40,
                height: 40,
              }}
              source={LottieImage}
            />
          ) : (
            <Icons.ChevronRight
              color={theme.colors.base.button}
              width={calcSizeForDevice(24).toString()}
              height={calcSizeForDevice(24).toString()}
            />
          )}
        </TouchableOpacity>
      </Box>
    </Box>
  );
};

export default withLink(BoxedMediumCard, 'BoxedMediumCard');
