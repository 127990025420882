import { Text } from '@gluestack-ui/themed';
import { Linking } from 'react-native';
import theme from 'theme';
import { calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';

import { DEV_ENVIRONMENTS } from '../utils/constants';

import Button from 'components/Button';

type EnvButtonProps = {
  name: string;
  url: string;
  type?: string;
  description?: string;
  borderColor?: string;
};

const EnvButton: React.FC<EnvButtonProps> = ({ name, url, description, borderColor }) => {
  return (
    <Button
      role="link"
      useTVFocus
      hasTVPreferredFocus={name === DEV_ENVIRONMENTS?.[0]?.title}
      variant="invisible"
      onPress={() => (Platform.isAndroidTV ? Linking.openURL(url) : window.location.replace(url))}
      style={{
        width: calcSizeForTv(200),
        height: calcSizeForTv(200),
        borderRadius: calcSizeForTv(100),
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: borderColor || '#7A8CFF',
      }}>
      <Text
        color="white"
        fontFamily={theme.fonts.inter.bold}
        fontSize={calcSizeForTv(28)}
        lineHeight={calcSizeForTv(32)}
        textAlign="center">
        {name}
      </Text>
      {!!description && (
        <Text
          color="rgb(233, 233, 233)"
          fontFamily={theme.fonts.inter.regular}
          fontSize={calcSizeForTv(20)}>
          {description || ''}
        </Text>
      )}
    </Button>
  );
};

export default EnvButton;
