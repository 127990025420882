import { View } from '@gluestack-ui/themed';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import SectionHeaderComponent from './components/SectionHeader';
import SectionHorizontal from './components/SectionHorizontal';
import SectionVertical from './components/SectionVertical';

import Card from 'components/Card';

const componentAudienceName = 'Prateleira';
type RenderCardProps = {
  item: CmsCard;
  index: number;
  type: string;
  requestTVFocus?: boolean;
  override?: { cardWidth?: { base: number; [x: string]: number } };
};

const RenderCard = (props: RenderCardProps) => {
  const { item, index, type, requestTVFocus } = props;
  const itemType = type?.replace('section.', '');

  return (
    <Card
      {...props}
      index={index}
      type={itemType}
      link={item?.link}
      title={item?.title}
      reference={item?.reference}
      jsxButton={item?.jsxButton}
      imgTitle={item?.image?.title}
      crops={{ mobile: '400x300' }}
      component={componentAudienceName}
      uri={item.image ? item?.image?.url : ''}
      requestTVFocus={index === 0 && requestTVFocus}
      startDate={item.eventStartDate}
    />
  );
};

type SectionProps = CmsHomeSection & {
  log?: boolean;
  requestTVFocus?: boolean;
  onEndReached?: () => void;
};

const Section: React.FC<SectionProps> = (props) => {
  const { items: rawItems, horizontal, loading, vertical, showTitle = true, more } = props;
  const filteredItems = rawItems?.filter((item) => item.link);
  const hasSeeMore = filteredItems && filteredItems.length > 0 && showTitle;

  const seeMoreItem: CmsCard = {
    title: 'Ver Mais',
    image: undefined,
    link: more?.link || '',
    reference: 'Ver Mais',
  };

  const items =
    hasSeeMore && more?.link && Platform.isTV ? [...filteredItems, seeMoreItem] : filteredItems;

  return (
    <>
      {hasSeeMore && <SectionHeaderComponent {...props} />}

      {horizontal && (
        <SectionHorizontal
          {...props}
          items={items}
          loading={loading}
          onEndReached={props.onEndReached}
          renderItem={(info: any) => {
            return (
              <View mr={info.index < items?.length! - 1 ? calcSizeForDevice(10, 30) : undefined}>
                <RenderCard
                  {...info}
                  {...props.cardProps}
                  type={props.variant}
                  isRelated={props.isRelated}
                  requestTVFocus={props.requestTVFocus}
                />
              </View>
            );
          }}
        />
      )}

      {vertical && (
        <SectionVertical
          {...props}
          isLoading={loading}
          onEndReached={props.onEndReached}
          type={props.variant}
          data={items}
        />
      )}
    </>
  );
};

export default Section;
