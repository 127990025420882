import { Box, ButtonText, HStack, View } from '@gluestack-ui/themed';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { useBottomSheet } from 'context/BottomSheetContext';
import { useGlobalConfig } from 'context/GlobalContext';
import { useMobileOrDesktop } from 'hoc/mobileOrDesktop';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { ProgramType } from 'utils/enum';
import { calcSizeForDevice, calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';

import Button from 'components/Button';
import NewGradientButton from 'components/Button/NewGradienteButton';
import useSuperiorNavbar from 'components/Header/helpers/useSuperiorNavbar';
import Hero from 'components/Hero';
import { Icons } from 'components/Icons';
import Player from 'components/Player';
import { usePlayer } from 'components/Player/context';
import ProgramContent from 'components/Program/Content';
import ProgramHeader from 'components/Program/Header';
import ProgramRelated from 'components/Program/Related';
import ProgramShared from 'components/Program/Shared';
import { useProgram } from 'components/Program/context';
import { WebDiv } from 'components/WebDiv';

const LiveScreen: React.FC<RootStackScreenProps<'Program'>> = ({ navigation }) => {
  const {
    isPlaying,
    isFullScreen,
    isStreaming,
    pause,
    play,
    isVisible,
    setIsVisible,
    mute,
    setNextTrack,
    reset,
  } = usePlayer();

  const route = useRoute();
  const program = useProgram();
  const analytics = useAnalytics();
  const { ads, colors } = useGlobalConfig();
  const [isActive, setIsActive] = useState(false);
  const [mediaId, setMediaId] = useState<string>();
  const { setVisible, setLiveMenuItems } = useBottomSheet();
  const hasSuperiorNavbar = useSuperiorNavbar(route);
  const { width } = useWindowDimensions();

  const getMarginTop = useMemo(() => {
    const marginTopForMobileWeb = Platform.isMobileWeb() ? 45 : 80;
    if (isFullScreen) return 0;
    return hasSuperiorNavbar ? marginTopForMobileWeb + 40 : marginTopForMobileWeb;
  }, [isFullScreen, hasSuperiorNavbar]);

  useEffect(() => {
    setNextTrack(undefined);
  }, []);

  useEffect(() => {
    return navigation.addListener('beforeRemove', async () => {
      if (isPlaying && !isStreaming) pause();

      reset({ media: undefined });
      setVisible(false);
      mute(false);
    });
  }, [navigation, isPlaying, isStreaming, pause]);

  useFocusEffect(
    useCallback(() => {
      setIsActive(true);

      return () => {
        if (!isStreaming) pause();
        setIsActive(false);
      };
    }, [isStreaming])
  );

  useFocusEffect(
    useCallback(() => {
      analytics.triggerLiveScreenPageViewEvent({
        programId: program._id,
        programTitle: program.title,
        programAgeRating: program.rating,
      });
    }, [])
  );

  const playerProps: PlayerProps | undefined = useMemo(() => {
    const media: PlayerMedia = {
      title: program.title,
      image: [program.imageVertical?.url!, program.imageHorizontal?.url!],
      duration: program.duration,
      programId: program._id,
      programTitle: program.title,
      programUrl: program.url,
      ratingValue: program.rating,
      ratingDescription: program.rateDescription,
      videoTracks: [],
    };

    const mediaIdDub = program.mediaIdDub;

    if (mediaIdDub) {
      media.videoTracks.push({
        language: 'dub',
        trackId: mediaIdDub,
      });
    }

    media.videoTracks.push({ language: 'origin', trackId: program.mediaId });

    return {
      media,
      isLive: program.type === ProgramType.LIVE || program.type === ProgramType.RADIO,
      adTagUrl:
        ads?.disableAds || program.ads?.disableAds
          ? undefined
          : ads?.vast.replace(
              /iu=([^&]+)/,
              (_, original) => `iu=${program.ads?.adunit?.val ?? original}`
            ),
      adsExpirationTime: ads?.adsExpirationTime,
    };
  }, [program, mediaId]);

  useEffect(() => {
    if (program?.ads?.disable_autoPlay !== true) {
      setMediaId(program.mediaId);
    }

    setLiveMenuItems?.([]); //TO DO: The data is inside "program.liveMenu"
  }, [program]);

  const Video = useMemo(
    () =>
      mediaId !== undefined && isActive && playerProps !== undefined ? (
        <Player {...playerProps} autoplay={!Platform.isAndroidTV} />
      ) : undefined,
    [mediaId, isActive, playerProps]
  );

  const [VideoMobile, VideoDesktop] = useMobileOrDesktop(Video, Video);

  if (Platform.isAndroidTV && isVisible) {
    return VideoDesktop;
  }

  return (
    <>
      {VideoMobile !== undefined && (
        <View style={{ marginTop: getMarginTop }}>
          <>
            {program.spallaUrl ? (
              <WebDiv>
                <iframe
                  id="spalla-player"
                  src={program.spallaUrl}
                  title="Spalla Test"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                  style={{
                    width: '98%',
                    height: 250,
                  }}
                  allowFullScreen
                />
              </WebDiv>
            ) : (
              <>{Video}</>
            )}
          </>
        </View>
      )}
      {(Platform.OS === 'web' || !isFullScreen) && (
        <Hero program={program} hideCover={mediaId != undefined}>
          <Hero.Header
            program={program}
            mediaId={mediaId}
            Aside={
              VideoDesktop != undefined && !Platform.isAndroidTV ? (
                <Box width={Platform.isTV ? 0 : '80%'}>
                  <>
                    {program.spallaUrl && !Platform.isTV ? (
                      <WebDiv>
                        <iframe
                          id="spalla-player"
                          src={program.spallaUrl}
                          title="Spalla Test"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                          style={{
                            width: '100%',
                            height: width < 1300 ? 300 : width < 1600 ? 420 : 500,
                          }}
                          allowFullScreen
                        />
                      </WebDiv>
                    ) : (
                      <>{Video}</>
                    )}
                  </>
                </Box>
              ) : undefined
            }>
            <ProgramHeader />
            {Platform.isTV && (
              <View mt={calcSizeForTv(30)}>
                <Button
                  useTVFocus
                  hasTVPreferredFocus
                  style={{ width: calcSizeForTv(300), height: calcSizeForTv(52) }}
                  textStyle={{ fontSize: calcSizeForTv(24) }}
                  iconStyle={{ width: calcSizeForTv(30), height: calcSizeForTv(30) }}
                  iconType="Play"
                  variant="gradient"
                  gradientVariant={colors ? 'newBtnColors' : undefined}
                  size="md"
                  onPress={() => {
                    if (mediaId) {
                      play();
                    }
                    if (program?.ads?.disable_autoPlay === true || !mediaId) {
                      setMediaId(program.mediaId);
                      play();
                    }
                    setIsVisible(true);
                  }}>
                  Assistir
                </Button>
              </View>
            )}
            {program?.ads?.disable_autoPlay && !Platform.isTV && (
              <NewGradientButton
                width={calcSizeForDevice(300, 400)}
                height={calcSizeForDevice(40, 52)}
                display={mediaId && !Platform.isTV ? 'none' : undefined}
                onPress={() => {
                  if (mediaId) {
                    play();
                  }
                  if (program?.ads?.disable_autoPlay === true || !mediaId) {
                    setMediaId(program.mediaId);
                    play();
                  }
                  setIsVisible(true);
                }}>
                <Icons.Play
                  width={calcSizeForTv(24).toString()}
                  height={calcSizeForTv(24).toString()}
                  color="#070d31"
                />
                <ButtonText
                  fontWeight="bold"
                  color="#070d31"
                  textTransform="uppercase"
                  fontSize={calcSizeForTv(16)}>
                  Assistir
                </ButtonText>
              </NewGradientButton>
            )}

            <HStack alignItems="center" mt={10}>
              <ProgramShared />
            </HStack>
            <ProgramContent />
          </Hero.Header>
          <ProgramRelated />
        </Hero>
      )}
    </>
  );
};

export default LiveScreen;
