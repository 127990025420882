import { Pressable, View, useBreakpointValue } from '@gluestack-ui/themed';
import { useLinkTo } from '@react-navigation/native';
import React, { useMemo, useState } from 'react';
import { calcHeightWithWidth, calcSizeForDevice, calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';

import MediumCard from 'components/Card/MediumCard';
import { getIconByName } from 'components/Icons';
import { WebDiv } from 'components/WebDiv';

type TvRankingItemProps = {
  item: any;
  index: number;
};

const TvRankingItem: React.FC<TvRankingItemProps> = ({ item, index }) => {
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [isFocused, setIsFocused] = useState<boolean | null>(null);
  const rankingItemRef = React.useRef(null);
  const linkTo = useLinkTo();

  const svgWidthValue = useBreakpointValue({ base: 130, lg: calcSizeForTv(260) });
  const svgWidthTwoDigitsValue = useBreakpointValue({ base: 200, lg: calcSizeForTv(390) });
  const xValue = useBreakpointValue({ base: 95, lg: calcSizeForTv(185) });
  const xTwoDigitsValue = useBreakpointValue({ base: 75, lg: calcSizeForTv(200) });

  const mediumCardAspectRatio = 3 / 4;

  const svgHeightValue = useMemo(
    () => calcHeightWithWidth(svgWidthValue, mediumCardAspectRatio),
    [svgWidthValue, mediumCardAspectRatio]
  );

  const handlePress = () => {
    let link = item?.link;

    if (link.charAt(0) !== '/') {
      link = '/' + link;
    }

    linkTo(link);
  };

  const getIconName = (currentIndex: number) =>
    `Number${(currentIndex + 1).toString().padStart(2, '0')}` as IconsName;

  const IconComponent = getIconByName(getIconName(index));

  React.useEffect(() => {
    if (Platform.isWebTV) {
      const observer = new MutationObserver((mutations: any) => {
        let isItemFocused = false;

        for (const mutation of mutations) {
          if (mutation.target.classList.contains('focused')) {
            isItemFocused = true;
          }
        }

        setIsFocused(isItemFocused);
        setFocusedIndex(index);
      });

      if (rankingItemRef.current) {
        observer.observe(rankingItemRef.current, {
          subtree: true,
          childList: true,
          attributes: true,
          attributeFilter: ['class'],
        });
      }

      return () => observer.disconnect();
    }
  }, [rankingItemRef]);

  if (!IconComponent) {
    return null;
  }

  return (
    <WebDiv ref={rankingItemRef} className="ranking-item">
      <Pressable
        role="link"
        mt={calcSizeForDevice(20)}
        display="flex"
        flexDirection="row"
        alignItems="center"
        onFocus={() => setFocusedIndex?.(index)}
        onBlur={() => setFocusedIndex?.(null)}
        onPress={handlePress}>
        <View
          height={0.8 * svgHeightValue}
          width={index >= 9 ? svgWidthTwoDigitsValue : svgWidthValue}
          position="absolute"
          right={index >= 9 ? xTwoDigitsValue : xValue}>
          <IconComponent
            color="#2240FF"
            innerColor={
              focusedIndex === index && (Platform.isAndroidTV || isFocused) ? '#001075' : 'none'
            }
          />
        </View>
        <WebDiv className="navigation-ignore">
          <View ml={index >= 9 ? svgWidthTwoDigitsValue : svgWidthValue}>
            <MediumCard
              uri={item?.image?.url}
              link={item?.link}
              title={item?.title || ''}
              imgTitle={item?.image?.title || ''}
              style={{
                borderWidth: calcSizeForTv(4),
                borderColor:
                  focusedIndex === index && (Platform.isAndroidTV || isFocused)
                    ? Platform.isAndroidTV
                      ? 'red'
                      : 'white'
                    : 'transparent',
              }}
            />
          </View>
        </WebDiv>
      </Pressable>
    </WebDiv>
  );
};

export default React.memo(TvRankingItem);
