import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store/index';
import { getAgeRatingFilterString } from 'utils/ageRating';

type RequestHandlerParams = {
  args: string | FetchArgs;
  api: BaseQueryApi;
  extraOptions: object;
};

export const appendIsKidInfo = (params: RequestHandlerParams): RequestHandlerParams => {
  const { args, api, extraOptions } = params;

  if (typeof args !== 'string') {
    const state = api.getState() as RootState;

    let modifiedParams: Record<string, unknown> = { ...args.params };
    if (state.profile.isKid) modifiedParams = { ...modifiedParams, kids: true };

    return { args: { ...args, params: modifiedParams }, api, extraOptions };
  }

  return { args, api, extraOptions };
};

export const appendRatingsInfo = (params: RequestHandlerParams): RequestHandlerParams => {
  const { args, api, extraOptions } = params;
  const safeArgs = typeof args === 'string' ? {} : args;
  const url = typeof args === 'string' ? args : args.url;

  const state = api.getState() as RootState;

  const useRatings = !state.profile.isKid && state.auth.isAuthenticated;
  const ratings = useRatings ? getAgeRatingFilterString(state.profile.ageRating) : '';

  const hasQuestionMark = url.includes('?');
  const modifiedUrl = hasQuestionMark ? `${url}&${ratings}` : `${url}?${ratings}`;

  return { args: { ...safeArgs, url: modifiedUrl }, api, extraOptions };
};

export const appendCopyrightInfo = (params: RequestHandlerParams): RequestHandlerParams => {
  const { args, api, extraOptions } = params;

  if (typeof args !== 'string') {
    let modifiedParams: Record<string, unknown> = { ...args.params };
    modifiedParams = { ...modifiedParams, noCopyright: null };

    return { args: { ...args, params: modifiedParams }, api, extraOptions };
  }

  return { args, api, extraOptions };
};

export const endpointToBeModified: Record<string, CallableFunction[]> = {
  '/api/v1/BandplayProgram': [appendIsKidInfo, appendRatingsInfo, appendCopyrightInfo],
  '/api/User/BandplayWatching': [appendRatingsInfo, appendCopyrightInfo],
  '/api/v1/BandplayHighlights': [appendRatingsInfo, appendCopyrightInfo],
  '/api/User/BandplayFavorite': [appendIsKidInfo, appendCopyrightInfo],
};

export const handleBaseQuery = (baseQuery: ReturnType<typeof fetchBaseQuery>) => {
  return (args: string | FetchArgs, api: BaseQueryApi, extraOptions: object) => {
    const rawURL = typeof args === 'string' ? args : args.url;
    const [url] = rawURL.split('?');
    const urlsToBeModified = Object.keys(endpointToBeModified);

    if (typeof args != 'string' && args.params) {
      args.params = convertParamsToCms(args.params);
    }

    if (urlsToBeModified.includes(url)) {
      const urlHandlers = endpointToBeModified[url];
      const defaultParams = { args, api, extraOptions };
      const result = urlHandlers.reduce((result, handler) => handler(result), defaultParams);
      return baseQuery(result.args, result.api, result.extraOptions);
    }

    return baseQuery(args, api, extraOptions);
  };
};

type QueryParams = {
  [key: string]: string | string[] | number | number[] | boolean | QueryParams | QueryParams[];
};

type ParamValue = string | number | boolean;

export const convertParamsToCms = (params: QueryParams): { [key: string]: ParamValue[] } => {
  return denormalizeParams(params).reduce<{ [key: string]: ParamValue[] }>((carr, [key, value]) => {
    if (carr[key] == undefined) {
      carr[key] = [];
    }
    carr[key].push(value);
    return carr;
  }, {});
};

// Exemplo:
//
// {
//   scalar: 'value',
//   array: ['cat1', 'cat2', 'catN'],
//   obj: { _id: 'target' },
//   nested: { mult: { with: { _id: 'target' }, skip: { this: 'ta' } } },
//   combined: [{ ignore: 'this', skip: 'self', _id: 'target', pass: { _id: 'ignored' } }],
//   idless: { b: { c: '1' } },
// }
//
// saída:
//
// {
//   scalar: ['value'],
//   array: ['cat1', 'cat2', 'catN'],
//   'obj._id': ['target'],
//   'nested.mult.with._id': ['target'],
//   'combined._id': ['target'],
//   'idless.b.c': ['1'],
// }
const denormalizeParams = (params: QueryParams): [string, ParamValue][] => {
  const items: [string, ParamValue][] = [];
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      for (const svalue of value) {
        if (typeof svalue == 'object') {
          mergeObjectParam(items, svalue, key);
        } else {
          items.push([key, svalue]);
        }
      }
    } else if (typeof value == 'object') {
      mergeObjectParam(items, value, key);
    } else if (value !== undefined) {
      items.push([key, value]);
    }
  }
  return items;
};

const mergeObjectParam = (ref: [string, ParamValue][], value: QueryParams, key: string): void => {
  const items = denormalizeParams(value).map<[string, ParamValue]>(([k, v]) => [key + '.' + k, v]);
  const item = items.find(([k]) => k.endsWith('._id'));
  // a preferência são os _id, na existência de um _id
  // todos os outros valores são descartados
  // no caso de múltiplos _id é considerado o da menor profundidade
  if (item) {
    ref.push(item);
  }
  // somente se não tiver _id em nenhuma profundidade
  // os values serão incluídos
  else {
    ref.push(...items);
  }
};
