import { Box, ButtonText, Button as GlueButton, HStack, View } from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import * as WebBrowser from 'expo-web-browser';
import { useMobileOrDesktop } from 'hoc/mobileOrDesktop';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import Button from 'components/Button';
import NewGradientButton from 'components/Button/NewGradienteButton';
import Hero from 'components/Hero';
import { Icons } from 'components/Icons';
import Player from 'components/Player';
import { useStream } from 'components/Player/Stream/context';
import { usePlayer } from 'components/Player/context';
import ProgramContent from 'components/Program/Content';
import ProgramHeader from 'components/Program/Header';
import ProgramRelated from 'components/Program/Related';
import ProgramShared from 'components/Program/Shared';
import { useProgram } from 'components/Program/context';

const ExternalProgram: React.FC<RootStackScreenProps<'Program'>> = ({ navigation }) => {
  const {
    isPlaying,
    isVisible,
    isFullScreen,
    isStreaming,
    pause,
    play,
    setIsVisible,
    mute,
    setNextTrack,
  } = usePlayer();
  const analytics = useAnalytics();
  const program = useProgram();
  const { ads } = useGlobalConfig();
  const { isConnected } = useStream();
  const [isActive, setIsActive] = useState(false);
  const [mediaId, setMediaId] = useState<string>();

  const { width } = useWindowDimensions();

  useEffect(() => {
    setNextTrack(undefined);
  }, []);

  const buttonDirection = !Platform.isTV && width < 720 ? 'column' : 'row';

  useEffect(() => {
    return navigation.addListener('beforeRemove', async () => {
      if (isPlaying && !isStreaming) {
        pause();
      }
      mute(false);
    });
  }, [navigation, isPlaying, isStreaming, pause]);

  useFocusEffect(
    useCallback(() => {
      setIsActive(true);

      return () => {
        if (!isStreaming) pause();
        setIsActive(false);
      };
    }, [isStreaming])
  );

  useFocusEffect(
    useCallback(() => {
      analytics.triggerMovieScreenPageViewEvent({
        programId: program._id,
        programTitle: program.title,
        programAgeRating: program.rating,
        programAdUnit: program.ads?.adunit?.val!,
        programCast: program.cast,
        programCategory: (program.category || []).map((tag) => tag.name).join(', '),
        programDirector: program.director,
        programGenre: (program.genre || []).map((genre) => genre.name).join(', '),
      });
    }, [])
  );

  const playerProps: PlayerProps | undefined = useMemo(() => {
    if (!program) {
      return undefined;
    }

    const media: PlayerMedia = {
      title: program.title,
      image: [program.imageVertical?.url!, program.imageHorizontal?.url!],
      duration: program.duration,
      programId: program._id,
      programTitle: program.title,
      programUrl: program.url,
      ratingValue: program.rating,
      ratingDescription: program.rateDescription,
      videoTracks: [],
    };

    const mediaIdDub = program.mediaIdDub;

    if (mediaIdDub) {
      media.videoTracks.push({
        language: 'dub',
        trackId: mediaIdDub,
      });
    }

    media.videoTracks.push({ language: 'origin', trackId: program.mediaId });

    return {
      media,
      isLive: false,
      adTagUrl:
        ads?.disableAds || program.ads?.disableAds
          ? undefined
          : ads?.vast.replace(
              /iu=([^&]+)/,
              (_, original) => `iu=${program.ads?.adunit?.val ?? original}`
            ),
      adsExpirationTime: ads?.adsExpirationTime,
    };
  }, [program, mediaId, isConnected]);

  async function redirectToExternalLink(url?: string) {
    if (!url) {
      console.error('URL inválida ou não definida');
      return;
    }
    try {
      if (Platform.OS === 'web') {
        window.open(url, '_blank');
      } else {
        await WebBrowser.openBrowserAsync(url);
      }
    } catch (error) {
      console.error('Erro ao abrir o navegador:', error);
    }
  }

  const Video = useMemo(
    () =>
      mediaId !== undefined && isActive && playerProps !== undefined ? (
        <Player {...playerProps} startTime={0} autoplay />
      ) : undefined,
    [mediaId, isActive, playerProps]
  );

  const [VideoMobile, VideoDesktop] = useMobileOrDesktop(Video, Video);

  if (Platform.isAndroidTV && isVisible) {
    return VideoDesktop;
  }

  return (
    <>
      {VideoMobile !== undefined && (
        <View style={{ marginTop: isFullScreen ? 0 : Platform.isMobileWeb() ? 45 : 80 }}>
          {Video}
        </View>
      )}
      {(Platform.isAndroidTV || Platform.OS === 'web' || !isFullScreen) && (
        <Hero program={program} hideCover={mediaId !== undefined}>
          <Hero.Header
            program={program}
            mediaId={mediaId}
            Aside={
              VideoDesktop !== undefined && !Platform.isAndroidTV ? (
                <Box aspectRatio={1 / 0.56} width={Platform.isTV ? 0 : '80%'}>
                  {Video}
                </Box>
              ) : undefined
            }>
            <ProgramHeader />
            <HStack space="lg" flexDirection={buttonDirection}>
              {!Platform.isTV && (
                <NewGradientButton
                  borderColor="transparent"
                  onPress={() => {
                    analytics.triggerMediaPlayActionEvent({
                      mediaType: 'video',
                      programId: program._id,
                      programTitle: program.title,
                      programAgeRating: program.rating,
                      ClickToExternalLink: program.mediaPartner.PartnerUrl,
                    });
                    redirectToExternalLink(program.mediaPartner.PartnerUrl);
                  }}>
                  <Icons.Play color={'#070D31'} />
                  <ButtonText color={'#070D31'} ml={4}>
                    {`Assista os episódios ${program.mediaPartner.isMaxMedia ? 'na Max' : 'Aqui'}`}
                  </ButtonText>
                </NewGradientButton>
              )}
              {Platform.isAndroidTV ? (
                <Button
                  id="PlayBtn"
                  useTVFocus
                  hasTVPreferredFocus
                  style={{
                    width: calcSizeForDevice(300, 400),
                    height: calcSizeForDevice(40, 70),
                    borderRadius: 20,
                  }}
                  borderColor="#2240FF"
                  textStyle={{ fontSize: calcSizeForDevice(18, 16) }}
                  iconStyle={{
                    width: calcSizeForDevice(16, 40),
                    height: calcSizeForDevice(16, 45),
                  }}
                  iconType={'ClapperboardPlay'}
                  variant="ghost"
                  size="md"
                  isFullWidth={Platform.OS !== 'web' && !Platform.isTV}
                  onPress={() => {
                    if (!mediaId) setMediaId(program.mediaId);

                    analytics.triggerMediaPlayActionEvent({
                      mediaType: 'video',
                      programId: program._id,
                      programTitle: program.title,
                      programAgeRating: program.rating,
                      ClickWatchTrailer: true,
                    });

                    play();

                    if (Platform.isAndroidTV) setIsVisible(true);
                  }}>
                  <ButtonText ml={8} color={'#fff'}>
                    Assista ao trailer
                  </ButtonText>
                </Button>
              ) : (
                <GlueButton
                  id="PlayBtn"
                  sx={{
                    ':hover': {
                      bg: 'transparent',
                    },
                  }}
                  style={{
                    ...(Platform.OS === 'web' || Platform.isTV
                      ? {
                          width: calcSizeForDevice(300, 400),
                          display: mediaId && !Platform.isTV ? 'none' : undefined,
                          height: calcSizeForDevice(40, 52),
                        }
                      : { display: mediaId && !Platform.isTV ? 'none' : undefined }),
                  }}
                  variant="outline"
                  borderColor="#2240FF"
                  rounded="$3xl"
                  onPress={() => {
                    if (!mediaId) setMediaId(program.mediaId);

                    analytics.triggerMediaPlayActionEvent({
                      mediaType: 'video',
                      programId: program._id,
                      programTitle: program.title,
                      programAgeRating: program.rating,
                    });

                    play();

                    if (Platform.isAndroidTV) setIsVisible(true);
                  }}>
                  <Icons.ClapperboardPlay color={'#fff'} />
                  <ButtonText ml={8} color={'#fff'}>
                    Assista ao trailer
                  </ButtonText>
                </GlueButton>
              )}
            </HStack>
            <HStack
              alignItems="center"
              space="md"
              marginTop={calcSizeForDevice(10, 14)}
              marginBottom={calcSizeForDevice(10, 20)}>
              <ProgramShared />
            </HStack>
            <ProgramContent />
          </Hero.Header>

          <View py={calcSizeForDevice(24)}>
            <ProgramRelated />
          </View>
        </Hero>
      )}
    </>
  );
};

export default ExternalProgram;
