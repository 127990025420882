import flowplayer from '@flowplayer/player';
import '@flowplayer/player/flowplayer.css';
import AdsPlugin from '@flowplayer/player/plugins/ads';
import AirPlayPlugin from '@flowplayer/player/plugins/airplay';
import ChromecastPlugin from '@flowplayer/player/plugins/chromecast';
import HLSPlugin from '@flowplayer/player/plugins/hls';
import MessagePlugin from '@flowplayer/player/plugins/message';
import QSelPlugin from '@flowplayer/player/plugins/qsel';
import SubtitlesPlugin from '@flowplayer/player/plugins/subtitles';
import Flowplayer, { useFlowplayer } from '@flowplayer/react-flowplayer';
import { pt } from '@flowplayer/translations';
import { View } from '@gluestack-ui/themed';
import { useGlobalConfig } from 'context/GlobalContext';
import Constants from 'expo-constants';
import userRequired from 'hoc/userRequired';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { renderToString } from 'react-dom/server';
import { ErrorHandlerType } from 'screens/ErrorHandler';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { ProgramType } from 'utils/enum';
import { Platform } from 'utils/platform';

import { useAutoNext } from '../AutoNext';
import { usePlayer } from '../context';
import ASelPlugin from './UI/Flowplayer/ASelPlugin';
import { VideoInfoView } from './UI/Info';

import { useProgram } from 'components/Program/context';

// idioma
// @ts-ignore
flowplayer.i18n.pt = pt;

// plugins
if (Platform.isTV) {
  flowplayer(
    HLSPlugin,
    AirPlayPlugin,
    SubtitlesPlugin,
    QSelPlugin,
    AdsPlugin,
    MessagePlugin,
    ASelPlugin
  );
} else {
  flowplayer(
    HLSPlugin,
    AirPlayPlugin,
    ChromecastPlugin,
    SubtitlesPlugin,
    QSelPlugin,
    AdsPlugin,
    MessagePlugin,
    ASelPlugin
  );
}

const languages: Record<string, string> = {
  dub: 'Dublado',
  origin: 'Original',
  en: 'Inglês',
  pt: 'Português',
  fr: 'Francês',
  es: 'Espanhol',
};

const VideoPlayer = forwardRef<CustomPlayerRef, CustomPlayerProps>((props, ref) => {
  const {
    isLive,
    startTime,
    mediaUrl,
    media,
    trackId,
    adTagUrl,
    setTrack,
    setupTextTracks,
    reloadMedia,
    loadAttempts,
    error,
    play,
    pause,
    setError,
    isVisible,
    setIsVisible,
    isMuted,
    mute,
    isPlaying,
    setFullScreen,
    isFullScreen,
  } = usePlayer();

  const playerRef = useRef<HTMLDivElement>(null);
  const playerApi = useFlowplayer(playerRef);

  const program = useProgram();
  const analytics = useAnalytics();
  const { hasInternet } = useGlobalConfig();
  const isRadio = program?.type === ProgramType.RADIO;
  const [showingError, setShowingError] = useState(false);
  const progressRef = useRef<PlayerMediaProgress>({ currentTime: 0 });

  const geoErrorCodes = ['http.403', 'http.live.403'];
  const networkErrorCodes = ['http.levelLoadError', 'http.fragLoadError', 'hls.networkError'];
  const handleFullScreen = (value: boolean) => setFullScreen(value);
  const [currentTime, setCurrentTime] = React.useState(0);

  const { showNextTrack, autoNextCard } = useAutoNext({
    currentTime,
    duration: media?.duration || 0,
    forceMobileView: !isFullScreen,
  });

  useImperativeHandle(
    ref,
    () => ({
      seek(time: number) {
        playerApi?.fastSeek(time);
      },
      progress(): PlayerMediaProgress {
        return progressRef.current;
      },
      getNativePlayerRef() {
        return playerApi;
      },
    }),
    [playerApi, progressRef]
  );

  useEffect(() => {
    if (!playerApi || !mediaUrl) {
      return;
    }

    const playerStartTime = progressRef.current.currentTime || startTime || 0;
    const playerDuration = media?.duration || progressRef?.current?.duration || 0;
    const timeRemaining = playerDuration - playerStartTime;
    const watchedEntireMedia = playerStartTime >= 0.96 * playerDuration;
    const normalizedStartTime = watchedEntireMedia || timeRemaining <= 30 ? 0 : playerStartTime;

    playerApi.setSrc(mediaUrl);
    if (!isLive) playerApi.setOpts({ start_time: normalizedStartTime });
    playerApi.toggleFullScreen(isFullScreen);
    setTimeout(() => playerApi.play(), 200);
  }, [playerApi, mediaUrl, startTime]);

  // re-tentativa de exibição de uma mídia com problema
  useEffect(() => {
    if (!playerApi || !error) return;

    const timer = setTimeout(() => {
      reloadMedia();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [playerApi, error, loadAttempts]);

  // controlador de exibição de erros
  // não é possível esconder/remover uma mensagem em exibição
  useEffect(() => {
    if (!playerApi || !error || showingError) return;

    if (
      Platform.isTV &&
      error.code === 'ERR_NETWORK' &&
      Math.floor(playerApi.buffered.end(0)) > Math.floor(playerApi.currentTime)
    )
      return;

    const timeout = 3000;

    playerApi.setState(flowplayer.states.ERRORED, true);

    playerApi.emit(playerApi.message?.events.SHOW_MESSAGE!, {
      message:
        'Este vídeo não está disponível para a sua região\n ou não está sendo transmitido no momento.',
      timeout,
    });

    setTimeout(() => {
      playerApi.setState(flowplayer.states.ERRORED, false);
      setShowingError(false);
    }, timeout);

    setShowingError(true);
  }, [playerApi, error, showingError]);

  useEffect(() => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      const clickListItemWithText = (text: string) => {
        const parentDiv = document.querySelector('.fp-qsel');

        if (parentDiv) {
          const list = parentDiv.querySelector('ol');

          if (list) {
            const listItems = list.getElementsByTagName('li');

            for (let i = 0; i < listItems.length; i++) {
              if (listItems[i].textContent === text) {
                listItems[i].click();
                break;
              }
            }
          }
        }
      };

      setTimeout(() => {
        clickListItemWithText('477p');
      }, 2000);
    }
  }, [isVisible]);

  useEffect(() => {
    analytics.triggerPlayerEvent({
      mediaType: 'video',
      programId: program._id,
      programTitle: program.title,
      programAgeRating: program.rating,
    });
  }, []);

  useEffect(() => {
    mute(!isRadio && !!isLive && !Platform.isTV);
  }, [isLive]);

  React.useEffect(() => {
    const bootstrap = () => {
      const currentTimeValue = playerApi?.currentTime || 0;
      progressRef.current.currentTime = currentTimeValue;
      setCurrentTime(currentTimeValue);
    };

    if (!isPlaying || !playerApi) return;
    const timer = setInterval(bootstrap, 900);
    return () => clearInterval(timer);
  }, [playerApi, isPlaying]);

  // inicialização do player
  useEffect(() => {
    if (!playerApi || !media || !trackId) {
      return;
    }

    playerApi.setOpts({
      muted: false,
      live: isLive,
      autoplay: isLive || Platform.isTV,
      //@ts-ignore
      ima: { ads: [{ time: 0, adTag: Platform.isTV ? undefined : adTagUrl }] },
      asel: {
        header:
          !Platform.isTV && !showNextTrack
            ? renderToString(<VideoInfoView {...media} />)
            : undefined,
        tracks: media?.videoTracks?.map((item) => ({
          id: item.trackId,
          title: languages[item.language],
          selected: item.trackId === trackId,
        })),
      },
    });

    const handleAudioChange = (event: any) => {
      const i = media?.videoTracks.findIndex((x) => x.trackId === event.detail);
      if (i !== undefined) setTrack(i);
    };

    const handleError = (event: any) => {
      event.preventDefault();
      const hasGeoError = geoErrorCodes.includes((event?.detail as any)?.code);
      const hasNetworkError = networkErrorCodes.includes((event?.detail as any)?.code);

      if (hasGeoError) {
        handleFullScreen(false);
        return setError(ErrorHandlerType.GEOLOCATION_ERROR);
      }

      // if (hasNetworkError) {
      //   handleFullScreen(false);
      //   setError(ErrorHandlerType.NETWORK_ERROR);
      // }

      setError(ErrorHandlerType.NETWORK_ERROR);
    };

    const handlePlay = () => play();
    const handlePause = () => pause();

    const handleEnded = () => {
      const flowplayerDiv = document.querySelectorAll('.fp-fullscreen-exit')[0] as HTMLHtmlElement;

      if (flowplayerDiv) {
        flowplayerDiv.click();
      }

      if (isVisible) {
        setIsVisible(false);
      }
    };

    const handleSessionEnded = () => {
      reloadMedia();
      handlePlay();
    };

    setupTextTracks([]);

    playerApi.on('asel:id', handleAudioChange);
    playerApi.on(flowplayer.events.FULLSCREEN_ENTER, () => handleFullScreen(true));
    playerApi.on(flowplayer.events.FULLSCREEN_EXIT, () => handleFullScreen(false));
    playerApi.on(flowplayer.events.ERROR, handleError);
    playerApi.on(flowplayer.events.PLAY, handlePlay);
    playerApi.on(flowplayer.events.PAUSE, handlePause);
    playerApi.on(flowplayer.events.ENDED, handleEnded);
    playerApi.on(flowplayer.events.ENDED, handleEnded);
    playerApi.on(flowplayer.events.REMOTE_SESSION_ENDED, handleSessionEnded);

    return () => {
      playerApi.off('asel:id', handleAudioChange);
      playerApi.off(flowplayer.events.ERROR, handleError);
      playerApi.off(flowplayer.events.PLAY, handlePlay);
      playerApi.off(flowplayer.events.PAUSE, handlePause);
      playerApi.off(flowplayer.events.ENDED, handleEnded);
    };
  }, [
    playerApi,
    media,
    trackId,
    isMuted,
    play,
    pause,
    setupTextTracks,
    showNextTrack,
    hasInternet,
  ]);

  // Initialize Google Cast SDK
  useEffect(() => {
    const initializeCast = () => {
      if (!chrome?.cast) {
        return;
      }

      const context = cast.framework.CastContext.getInstance();

      context.setOptions({
        receiverApplicationId: 'E333822E', // Default Chromecast Receiver ID
        autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
      });

      const handleSession = () => {
        const session = context.getCurrentSession();

        if (!session) {
          return playerApi?.play();
        }

        const media = session.getMediaSession();

        if (!media) {
          return playerApi?.play();
        }

        if (!mediaUrl) return;

        const baseMediaUrl = mediaUrl.split('?')[0];
        handleCastMessage(session.Ya.friendlyName);

        if (!media.media.contentId?.includes(baseMediaUrl)) {
          changeVideo(mediaUrl, media.title || '');
        }
      };

      // Listen for session state changes
      context.addEventListener(
        cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        (event: cast.framework.CastContextEventData) => {
          switch (event.sessionState) {
            case cast.framework.SessionState.SESSION_STARTED:
              const session = context.getCurrentSession();

              handleCastMessage(session.Ya.friendlyName);
              changeVideo(mediaUrl, media?.title || '');
              break;
            case cast.framework.SessionState.SESSION_ENDED:
              removeCastMessage();
              break;
          }
        }
      );

      handleSession();
    };

    if (window.cast && cast.framework) {
      initializeCast();
    } else {
      window.__onGCastApiAvailable = (isAvailable: boolean) => {
        if (isAvailable) {
          initializeCast();
        }
      };
    }
  }, [mediaUrl]);

  const castVideo = (url: string, title: string) => {
    const session = cast.framework.CastContext.getInstance().getCurrentSession();

    if (session) {
      const mediaInfo = new chrome.cast.media.MediaInfo(url, 'video/mp4');
      mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
      mediaInfo.metadata.title = title;

      const request = new chrome.cast.media.LoadRequest(mediaInfo);
      session
        .loadMedia(request)
        .then(() => {})
        .catch(() => {});
    }
  };

  const handleCastMessage = (sessionName: string) => {
    const existingOverlay = playerApi?.root.querySelector('.cast-message');
    const flowplayerMiddleClass = playerApi?.root.querySelector('.fp-middle');

    if (!existingOverlay) {
      const overlay = document.createElement('div');
      overlay.className = 'cast-message';
      overlay.innerText = `Transmitindo para ${sessionName}`;
      flowplayerMiddleClass?.prepend(overlay);
    }
  };

  const removeCastMessage = () => {
    const existingOverlay = playerApi?.root.querySelector('.cast-message');

    if (existingOverlay) {
      existingOverlay.remove();
    }
  };

  const changeVideo = (newVideoUrl: string = '', title: string = '') => {
    if (playerRef.current) {
      const player = playerRef.current as any;
      player.src = newVideoUrl;
    }

    castVideo(newVideoUrl, title);
  };

  const flowplayerUI = playerApi?.parentElement?.querySelector('flowplayer-ui')!;

  useEffect(() => {
    const fullScreenButton = document.querySelector(
      isFullScreen ? '.fp-fullscreen-exit' : '.fp-fullscreen'
    ) as HTMLDivElement;

    if (fullScreenButton) {
      if (showNextTrack) {
        fullScreenButton.style.visibility = 'hidden'; // Esconde o botão
      } else {
        fullScreenButton.style.visibility = 'visible'; // Mostra o botão
      }
    }
  }, [showNextTrack, isFullScreen]);

  const handlePlayAnalytics = () => {
    analytics.triggerMediaPlayActionEvent({
      mediaType: 'video',
      programId: program._id,
      programTitle: program.title,
      programAgeRating: program.rating,
    });
  };

  const handlePauseAnalytics = () => {
    analytics.triggerMediaPauseActionEvent({
      mediaType: 'video',
      programId: program._id,
      programTitle: program.title,
      programAgeRating: program.rating,
    });
  };

  useEffect(() => {
    const playElement = playerApi?.parentElement?.querySelector('flowplayer-small-play-icon');
    const pauseElement = playerApi?.parentElement?.querySelector('flowplayer-small-pause-icon');
    playElement?.addEventListener('click', handlePlayAnalytics);
    pauseElement?.addEventListener('click', handlePauseAnalytics);

    return () => {
      playElement?.removeEventListener('click', handlePlayAnalytics);
      pauseElement?.removeEventListener('click', handlePauseAnalytics);
    };
  }, [playerApi]);

  return (
    <>
      <Flowplayer
        ref={playerRef}
        src={undefined}
        token={Constants.expoConfig?.extra?.flowPlayerToken}
        opts={{
          lang: 'pt',
          controls: !showNextTrack,
          hls: { liveSyncDurationCount: 5, liveMaxLatencyDurationCount: 20 },
        }}
      />
      {flowplayerUI && showNextTrack && !Platform.isTV
        ? createPortal(
            <View pr={isFullScreen ? undefined : '$4'} pb={isFullScreen ? undefined : '$4'}>
              {autoNextCard}
            </View>,
            flowplayerUI
          )
        : null}
    </>
  );
});

VideoPlayer.displayName = 'VideoPlayer';

export default userRequired(VideoPlayer);
