import { View } from '@gluestack-ui/themed';
import {
  DefaultTheme,
  LinkingOptions,
  NavigationContainer,
  createNavigationContainerRef,
} from '@react-navigation/native';
import { NativeStackScreenProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { useBottomSheet } from 'context/BottomSheetContext';
import { useGlobalConfig } from 'context/GlobalContext';
import { getDeviceTypeAsync } from 'expo-device';
import { useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { SpotlightTourProvider } from 'react-native-spotlight-tour';
import { useSelector } from 'react-redux';
import CategoryScreen from 'screens/Category';
import TvEasterEggScreen from 'screens/EasterEgg/index.ctv';
import ErrorHandler, { ErrorHandlerType } from 'screens/ErrorHandler';
import FavoritesScreen from 'screens/Favorites';
import ContactUsScreen from 'screens/Help/ContactUsScreen';
import TvContactUsScreen from 'screens/Help/ContactUsScreen.ctv';
import FAQSectionScreen from 'screens/Help/FAQSectionScreen';
import TvFAQSectionScreen from 'screens/Help/FAQSectionScreen.ctv';
import LegalTermsScreen from 'screens/Help/LegalTermsScreen';
import TvLegalTermsScreen from 'screens/Help/LegalTermsScreen.ctv';
import TvMyProfile from 'screens/Help/MyProfile.ctv';
import HomeScreen from 'screens/Home/HomeScreen';
import LoginScreen from 'screens/Login';
import NotFound from 'screens/NotFound';
import Onboarding from 'screens/Onboarding';
import ProgramScreen from 'screens/Program/ProgramScreen';
import Search from 'screens/Search';
import WebViewScreen from 'screens/WebView';
import AnalyticsScreen from 'services/analytics/Screen';
import { RootState } from 'store/index';
import theme from 'theme';
import { Platform } from 'utils/platform';
import { isEmpty } from 'utils/string';

import BandMenuModal from 'components/BandMenuModal/BandMenuModal';
import BottomMenu from 'components/BottomMenu';
import Header from 'components/Header';
import LiveMenuProvider from 'components/LiveMenu';
import PersistentControl from 'components/Player/Stream/UI/PersistentControl';
import { usePlayer } from 'components/Player/context';
import SidebarMenu from 'components/SidebarMenu';
import { useTourSteps } from 'components/SpotlightTour/useSpotlightStep';
import TvSubMenuArea from 'components/TvSubMenuArea';
import { WebDiv } from 'components/WebDiv';

export type RootStackParamList = {
  Media: undefined;
  Search: undefined;
  Favorites: undefined;
  ContactUs: undefined;
  FaqSection: undefined;
  LegalTerms: undefined;
  VideoPlayer: undefined;
  Category: { slug: string };
  Login: { redirect?: string } | undefined;
  Program: { programId: string; mediaId?: string; seasonId?: string };
  Home: undefined;
  Listen: { id: string };
  Videos: { id: string };
  Generic: { id: string };
  MyProfile: undefined;
  EasterEgg: undefined;
  NotFound: { path?: string } | undefined;
  Analytics: undefined;
  TvSubMenuArea: undefined;
  WebView: undefined;
  Onboarding: undefined;
};

export type RootStackScreenProps<T extends keyof RootStackParamList> = NativeStackScreenProps<
  RootStackParamList,
  T
>;

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: ['bandplay://', 'https://www.bandplay.com'],
  config: {
    screens: {
      Home: '',
      Listen: 'ouca',
      Videos: 'assista',
      Generic: ':id/h',
      Search: 'buscar',
      Favorites: 'favoritos',
      Login: 'entrar',
      Program: ':programId/:seasonId?/:episodeName?/:mediaId?',
      Category: 'categorias/:slug',
      FaqSection: 'ajuda',
      LegalTerms: 'termos',
      TvSubMenuArea: 'tvSubMenuArea',
      ContactUs: 'contato',
      MyProfile: 'perfil',
      EasterEgg: 'easter-egg',
      Analytics: 'analytics',
      Onboarding: 'onboarding',
      NotFound: '*',
    },
  },
};

const Stack = createNativeStackNavigator<RootStackParamList>();
export const navigationRef = createNavigationContainerRef<any>();

export const AppNavigator = () => {
  const { dataLogin, setColor, hasInternet } = useGlobalConfig();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const userDeviceData = useSelector((state: RootState) => state.userDeviceData);
  const tourSteps = useTourSteps();
  const {
    setError,
    media,
    reloadMedia,
    pause,
    isFullScreen,
    hasTransmissionError,
    setHasTransmissionError,
  } = usePlayer();
  const { handleToggleSheet, visible } = useBottomSheet();
  const [isNavigationReady, setIsNavigationReady] = useState(false);
  const hasBottomMenu =
    !isFullScreen &&
    !Platform.isTV &&
    (Platform.OS === 'web' || userDeviceData.hasCompletedOnboarding);

  useEffect(() => {
    if (Platform.OS === 'web') {
      Linking.getInitialURL().then((url: any) => {
        getDeviceTypeAsync().then(async (deviceType: any) => {
          const parts = url.split('/');
          const position = parts.slice(0, 3).join('/').length;
          const urlWeb = url.substr(0, position);
          const path = url?.replace(`${urlWeb}/`, '');

          const urlTo = path.indexOf('?') !== -1 ? path.substr(0, path.indexOf('?')) : path;

          if (deviceType === 1 || deviceType === 2) {
            if (Platform.isSafari && isEmpty(urlTo)) return;

            window.location.href = `bandplay://${urlTo}`;
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    setHasTransmissionError(!hasInternet);

    if (hasInternet) {
      setError(undefined);
      if (media !== undefined) reloadMedia();
    } else {
      if (media !== undefined) {
        pause();
      }
    }
  }, [hasInternet]);

  useEffect(() => {
    isFullScreen && visible && handleToggleSheet();
  }, [isFullScreen]);

  const isWebNotTV = Platform.OS === 'web' && !Platform.isTV;
  const shouldSkipAuthentication = dataLogin?.skipAuthenticated;

  const initialRouteName =
    isAuthenticated || isWebNotTV || shouldSkipAuthentication
      ? 'Home'
      : Platform.OS !== 'web' && !Platform.isTV
      ? 'Onboarding'
      : 'Login';

  return (
    <SpotlightTourProvider
      steps={tourSteps}
      overlayColor="black"
      overlayOpacity={0.5}
      nativeDriver
      onBackdropPress="continue"
      spotPadding={0}
      motion="slide"
      shape="circle">
      <SafeAreaProvider style={{ backgroundColor: theme.colors.base.darkBlue }}>
        <LiveMenuProvider navigationRef={navigationRef}>
          <NavigationContainer
            ref={navigationRef}
            linking={linking}
            onReady={() => setIsNavigationReady(true)}
            theme={{
              ...DefaultTheme,
              colors: { ...DefaultTheme.colors, background: theme.colors.base.darkBlue },
            }}
            onStateChange={(e) => {
              const lastStack = e?.routes[e.routes.length - 1];
              if (lastStack?.name !== 'Program') {
                setColor(undefined);
              }
              visible && handleToggleSheet();
            }}>
            <View
              style={{
                zIndex: !hasInternet ? 9999 : -1,
                position: 'absolute',
                top: 0,
                left: !hasInternet ? 0 : -9999999,
                right: 0,
                bottom: 0,
              }}>
              <ErrorHandler error={ErrorHandlerType.NO_INTERNET} />
            </View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              {!Platform.isTV && <BandMenuModal navigationRef={navigationRef} />}
              {Platform.isTV && isAuthenticated && hasInternet && <SidebarMenu />}
              <WebDiv style={{ flex: 1, display: 'flex' }} className="navigation-container">
                <Stack.Navigator
                  screenOptions={{ headerShown: false, animation: 'none' }}
                  initialRouteName={initialRouteName}>
                  {(isAuthenticated ||
                    (Platform.OS === 'web' && !Platform.isTV) ||
                    dataLogin?.skipAuthenticated) && (
                    <>
                      <Stack.Screen
                        name="Home"
                        component={HomeScreen as any}
                        getId={({ params }: any) => params?.id}
                        options={{
                          title: 'Bandplay',
                          header: ({ options }) => <Header title={options.title} showScreenName />,
                          headerShown: !Platform.isTV,
                        }}
                      />
                      <Stack.Screen
                        name="Videos"
                        component={HomeScreen as any}
                        initialParams={{ id: 'assista' }}
                        getId={({ params }) => params?.id}
                        options={{
                          title: 'Bandplay',
                          header: ({ options }) => <Header title={options.title} showScreenName />,
                          headerShown: !Platform.isTV,
                        }}
                      />
                      <Stack.Screen
                        name="Listen"
                        initialParams={{ id: 'ouca' }}
                        component={HomeScreen as any}
                        getId={({ params }) => params?.id}
                        options={{
                          title: 'Bandplay',
                          header: ({ options }) => <Header title={options.title} showScreenName />,
                          headerShown: !Platform.isTV,
                        }}
                      />
                      <Stack.Screen
                        name="Generic"
                        component={HomeScreen as any}
                        getId={({ params }) => params?.id}
                        options={{
                          header: ({ options }) => <Header title={options.title} showScreenName />,
                          headerShown: !Platform.isTV,
                        }}
                      />
                      <Stack.Screen
                        name="Program"
                        component={ProgramScreen}
                        getId={({ params }) => params.programId}
                        options={{
                          headerShown: false,
                        }}
                      />
                      <Stack.Screen
                        name="Category"
                        getId={({ params }) => params?.slug}
                        component={CategoryScreen}
                        options={{
                          header: ({ options }) => <Header title={options.title} showScreenName />,
                          headerShown: !Platform.isTV,
                        }}
                      />
                      <Stack.Screen
                        name="Search"
                        component={Search}
                        options={{
                          title: 'Buscar - Bandplay',
                          header: ({ options }) => <Header title={options.title} showScreenName />,
                          headerShown: !Platform.isTV,
                        }}
                      />
                      <Stack.Screen
                        name="WebView"
                        component={WebViewScreen}
                        options={{
                          title: 'Band +',
                          header: ({ options }) => <Header title={options.title} showScreenName />,
                          headerShown: !Platform.isTV,
                        }}
                      />
                      <Stack.Screen
                        name="Favorites"
                        component={FavoritesScreen}
                        options={{
                          title: 'Minha lista',
                          header: ({ options }) => <Header title={options.title} showScreenName />,
                          headerShown: !Platform.isTV,
                        }}
                      />
                      <Stack.Group
                        screenOptions={{
                          header: () => <Header showScreenName />,
                          headerShown: !Platform.isTV,
                        }}>
                        <Stack.Screen
                          name="FaqSection"
                          component={Platform.isTV ? TvFAQSectionScreen : FAQSectionScreen}
                        />
                        <Stack.Screen
                          name="LegalTerms"
                          component={Platform.isTV ? TvLegalTermsScreen : LegalTermsScreen}
                        />
                        <Stack.Screen
                          name="ContactUs"
                          component={Platform.isTV ? TvContactUsScreen : ContactUsScreen}
                        />
                      </Stack.Group>
                      <Stack.Screen name="MyProfile" component={TvMyProfile} />
                    </>
                  )}
                  {!Platform.isTV && Platform.OS !== 'web' && (
                    <>
                      {!userDeviceData.hasCompletedOnboarding && (
                        <Stack.Screen name="Onboarding" component={Onboarding} />
                      )}
                    </>
                  )}
                  <Stack.Screen name="Login" component={LoginScreen} />
                  <Stack.Screen name="NotFound" component={NotFound} />
                  {__DEV__ && <Stack.Screen name="Analytics" component={AnalyticsScreen} />}
                  {Platform.isTV && <Stack.Screen name="EasterEgg" component={TvEasterEggScreen} />}
                  {Platform.isTV && <Stack.Screen name="TvSubMenuArea" component={TvSubMenuArea} />}
                </Stack.Navigator>
              </WebDiv>
            </View>
            {hasBottomMenu && !hasTransmissionError && <BottomMenu navigationRef={navigationRef} />}
            <PersistentControl navigationRef={navigationRef} />
          </NavigationContainer>
        </LiveMenuProvider>
      </SafeAreaProvider>
    </SpotlightTourProvider>
  );
};
