import { Box, HStack, Image, Link } from '@gluestack-ui/themed';
import { useLinkTo, useRoute } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import { LinearGradient } from 'expo-linear-gradient';
import { useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { RootState } from 'store/index';
import { Platform } from 'utils/platform';

import MenuNavigation from './Navigation.web';
import SuperiorNavbar from './SuperiorNavbar';
import useSuperiorNavbar from './helpers/useSuperiorNavbar';

import BandHeaderLogo from 'assets/LogoHeader.png';

import HeaderMenuRightArea from 'components/Header/HeaderRightArea';
import { usePlayer } from 'components/Player/context';

const Header: React.FC<Omit<HeaderProps, 'showScreenName'>> = ({ sticky = false }) => {
  const { isFullScreen: isVideoInFullScreen } = usePlayer();
  const route = useRoute();
  const linkTo = useLinkTo();
  const profile = useSelector((state: RootState) => state.profile);
  const { colors, isScrollingDown } = useGlobalConfig();
  const { width: screenWidth } = useWindowDimensions();
  const blurStyle = { backdropFilter: 'blur(8px)' } as any;
  const hasSuperiorNavbar = useSuperiorNavbar(route);
  const isSuperiorNavbarVisible =
    hasSuperiorNavbar && !profile.isKid && !Platform.isTV && screenWidth < 480;

  if (isVideoInFullScreen && Platform.OS !== 'web') {
    return null;
  }

  return (
    <Box>
      <Box
        id={sticky ? 'header-sticky' : 'header'}
        top={0}
        sx={{
          '@lg': {
            height: 80,
            paddingHorizontal: 45,
          },
          '@base': {
            height: 45,
            paddingHorizontal: 20,
          },
        }}
        w="$full"
        zIndex={100}
        justifyContent="center"
        backgroundColor={
          isScrollingDown
            ? 'rgba(7, 13, 49, 0.8)'
            : isSuperiorNavbarVisible
            ? '#070d31'
            : 'transparent'
        }
        {...(isScrollingDown && { style: blurStyle })}>
        {!isScrollingDown && (
          <LinearGradient
            start={{ x: 0, y: 1 }}
            end={{ x: 0, y: 0 }}
            tvParallaxShiftDistanceX={10}
            colors={['transparent', colors || '#070d31']}
            style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
          />
        )}
        <Box w="$full" flexDirection="row" justifyContent="space-between" alignItems="center">
          <HStack
            sx={{
              '@lg': {
                alignItems: 'center',
                gap: 55,
                flexDirection: 'row',
              },
              '@md': {
                alignItems: 'center',
                gap: 35,
                flexDirection: 'row',
              },
              '@sm': {
                alignItems: 'center',
                gap: 25,
                flexDirection: 'row',
              },
              '@base': {
                alignItems: 'flex-start',
                gap: 8,
                flexDirection: 'column',
              },
            }}>
            <Link onPress={() => linkTo('/')}>
              <Image
                source={BandHeaderLogo}
                sx={{
                  '@lg': {
                    width: 216,
                    height: 47,
                  },
                  '@base': {
                    width: 100,
                    height: 22,
                  },
                }}
              />
            </Link>
            {!profile.isKid && <MenuNavigation />}
          </HStack>

          <HeaderMenuRightArea />
        </Box>
      </Box>
      {isSuperiorNavbarVisible && <SuperiorNavbar />}
    </Box>
  );
};

export default Header;
