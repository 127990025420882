import {
  Button,
  CloseIcon,
  Icon,
  Image,
  Modal,
  ModalBody,
  Pressable,
  VStack,
} from '@gluestack-ui/themed';
import { NavigationContainerRefWithCurrent, Route } from '@react-navigation/native';
import { useBottomSheet } from 'context/BottomSheetContext';
import { useGlobalConfig } from 'context/GlobalContext';
import { useModalConfig } from 'context/ModalContext';
import * as WebBrowser from 'expo-web-browser';
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { MAX_MENU_ITEMS } from 'utils/constants';
import { Platform } from 'utils/platform';

const BandMenuModal: React.FC<{ navigationRef?: NavigationContainerRefWithCurrent<any> }> = ({
  navigationRef,
}) => {
  const { isModalVisible, closeModal } = useModalConfig();
  const { BandMenu } = useGlobalConfig();
  const { height, width } = useWindowDimensions();
  const [menuItems, setMenuItems] = useState<BandMenuProducts[]>();
  const { handleToggleSheet, setCurrentUrl, setCurrentMenu } = useBottomSheet();
  const currentRoute: Route<string> | undefined = navigationRef?.current?.getCurrentRoute();

  useEffect(() => {
    const menuLength = BandMenu?.product.length ?? 0;
    const customMenu = [];

    if (menuLength > MAX_MENU_ITEMS) {
      customMenu.push(
        ...(BandMenu?.product.slice(MAX_MENU_ITEMS - 1, menuLength).map((e) => {
          return {
            productImage: e.productImage,
            productUrl: e.productUrl,
            name: e.name,
            icon: e.icon,
            selectedIcon: e.selectedIcon,
            isNewTab: !!e?.isNewTab,
          };
        }) ?? [])
      );

      setMenuItems(customMenu);
    }
  }, [BandMenu]);

  const handleOpenUrl = async (item: any, index: number) => {
    const url = item.productUrl;

    if (Platform.OS === 'web' || item.isNewTab) {
      await WebBrowser.openBrowserAsync(url);
    } else {
      setCurrentMenu({
        value: index,
        name: item.name,
      });
      handleToggleSheet(currentRoute?.name !== 'Program');
      setCurrentUrl(item.productUrl);
    }

    closeModal();
  };

  if (!menuItems) return <></>;

  return (
    <Modal
      isOpen={isModalVisible}
      height={menuItems.length * 100 + 80}
      marginTop="auto"
      paddingHorizontal={20}
      paddingVertical={10}
      marginBottom={Platform.OS === 'ios' ? 90 : 70}>
      <Pressable
        width={width}
        height={height}
        position="absolute"
        left={0}
        right={0}
        bottom={0}
        backgroundColor="black"
        opacity={0.3}
        onPress={closeModal}
      />
      <ModalBody
        height="$full"
        w="$full"
        borderColor="#2240FF"
        backgroundColor="#070D31"
        borderRadius={12}
        borderWidth={3}>
        <VStack space="4xl" height={menuItems.length * 100}>
          <Pressable mt="$4" ml={'auto'} onPress={closeModal}>
            <Icon as={CloseIcon} color="$white" width={24} height={24} />
          </Pressable>
          {menuItems.map((data, index) => (
            <Button
              key={index}
              backgroundColor="transparent"
              onPress={() => {
                handleOpenUrl(data, index);
              }}>
              <Image
                w={data.productImage.width}
                h={data.productImage.height}
                alt={data.productImage.title}
                source={{ uri: data.productImage.url }}
              />
            </Button>
          ))}
        </VStack>
      </ModalBody>
    </Modal>
  );
};

export default BandMenuModal;
