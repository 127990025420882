import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Constants from 'expo-constants';
import type { RootState } from 'store/index';
import { Platform } from 'utils/platform';
import { handleBaseQuery } from 'utils/request';

export const backendApiSlice = createApi({
  reducerPath: 'backendApi',
  tagTypes: ['Favorite', 'Watching'],
  baseQuery: handleBaseQuery(
    fetchBaseQuery({
      baseUrl: Constants.expoConfig?.extra?.backendApi,
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.userToken;
        if (token) {
          headers.set('Cookie', `access_token=${token}`);
          headers.set('Authorization', `Bearer ${token}`);
          return headers;
        }
      },
    })
  ),
  endpoints: (builder) => ({
    logIn: builder.mutation<BackendApiLogin, BackendApiLoginParams>({
      query: (payload) => ({
        url: '/api/login',
        method: 'POST',
        body: payload,
      }),
    }),
    // @TODO endpoint real e mapeamento ts
    getProfiles: builder.query({
      query: () => ({
        url: '/api/Bandplay/Profiles',
      }),
    }),
    getFavorites: builder.query<BackendGenericList<BackendFavorite>, void>({
      query: () => ({
        url: '/api/User/BandplayFavorite',
      }),
      providesTags: (result, error, arg) =>
        result
          ? [...result.items.map(({ _id }) => ({ type: 'Favorite' as const, _id })), 'Favorite']
          : ['Favorite'],
    }),
    createFavorite: builder.mutation<BackendFavorite, Omit<BackendFavorite, '_id' | 'createdAt'>>({
      query: (payload) => ({
        url: '/api/User/BandplayFavorite',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Favorite'],
    }),
    deleteFavorite: builder.mutation<void, string>({
      query: (payload) => ({
        url: `/api/User/BandplayFavorite/${payload}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Favorite'],
    }),
    getWatchingList: builder.query<BackendGenericList<BackendWatching>, Partial<BackendWatching>>({
      query: (params) => ({
        url: '/api/User/BandplayWatching',
        params: { inProgress: true, ...params, limit: Platform.isTV ? 8 : 15 },
      }),
      providesTags: ['Watching'],
      keepUnusedDataFor: 0,
    }),
    createFeedback: builder.mutation<void, FeedbackProps>({
      query: (payload) => ({
        url: '/api/User/Feedback',
        method: 'POST',
        body: {
          ...payload,
          app: 'Bandplay',
        },
      }),
    }),
  }),
});

export const {
  useLogInMutation,
  useGetProfilesQuery,
  useGetFavoritesQuery,
  useGetWatchingListQuery,
  useLazyGetWatchingListQuery,
  useCreateFavoriteMutation,
  useDeleteFavoriteMutation,
  useCreateFeedbackMutation,
} = backendApiSlice;
