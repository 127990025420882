import { useState } from 'react';
import { Pressable } from 'react-native';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { Platform } from 'utils/platform';

import FocusView from 'components/FocusView';
import { Icons } from 'components/Icons';
import { usePlayer } from 'components/Player/context';
import { useProgram } from 'components/Program/context';

type VideoPlayButtonProps = {
  black?: boolean;
};

const VideoPlayButton: React.FC<VideoPlayButtonProps> = ({ black }) => {
  const { play } = usePlayer();
  const program = useProgram();
  const analytics = useAnalytics();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleOnPress = () => {
    analytics.triggerMediaPlayActionEvent({
      mediaType: 'video',
      programId: program._id,
      programTitle: program.title,
      programAgeRating: program.rating,
    });

    play();
  };

  return (
    <FocusView
      style={{
        borderWidth: 2,
        borderColor: !isFocused ? 'transparent' : 'red',
        borderRadius: 50,
      }}>
      <Pressable
        hasTVPreferredFocus
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onFocus={() => (Platform.isAndroidTV ? setIsFocused(true) : {})}
        onBlur={() => (Platform.isAndroidTV ? setIsFocused(false) : {})}
        onPress={handleOnPress}>
        {!black ? (
          <Icons.SolidPlayCircular height="36" width="36" />
        ) : (
          <Icons.SolidPlay color="black" />
        )}
      </Pressable>
    </FocusView>
  );
};

export default VideoPlayButton;
