import React, { useMemo } from 'react';
import { useLazyGetProgramsQuery } from 'services/cms';
import { Platform } from 'utils/platform';

import Section from './index';

const getImage = (item: any, variant: string) => {
  return variant === 'section.medium' ? item.imageVertical : item.imageHorizontal;
};

type SearchProgramExternalProps = CmsHomeSection & {
  onLoaded?: () => void;
  waitToRender?: boolean;
  requestTVFocus?: boolean;
  sortRelatedPrograms?: string;
};

type SearchProgramInternalProps = CmsHomeSection &
  SearchProgramExternalProps & {
    skipSearch: boolean;
    useLazyGetProgramsQueryData: {
      data?: CmsApiProgramList;
      isLoading: boolean;
      fetchData: () => any;
    };
  };

const SearchProgram: React.FC<SearchProgramInternalProps> = React.memo(
  (props) => {
    const { data, isLoading } = props.useLazyGetProgramsQueryData;

    const items = React.useMemo(() => {
      const getAllValidItems = () => {
        const baseItems = [...(props.editorialCards || [])];
        if (!data || !data.items) return baseItems;

        const validItems = data.items
          .filter((item) => item._id !== props.ignoreId)
          .map<CmsCard>((item) => {
            return {
              title: props.showTitle ? item.title : '',
              image: getImage(item, props.variant),
              link: `/${item.url}`,
              eventStartDate: item.eventStartDateTag,
            };
          });

        return [...baseItems, ...validItems];
      };

      return getAllValidItems().map((item) => ({
        ...item,
        reference: props.title,
      }));
    }, [data]);

    React.useEffect(() => {
      if (!props.skipSearch && isLoading) return;
      setTimeout(() => props?.onLoaded?.(), 0);
    }, [isLoading, data]);

    if (Platform.isAndroidTV && (isLoading || props.waitToRender)) {
      return null;
    }

    return <Section {...props} horizontal loading={isLoading} items={items} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.waitToRender === nextProps.waitToRender &&
      prevProps.useLazyGetProgramsQueryData.data === nextProps.useLazyGetProgramsQueryData.data &&
      prevProps.useLazyGetProgramsQueryData.isLoading ===
        nextProps.useLazyGetProgramsQueryData.isLoading
    );
  }
);

/**
 * NOTA: Sem este shield, o componente terá inumeras re-renderizações por conta do useGetHomeQuery,
 * que atualiza varias propriedades que não utilizamos. Dessa forma, somente quando os
 * campos que nos interessam mudar é que um re-render será causado
 */
const SearchProgramPerformaticShield: React.FC<SearchProgramExternalProps> = (props) => {
  const skipSearch = React.useMemo(() => {
    return (
      !props.searchPrograms?.category &&
      !(props.searchPrograms ?? {}).hasOwnProperty('genre') &&
      !(props.searchPrograms ?? {}).hasOwnProperty('genre.id')
    );
  }, [props.searchPrograms]);

  const [_fetchData, { data, isLoading, isUninitialized }] = useLazyGetProgramsQuery();

  const fetchData = useMemo(() => {
    const args = {
      ...props.searchPrograms,
    };

    if (props?.sortRelatedPrograms) {
      args.sort = props?.sortRelatedPrograms;
    }

    return async () => _fetchData(args);
  }, [props]);

  const composedIsLoading = useMemo(() => {
    return !skipSearch && (isLoading || isUninitialized);
  }, [isLoading, isUninitialized, skipSearch]);

  React.useEffect(() => {
    if (skipSearch) return;
    fetchData();
  }, []);

  return (
    <SearchProgram
      {...props}
      skipSearch={skipSearch}
      useLazyGetProgramsQueryData={{ data, isLoading: composedIsLoading, fetchData }}
    />
  );
};

export default SearchProgramPerformaticShield;
