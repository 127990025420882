import React, { useState } from 'react';
import { Pressable } from 'react-native';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { Platform } from 'utils/platform';

import FocusView from 'components/FocusView';
import { Icons } from 'components/Icons';
import { usePlayer } from 'components/Player/context';
import { useProgram } from 'components/Program/context';

type VideoPauseButtonProps = {
  black?: boolean;
};

const VideoPauseButton: React.FC<VideoPauseButtonProps> = ({ black }) => {
  const program = useProgram();
  const { pause } = usePlayer();
  const analytics = useAnalytics();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleOnPress = () => {
    analytics.triggerMediaPauseActionEvent({
      mediaType: 'video',
      programId: program._id,
      programTitle: program.title,
      programAgeRating: program.rating,
    });

    pause();
  };

  return (
    <FocusView
      style={{
        borderWidth: 2,
        borderColor: !isFocused ? 'transparent' : 'red',
        borderRadius: 50,
      }}>
      <Pressable
        hasTVPreferredFocus
        style={[
          {
            alignItems: 'center',
            justifyContent: 'center',
          },
        ]}
        onFocus={() => (Platform.isAndroidTV ? setIsFocused(true) : {})}
        onBlur={() => (Platform.isAndroidTV ? setIsFocused(false) : {})}
        onPress={handleOnPress}>
        {!black ? (
          <Icons.SolidPauseCircular height="36" width="36" />
        ) : (
          <Icons.SolidPause color="black" />
        )}
      </Pressable>
    </FocusView>
  );
};

export default VideoPauseButton;
