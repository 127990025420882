import { Box } from '@gluestack-ui/themed';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetFavoritesQuery } from 'services/backend';
import { useGetProgramGroupQuery } from 'services/cms';
import { RootState } from 'store/index';
import { AGE_RATINGS } from 'utils/ageRating';
import { MY_LIST_ITEM, PROGRAM_GROUP_UNPUBLISHED_LIST_ID } from 'utils/constants';
import { Platform } from 'utils/platform';

import Section from '.';
import EmptyFavorites from './components/EmptyFavorites';

import IsLoading from 'components/isLoading';

type SectionFavoriteProps = Omit<Partial<CmsHomeSectionMyList>, '_name'> & {
  topContainerHeight?: number | undefined;
  horizontal?: boolean;
  onLoaded?: () => void;
};

export const SectionFavorite: React.FC<SectionFavoriteProps> = (props) => {
  const { horizontal = false, limit, title } = props;
  const profile = useSelector((state: RootState) => state.profile);

  const getFavoritesQuery = useGetFavoritesQuery();
  const getProgramGroupQuery = useGetProgramGroupQuery(PROGRAM_GROUP_UNPUBLISHED_LIST_ID);

  const isLoading = React.useMemo(() => {
    return getFavoritesQuery.isLoading || getProgramGroupQuery.isLoading;
  }, [getFavoritesQuery.isLoading, getProgramGroupQuery.isLoading]);

  const layoutProps = {
    vertical: !horizontal,
    horizontal,
    ...(horizontal && {
      title: props.title || MY_LIST_ITEM.title,
      limit: props.limit || MY_LIST_ITEM.limit,
      more: MY_LIST_ITEM.more,
    }),
  };

  React.useEffect(() => {
    setTimeout(() => props?.onLoaded?.(), 0);
  }, []);

  const blockedList = React.useMemo(
    () => getProgramGroupQuery?.data?.items?.[0]?.group || [],
    [getProgramGroupQuery.data]
  );

  const items = React.useMemo(() => {
    if (!getFavoritesQuery.data) return [];

    const formattedItems = getFavoritesQuery.data.items
      .filter((item) => !blockedList.includes(item.programId))
      .map((item) => ({
        title: item.title,
        link: `/${item.url}`,
        image: { url: item.image },
        isKid: item.kids,
        reference: 'Minha Lista',
        rating: item?.rating,
      }));

    const userRatingIndex = AGE_RATINGS.findIndex((rating) => rating === profile.ageRating);

    if (profile.isKid) return formattedItems.filter((item) => item.isKid);

    const filteredItems = formattedItems.filter((item) => {
      if (item?.rating) {
        const findRatingIndex = AGE_RATINGS.findIndex((rating) => rating === item.rating);
        return findRatingIndex <= userRatingIndex && !item.isKid;
      }
      return !item.isKid;
    });

    return horizontal ? filteredItems?.slice(0, limit || 15) : filteredItems;
  }, [getFavoritesQuery.data, blockedList]);

  if (isLoading) {
    return (
      <Box flex={1} alignItems="center" justifyContent="center">
        <IsLoading />
      </Box>
    );
  }

  if (Platform.isTV && items?.length === 0) {
    if (!horizontal) return <EmptyFavorites />;

    return null;
  }

  return (
    <Section
      requestTVFocus
      items={items}
      _name="section"
      title={title || 'Favoritos'}
      showTitle={horizontal}
      variant="section.medium"
      {...props}
      {...layoutProps}
    />
  );
};
