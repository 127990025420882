import { Box, ScrollView, Text, View } from '@gluestack-ui/themed';
import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { ProgramType } from 'utils/enum';
import { calcSizeForDevice, calcSizeForTv } from 'utils/index';

import { SEARCH_MENU_OPTION } from './index';

import Button from 'components/Button';
import FocusView from 'components/FocusView';
import { Icons } from 'components/Icons';
import SearchVerticalList from 'components/Section/SearchVerticalList';
import TvKeyboard from 'components/TvKeyboard';
import { WebDiv } from 'components/WebDiv';

const SearchTV: React.FC = () => {
  const [textValue, setTextValue] = useState('');
  const [categoryValue, setCategoryValue] = useState<ProgramType>();

  const { width: windowWidth } = useWindowDimensions();
  const containerLeftWidth = windowWidth - calcSizeForTv(600); // 600 => left area (keyboard, menus)

  const iconSize = {
    width: calcSizeForDevice(24).toString(),
    height: calcSizeForDevice(24).toString(),
  };

  return (
    <FocusView trapFocusUp trapFocusRight style={{ flex: 1 }}>
      <Box
        flex={1}
        h="$full"
        width="$full"
        display="flex"
        maxWidth="100%"
        maxHeight="100%"
        flexDirection="row"
        alignItems="flex-start"
        paddingLeft={calcSizeForTv(60)}
        paddingRight={calcSizeForTv(60)}>
        <FocusView trapFocusUp trapFocusDown>
          <WebDiv
            className="navigation-container"
            style={{
              height: '100%',
              overflow: 'hidden',
              width: calcSizeForTv(360),
            }}>
            <View
              flexDirection="row"
              alignItems="center"
              mb={calcSizeForTv(20)}
              paddingTop={calcSizeForTv(60)}>
              <Icons.MagnifyinGlass {...iconSize} />
              <Text
                fontSize={calcSizeForTv(30)}
                color="white"
                lineHeight={calcSizeForTv(40)}
                ml={calcSizeForTv(20)}>
                {textValue || 'Principais Pesquisas'}
              </Text>
            </View>
            <TvKeyboard variant="simple" textValue={textValue} setTextValue={setTextValue} />
            <View />

            <ScrollView showsVerticalScrollIndicator={false} mt={calcSizeForTv(20)}>
              {SEARCH_MENU_OPTION.map((item, index) => (
                <Button
                  key={`${item?.title}-${index}`}
                  useTVFocus
                  variant="solid"
                  onPress={() => setCategoryValue(item?.value)}
                  backgroundColor="transparent"
                  size="sm"
                  style={{
                    justifyContent: 'flex-start',
                    marginBottom: calcSizeForTv(10),
                    borderColor: categoryValue === item?.value ? '#7A8CFF' : 'transparent',
                    borderWidth: 1,
                    paddingHorizontal: calcSizeForTv(20),
                  }}
                  textStyle={{
                    fontSize: calcSizeForTv(20),
                    fontWeight: categoryValue === item?.value ? 700 : 400,
                  }}
                  isFullWidth>
                  {item?.title}
                </Button>
              ))}
            </ScrollView>
          </WebDiv>
        </FocusView>
        <Box
          flex={1}
          h="$full"
          maxHeight="$full"
          overflow="hidden"
          ml={calcSizeForTv(64)}
          w={containerLeftWidth}>
          <WebDiv className="navigation-container">
            <Box paddingTop={calcSizeForTv(60)}>
              <SearchVerticalList
                searchInputValue={textValue}
                categoryValue={categoryValue}
                containerWidth={containerLeftWidth}
              />
            </Box>
          </WebDiv>
        </Box>
      </Box>
    </FocusView>
  );
};

export default SearchTV;
