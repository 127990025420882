import { Box, Button, ButtonText } from '@gluestack-ui/themed';
import {
  NavigationProp,
  ParamListBase,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useMemo } from 'react';

import useSuperiorNavbar, { NAVBAR_ITEMS } from './helpers/useSuperiorNavbar';

type RouteParams = { screen?: string; path?: string; [key: string]: string | undefined };

const SuperiorNavbar = () => {
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const route = useRoute<RouteProp<ParamListBase, string>>();
  const routeParams = useMemo(() => route?.params as RouteParams, [route?.params]);
  const hasSuperiorNavbar = useSuperiorNavbar(route);

  const isHomePage = routeParams?.screen === 'Home' && routeParams?.path === '/';

  if (!hasSuperiorNavbar) return null;

  return (
    <Box
      flexDirection="row"
      borderWidth={1}
      borderColor="transparent"
      justifyContent="space-between"
      bg="#070D31"
      width="$full">
      {NAVBAR_ITEMS.map((item) => {
        const isScreenMatched = item.screen === route.name;
        const areParamsMatched =
          JSON.stringify(item?.params || {}) === JSON.stringify(route?.params || {});

        const isSelected =
          (isScreenMatched && areParamsMatched) || (item.name === 'Home' && isHomePage);

        if (!item?.label) return;

        return (
          <Button
            borderRadius={0}
            borderBottomWidth={3}
            borderColor={isSelected ? '#7A8CFF' : 'transparent'}
            bg="#070D31"
            onPress={() => navigation.navigate(item.screen, item.params)}>
            <ButtonText color={isSelected ? '#7A8CFF' : '#FFFFFF'}>{item?.label}</ButtonText>
          </Button>
        );
      })}
    </Box>
  );
};

export default SuperiorNavbar;
