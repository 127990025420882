import { Text, View } from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { useGetPodcastQuery } from 'services/apiConteudo';
import { calcSizeForTv } from 'utils/index';

import Button from 'components/Button';
import Card from 'components/Card';
import FocusView from 'components/FocusView';
import Grid from 'components/Grid';
import Hero from 'components/Hero';
import ProgramContent from 'components/Program/Content';
import { useProgram } from 'components/Program/context';
import Radio from 'components/Radio/Radio';
import TvSeasonsMenu from 'components/TvSeasonsMenu/TvSeasonsMenu';
import { WebDiv } from 'components/WebDiv';

const TvPodcastScreen: React.FC = () => {
  const program = useProgram();
  const analytics = useAnalytics();
  const [selected, setSelected] = useState<number>(0);

  const [page, setPage] = useState(1);
  const [items, setItems] = useState<any[]>([]);

  const { data: podcast } = useGetPodcastQuery({ id: program.podcastId, page });

  const handlePodcast = (actual: number) => {
    setSelected(actual);
  };

  const handleOnEndReached = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    setItems((old) => [...old, ...(podcast?.data.spreaker_episodes || [])]);
  }, [podcast]);

  const renderSeasonItem = ({ item }: any) => (
    <WebDiv
      className={`navigation-container ${program.seasons.length < 2 ? 'navigation-ignore' : ''}`}>
      <Button
        useTVFocus
        hasTVPreferredFocus
        variant="solid"
        backgroundColor="transparent"
        style={{
          marginRight: calcSizeForTv(20),
          borderBottomWidth: calcSizeForTv(3),
          borderColor: 'white',
        }}
        textTransform="uppercase"
        textStyle={{
          fontSize: calcSizeForTv(20),
          lineHeight: calcSizeForTv(28),
          color: 'white',
          textShadowColor: 'rgba(7, 13, 49, 0.7)',
          textShadowOffset: { width: 1, height: 1 },
        }}>
        {item.name}
      </Button>
    </WebDiv>
  );

  const renderItem = (podcastItem: ApiConteudoSpreakerEpisode) => {
    const podcastIndex = items.findIndex((item) => item.title === podcastItem.title);

    return (
      <Card
        type="midBoxed"
        title={podcastItem.title}
        uri={podcastItem.image}
        lines={{ title: 4, description: 2 }}
        onPress={() => setSelected(podcastIndex)}
        isSelected={podcastIndex === selected}
      />
    );
  };

  useFocusEffect(
    useCallback(() => {
      analytics.triggerPodcastScreenPageViewEvent({
        programId: program._id,
        programTitle: program.title,
        programAgeRating: program.rating,
        programAdUnit: program.ads?.adunit?.val!,
        programCategory: program.category.map((tag) => tag.name).join(', '),
        programGenre: program.genre.map((genre) => genre.name).join(', '),
      });
    }, [])
  );

  if (!podcast) return null;

  return (
    <WebDiv className="navigation-container">
      <Hero program={program}>
        <View mt={calcSizeForTv(80)} justifyContent="center">
          <Text
            color="$white"
            fontWeight="$bold"
            fontSize={calcSizeForTv(64)}
            lineHeight={calcSizeForTv(72)}>
            {program?.title}
          </Text>
          <View my={calcSizeForTv(80)}>
            <Radio
              program={program}
              podcast={items}
              selectedIndex={selected}
              handleSelectedIndex={handlePodcast}
            />
          </View>
          <ProgramContent podcast={podcast} selectedIndex={selected} />
          {Boolean(program.seasons) && Boolean(program.seasons.length) && (
            <FocusView>
              <TvSeasonsMenu items={program.seasons} renderItem={renderSeasonItem} />
            </FocusView>
          )}
          <Grid
            onEndReached={handleOnEndReached}
            gap={calcSizeForTv(16)}
            key={program.podcastId}
            numColumns={3}
            data={items}
            renderItem={renderItem}
          />
        </View>
      </Hero>
    </WebDiv>
  );
};

export default TvPodcastScreen;
