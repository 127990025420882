import { Box, Image, Text, View } from '@gluestack-ui/themed';
import { useLinkTo } from '@react-navigation/native';
import Constants from 'expo-constants';
import { ImageBackground } from 'expo-image';
import { Dimensions, useWindowDimensions } from 'react-native';
import { calcSizeForDevice } from 'utils/index';
import backgroundImageDesktop from 'assets/error-background-desktop.png';
import backgroundImageMobile from 'assets/error-background-mobile.png';
import NoCopyrightImage from 'assets/no-copyright.png';

import Button from 'components/Button';

export const NoCopyright: React.FC = () => {
  const linkTo = useLinkTo();
  const { width } = useWindowDimensions();
  const isMobile = width < 950;
  const backgroundImage = isMobile ? backgroundImageMobile : backgroundImageDesktop;

  const title = (
    <Text
      fontSize={calcSizeForDevice(32, 41)}
      lineHeight={calcSizeForDevice(36, 41)}
      fontWeight="$bold"
      color="white"
      size="lg"
      mb={calcSizeForDevice(24, 60)}>
      Ixe! Tarde demais...
    </Text>
  );

  const description = (
    <Text
      fontSize={calcSizeForDevice(18, 27)}
      lineHeight={calcSizeForDevice(22, 27)}
      textAlign={isMobile ? 'center' : 'left'}
      color="white"
      size="lg"
      mb={calcSizeForDevice(24, 60)}>
      Este conteúdo foi removido devido a questãode direitos ou parcerias. Que tal explorar outras
      opções do catálogo?
    </Text>
  );

  const image = (
    <Image
      source={NoCopyrightImage}
      width={400}
      height={400}
      maxHeight={400}
      mb={calcSizeForDevice(24, 60)}
      resizeMode="contain"
    />
  );

  const action = (
    <Button
      useTVFocus
      hasTVPreferredFocus
      variant="gradient"
      size="md"
      onPress={() => linkTo('/')}
      style={{
        height: calcSizeForDevice(40, 60),
        paddingHorizontal: calcSizeForDevice(20, 30),
      }}
      textStyle={{ fontSize: calcSizeForDevice(16, 24) }}>
      Voltar para a página inicial
    </Button>
  );

  if (!isMobile) {
    return (
      <ImageBackground
        source={backgroundImage}
        style={{
          padding: isMobile ? 24 : 44,
          paddingTop: isMobile ? Constants?.statusBarHeight : 0,
          flex: 1,
          justifyContent: isMobile ? 'flex-start' : 'center',
          maxHeight: Dimensions.get('screen').height,
          overflow: 'hidden',
        }}>
        <Box
          flexDirection="row"
          padding={calcSizeForDevice(16)}
          height="$full"
          alignItems="center"
          justifyContent="center">
          <View
            maxWidth={calcSizeForDevice(450)}
            padding={calcSizeForDevice(16)}
            paddingHorizontal={calcSizeForDevice(16)}
            mr={calcSizeForDevice(24, 32)}>
            {title}
            {description}
            {action}
          </View>

          {image}
        </Box>
      </ImageBackground>
    );
  }

  return (
    <ImageBackground
      source={backgroundImage}
      style={{
        padding: isMobile ? 24 : 44,
        paddingTop: isMobile ? Constants?.statusBarHeight : 0,
        flex: 1,
        justifyContent: isMobile ? 'flex-start' : 'center',
        maxHeight: Dimensions.get('screen').height,
        overflow: 'hidden',
      }}>
      <Box
        padding={calcSizeForDevice(16)}
        height="$full"
        alignItems="center"
        justifyContent="center">
        <View
          padding={calcSizeForDevice(16)}
          paddingHorizontal={calcSizeForDevice(16)}
          alignItems="center">
          {title}
          {image}
          {description}
          {action}
        </View>
      </Box>
    </ImageBackground>
  );
};
