import { Box, FlatList, Text } from '@gluestack-ui/themed';
import React, { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import Card from 'components/Card';
import FocusView from 'components/FocusView';
import { WebDiv } from 'components/WebDiv';
import IsLoading from 'components/isLoading';

type SectionVerticalProps = {
  isLoading?: boolean;
  data?: CmsCard[];
  type: string;
  onEndReached?: () => void;
  containerWidth?: number | undefined;
  topContainerHeight?: number | undefined;
  requestTVFocus?: boolean;
};

const SectionVertical: React.FC<SectionVerticalProps> = ({
  isLoading,
  data,
  type,
  onEndReached,
  containerWidth,
  topContainerHeight,
  requestTVFocus,
}) => {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const width = containerWidth ?? windowWidth;

  const numColsDividend = useMemo(
    () => (width < 480 ? 105 : calcSizeForDevice(180, Platform.isAndroidTV ? 320 : 260)),
    [width]
  );
  const numCols = useMemo(() => Math.floor(width / numColsDividend), [width, numColsDividend]);
  const cardWidth = width / numCols - calcSizeForDevice(40, 60);

  if (isLoading) {
    return (
      <Box flex={1} alignItems="center" justifyContent="center">
        <IsLoading />
      </Box>
    );
  }

  if (!data || !data.length) {
    return (
      <Text color="#fff" marginTop={5} marginLeft={15} fontSize="$lg" fontWeight="$semibold">
        Nenhum item encontrado
      </Text>
    );
  }

  return (
    <FocusView>
      <WebDiv className="navigation-container navigation-grid instant-scroll">
        <FlatList
          data={data}
          key={numCols}
          focusable={false}
          numColumns={numCols}
          onEndReached={onEndReached}
          onEndReachedThreshold={0.5}
          showsVerticalScrollIndicator={false}
          removeClippedSubviews={Platform.isTV}
          showsHorizontalScrollIndicator={false}
          initialNumToRender={Platform.isTV ? Infinity : 10}
          keyExtractor={(i: any, index) => `${i.link}-${index}`}
          height={topContainerHeight ? windowHeight - topContainerHeight : windowHeight}
          contentContainerStyle={{
            gap: 10,
            paddingBottom: Platform.isTV ? 0 : 280,
          }}
          renderItem={({ item, index }) => (
            <Item
              index={index}
              item={item as any}
              ctx={{ numCols, type, cardWidth, requestTVFocus }}
            />
          )}
        />
      </WebDiv>
    </FocusView>
  );
};

const Item = React.memo(
  (props: {
    item: CmsCard;
    index: number;
    ctx: { numCols: number; type: string; cardWidth: number; requestTVFocus?: boolean };
  }) => {
    const { item, index } = props;
    const { numCols, type, cardWidth, requestTVFocus } = props.ctx;

    return (
      <Box alignItems="center" flex={1 / numCols} mb={calcSizeForDevice(10, 30)}>
        <Card
          type={type}
          title={item?.title}
          component="Prateleira"
          uri={item.image?.url!}
          link={`/${item?.link}`}
          crops={{ mobile: '300x400' }}
          requestTVFocus={index === 0 && requestTVFocus}
          override={type.includes('medium') ? { cardWidth: { base: cardWidth } } : undefined}
        />
      </Box>
    );
  },
  (prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

export default SectionVertical;
