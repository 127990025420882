import { useRoute } from '@react-navigation/native';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useEffect } from 'react';
import { WebView } from 'react-native-webview';

const WebViewScreen: React.FC<RootStackScreenProps<'WebView'>> = ({ navigation }) => {
  const route = useRoute();
  const data = (route.params as any)?.data;

  useEffect(() => {
    if (data.pageTitle) navigation.setOptions({ title: data.pageTitle });
  }, [navigation, data]);

  return <WebView source={{ uri: data.productUrl }} style={{ flex: 1 }} />;
};

export default WebViewScreen;
