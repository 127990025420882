import { getAnalytics, getGoogleAnalyticsClientId, logEvent, setUserId } from 'firebase/analytics';

import { AnalyticsDriver } from './types';

export const useAnalyticsDriver = (): AnalyticsDriver => {
  const analytics = getAnalytics();

  return {
    setUserId: async (id: string) => setUserId(analytics, id),
    getAppInstanceId: () => getGoogleAnalyticsClientId(analytics),
    logEvent: async (name, params) => logEvent(analytics, name, params),
  };
};
