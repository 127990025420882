import { Icon, Image, Menu, MenuItem, Pressable, Text } from '@gluestack-ui/themed';
import { useGlobalConfig } from 'context/GlobalContext';
import React, { useState } from 'react';
import theme from 'theme';

import { Icons } from 'components/Icons';

const BandMoreMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { BandMoreTab } = useGlobalConfig();

  return (
    <Menu
      onClose={() => setIsOpen(false)}
      onSelectionChange={(keys: any) => {
        const item = BandMoreTab?.product.find((o) => o.productUrl == keys.currentKey);
        window.open(item?.productUrl, '_self');
        setIsOpen(false);
      }}
      gap={10}
      mt={8}
      pr={10}
      pb={10}
      isOpen={isOpen}
      selectionMode="single"
      placement="bottom right"
      backgroundColor="#15161C"
      disabledKeys={['profilePic']}
      trigger={({ ...triggerProps }) => {
        return (
          <Pressable
            {...triggerProps}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            aria-label="Mais opçoes do menu"
            onPress={() => {
              setIsOpen(true);
            }}
            borderWidth={2}
            borderColor="#B5B5B5"
            rounded={'$full'}
            padding={1}
            paddingHorizontal={4}
            width={90}>
            <Icon h={14} w={14} as={Icons.Plus} color="#B4B6C1" />
            <Text color="#B4B6C1" fontSize={'$md'} fontFamily={theme.fonts.inter.regular}>
              Band
            </Text>
          </Pressable>
        );
      }}>
      {BandMoreTab?.product.map((data) => (
        <MenuItem
          marginLeft={10}
          key={data.productUrl}
          flexDirection="row"
          alignItems="center"
          backgroundColor="inherit"
          sx={{ ':focus': { backgroundColor: '#333336' } }}>
          <Image
            w={data.productImage.width}
            h={data.productImage.height}
            alt={data.productImage.title}
            source={{ uri: data.productImage.url }}
          />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default BandMoreMenu;
