import { View, useBreakpointValue } from '@gluestack-ui/themed';
import { memo } from 'react';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import Carousel from 'components/Carousel';
import NewCarousel from 'components/NewCarrousel';
import SearchProgram from 'components/Section/SearchProgram';
import SearchWatchingList from 'components/Section/SearchWatchingList';
import { SectionFavorite } from 'components/Section/SectionFavorite';
import SectionRanking from 'components/Section/SectionRanking';
import SectionSuggestion from 'components/Section/SectionSuggestion';
import SectionVideo from 'components/Section/SectionVideo';

type DynamicListItemProps = {
  onLoaded?: () => void;
  waitToRender?: boolean;
  requestTVFocus?: boolean;
};

const DynamicListItem: React.FC<CmsHomeModule & DynamicListItemProps> = (props) => {
  const paddingX = useBreakpointValue({ xl: calcSizeForDevice(24), lg: '$6', base: '$4' });

  switch (props._name) {
    case 'section':
      return (
        <View px={paddingX}>
          <SearchProgram {...props} />
        </View>
      );

    case 'sectionWatchingList':
      return (
        <View px={paddingX}>
          <SearchWatchingList {...props} _name="section" />
        </View>
      );

    case 'player':
      return <SectionVideo {...props} />;

    case 'hero':
      return Platform.isTV ? <Carousel {...props} /> : <NewCarousel {...props} />;

    case 'sectionMyList':
      return (
        <View px={paddingX}>
          <SectionFavorite {...props} horizontal />
        </View>
      );

    case 'sectionRanking':
      return <SectionRanking {...props} />;

    case 'sectionSuggestion':
      return (
        <View px={paddingX}>
          <SectionSuggestion {...props} />
        </View>
      );

    default:
      setTimeout(() => (props as any)?.onLoaded?.(), 0);
      return null;
  }
};

export default memo(DynamicListItem);
