export enum ProgramType {
  MOVIE = '1',
  DOCUMENTARY = '2',
  LIVE = '3', // ao-vivo
  PROGRAM = '4',
  TV_SHOW = '5', // series
  RADIO = '6',
  PODCAST = '7',
  EXTERNALPROGRAM = '8',
}

export const programTypeToString: Record<ProgramType, string> = {
  [ProgramType.MOVIE]: 'Movie',
  [ProgramType.DOCUMENTARY]: 'Documentary',
  [ProgramType.LIVE]: 'Live',
  [ProgramType.PROGRAM]: 'Program',
  [ProgramType.TV_SHOW]: 'TV Show',
  [ProgramType.RADIO]: 'Radio',
  [ProgramType.PODCAST]: 'Podcast',
  [ProgramType.EXTERNALPROGRAM]: 'External Program',
};
