import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { ErrorHandlerType } from 'screens/ErrorHandler';
import { getMediaStreamUrl } from 'services/media';
import { Platform } from 'utils/platform';

import AudioPlayer from './Audio';
import CTVPlayer from './CTV';
import PlayerSocketUpdater from './SocketUpdater';
import StreamPlayer from './Stream';
import { useStream } from './Stream/context';
import VideoPlayer from './Video';

export const ERRORS: Record<any, string> = {
  ERR_NETWORK: 'Sem conexão com a internet.',
  ERR_BAD_REQUEST:
    'Este vídeo não está sendo transmitido no momento ou o serviço está indisponível.',
} as const;

const PlayerContext = createContext<PlayerContextProps>({} as PlayerContextProps);

function reducer(state: PlayerState, action: PlayerAction) {
  switch (action.type) {
    case 'COMPONENT':
      return {
        ...state,
        Component: action.payload,
      };
    case 'SET_NEXT_TRACK':
      return {
        ...state,
        nextTrack: action.payload,
      };
    case 'PLAY_MEDIA':
      return action.payload == state.mediaUrl
        ? state
        : {
            ...state,
            mediaUrl: action.payload,
            error: undefined,
            loadAttempts: 0,
          };
    case 'RELOAD_MEDIA':
      return {
        ...state,
        mediaUrl: undefined,
        loadAttempts: state.loadAttempts + 1,
        ...action.payload,
      };
    case 'TRACK_ID':
      const { index, startTime = state.startTime } = action.payload;
      const track = state.media?.videoTracks?.[index];
      if (!track || track.trackId == state.trackId) {
        return state;
      }
      return {
        ...state,
        trackId: track.trackId,
        originalTrackId: state.trackId,
        mediaUrl: undefined,
        startTime,
      };
    case 'IS_PLAYING':
      return action.payload == state.isPlaying ? state : { ...state, isPlaying: action.payload };
    case 'IS_MUTED':
      return { ...state, isMuted: action.payload };
    case 'IS_STREAMING':
      return { ...state, isStreaming: action.payload, mediaUrl: undefined, Component: undefined };
    case 'IS_FULLSCREEN':
      return { ...state, isFullScreen: action.payload };
    case 'MEDIA':
      return {
        ...state,
        ...action.payload,
        textTracks: undefined,
        textTrack: undefined,
        Component: undefined,
        recovering: true,
      };
    case 'SETUP_TEXT_TRACKS':
      return { ...state, textTracks: action.payload, isReady: true };
    case 'TEXT_TRACK':
      return { ...state, textTrack: action.payload?.language || undefined };
    case 'ON_ERROR':
      return { ...state, error: action.payload };
    case 'SHOWING_ADS':
      return { ...state, isShowingAds: action.payload, isReady: action.payload == false };
    case 'HAS_TRANSMISSION_ERROR':
      return { ...state, hasTransmissionError: action.payload };
    case 'IS_VISIBLE':
      return {
        ...state,
        isVisible: action?.payload,
      };
    case 'SHOW_UNMUTE_BUTTON':
      return {
        ...state,
        showUnmuteButton: action?.payload,
      };
    case 'IS_LIVE_REAL_TIME':
      return { ...state, isLiveRealTime: action?.payload };
    case 'RESET':
      let { adTagUrl, ...payload } = action.payload;
      // const tempoEntreAd = state.adsExpirationTime! * 60;
      // if (state.lastAdViewTime) {
      //   const tempoDecorrido = (+new Date() - state.lastAdViewTime) / 1000;

      //   if (tempoDecorrido <= tempoEntreAd) {
      //     adTagUrl = undefined;
      //   } else {
      //     state.lastAdViewTime = +new Date();
      //   }
      // } else {
      //   state.lastAdViewTime = +new Date();
      // }

      return {
        ...state,
        ...payload,
        adTagUrl,
        isPlaying: payload.autoplay || state.isPlaying,
        trackId: payload.media?.videoTracks?.[0].trackId,
        originalTrackId: undefined,
        textTracks: undefined,
        textTrack: undefined,
        isShowingAds: undefined,
        isReady: undefined,
        mediaUrl: undefined,
        Component: undefined,
        recovering: undefined,
        loadAttempts: 0,
        error: undefined,
        showUnmuteButton: false,
      };
    default:
      return state;
  }
}

export const PlayerProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isConnected } = useStream();

  const [state, dispatch] = useReducer(reducer, {
    isPlaying: false,
    isStreaming: false,
    isFullScreen: false,
    volume: 5,
    isMuted: false,
    loadAttempts: 0,
    isLiveRealTime: true,
    hasTransmissionError: false,
  });
  const ref = useRef<CustomPlayerRef>(null);

  useEffect(() => {
    if (state.Component) {
      return;
    }
    dispatch({
      type: 'COMPONENT',
      payload: (isConnected
        ? StreamPlayer
        : state.mediaType === 'audio'
        ? AudioPlayer
        : Platform.isWebTV
        ? CTVPlayer
        : VideoPlayer) as any,
    });
  }, [state.Component, state.isPlaying, state.mediaUrl, isConnected]);

  useEffect(() => {
    if (state.isStreaming !== isConnected) {
      dispatch({ type: 'IS_STREAMING', payload: isConnected });
    }
  }, [state.isStreaming, isConnected]);

  useEffect(() => {
    const bootstrap = async () => {
      if ((state.isPlaying && state.trackId) || isConnected) {
        if (!state.mediaUrl) {
          try {
            const mediaUrl = await (state.onRequestMediaUrl || getMediaStreamUrl)(state.trackId!);
            if (mediaUrl) {
              dispatch({ type: 'PLAY_MEDIA', payload: mediaUrl });
            }
          } catch (error: any) {
            dispatch({
              type: 'ON_ERROR',
              payload: {
                code: error?.code || 'SERVICE',
                message: ERRORS[error?.code] || 'Serviço de vídeo indisponível.',
              },
            });
          }
        }
      }
    };
    bootstrap();
  }, [state.isPlaying, state.trackId, state.mediaUrl, state.loadAttempts]);

  const actions = useMemo(
    () => ({
      play: () => dispatch({ type: 'IS_PLAYING', payload: true }),
      pause: () => dispatch({ type: 'IS_PLAYING', payload: false }),
      setHasTransmissionError: (payload: boolean) =>
        dispatch({ type: 'HAS_TRANSMISSION_ERROR', payload }),
      mute: (payload: boolean) => dispatch({ type: 'IS_MUTED', payload }),
      reset: (payload: Partial<PlayerState>) => dispatch({ type: 'RESET', payload }),
      setMedia: (payload: PlayerMedia) => dispatch({ type: 'MEDIA', payload }),
      reloadMedia: (payload?: Partial<PlayerState>) => dispatch({ type: 'RELOAD_MEDIA', payload }),
      setFullScreen: (payload: boolean) => dispatch({ type: 'IS_FULLSCREEN', payload }),
      setTextTrack: (payload?: PlayerTextTrack) => dispatch({ type: 'TEXT_TRACK', payload }),
      setShowingAds: (payload: boolean) => dispatch({ type: 'SHOWING_ADS', payload }),
      setError: (payload: ErrorHandlerType | undefined) => dispatch({ type: 'ON_ERROR', payload }),
      setIsVisible: (payload: boolean) => dispatch({ type: 'IS_VISIBLE', payload }),
      setShowUnmuteButton: (payload: boolean) => dispatch({ type: 'SHOW_UNMUTE_BUTTON', payload }),
      setIsLiveRealTime: (payload: boolean) => dispatch({ type: 'IS_LIVE_REAL_TIME', payload }),
      setupTextTracks: (payload: PlayerTextTrack[]) => {
        dispatch({ type: 'SETUP_TEXT_TRACKS', payload });
      },
      setNextTrack: (payload: PlayerState['nextTrack']) => {
        dispatch({ type: 'SET_NEXT_TRACK', payload });
      },
      setTrack: (index: number) => {
        dispatch({
          type: 'TRACK_ID',
          payload: { index, startTime: ref.current?.progress?.().currentTime },
        });
      },
    }),
    []
  );

  return (
    <PlayerContext.Provider value={{ ref, ...state, ...actions }}>
      {children}
      <PlayerSocketUpdater />
    </PlayerContext.Provider>
  );
};

export const usePlayer = (props?: PlayerProps): PlayerContextProps => {
  const player = useContext(PlayerContext);

  useEffect(() => {
    if (props) {
      player.reset(props);
    }
  }, [props]);

  return player;
};

export default PlayerContext;
