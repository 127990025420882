import _ from 'lodash';
import { Platform } from 'utils/platform';

const TOUCHABLE_ELEMENTS = ['a', 'button', '.touchable', 'div[role="link"]'];

// indexes of the last
let memoryStack: any = [[0, 1]];
let tempMemoryStack: any = null;

// a map of all focusableItems and containers
let focusableItems: any[] = [];

// the node in focus
let inFocusElement: any = null;

// used to track when a page navigation is happening
let oldHref = document.location.href;

// flags
let priorityContainerInUse: any = null;
let isCustomNavigation: boolean = false;

/**
 * Retrieves the forced first index from a stage
 * @returns index
 */
function getFirstIndexFromStage(stage: any) {
  try {
    const testRegex = /first-focus-index\[(\d)\]/;
    const stageClasses = [...(stage?.classList || [])];
    const stageFirstIndex = stageClasses.find((c: string) => testRegex.test(c));
    if (!stageFirstIndex) return 0;

    const [_result, index] = testRegex.exec(stageFirstIndex) || [];
    return Number(index) - 1 || 0;
  } catch {
    return 0;
  }
}

/**
 * This function creates a map of focusable components and inherited navigation containers
 * @param rootElement any HTML element with navigation-container class
 * @returns a list o focusable items
 */
function getNavigationItems(rootElement: any): any {
  const innerContainers = [...rootElement.querySelectorAll('.navigation-container')].filter(
    (stage) => {
      if (stage.classList.contains('navigation-ignore') || stage?.closest('.navigation-ignore'))
        return false;
      const closestContainer = stage.parentElement.closest('.navigation-container');
      return closestContainer === rootElement;
    }
  );

  const priorityContainer = innerContainers.find((container) =>
    container.classList.contains('navigation-priority')
  );

  if (priorityContainer) {
    const priorityContainerData = getNavigationItems(priorityContainer);

    return {
      isPriorized: true,
      items: priorityContainerData.items,
      container: priorityContainerData.container,
    };
  }

  const otherStages = innerContainers.map((stage) => {
    const { x, y } = stage.getBoundingClientRect();
    const data = getNavigationItems(stage);

    return {
      x,
      y: y < 0 ? 0 : y,
      nodes: data.items,
      isPriorized: data.isPriorized,
      container: data.container,
    };
  });

  const deepPriorityContainer = otherStages.find((container) => container.isPriorized);

  if (deepPriorityContainer) {
    return {
      isPriorized: true,
      items: deepPriorityContainer.nodes,
      container: deepPriorityContainer.container,
    };
  }

  const orphanInnerElements = [
    ...rootElement.querySelectorAll(TOUCHABLE_ELEMENTS.join(',')),
  ].filter((e) => {
    if (e.classList.contains('navigation-ignore') || e?.closest('.navigation-ignore')) return false;
    const closestContainer = e.closest('.navigation-container');
    return closestContainer === rootElement && e.offsetHeight > 0 && e.offsetWidth > 0;
  });

  const focusableElementsWithCoords = orphanInnerElements.map((node) => {
    const { x, y } = node.getBoundingClientRect();
    return { node, x, y };
  });

  const orderedFocusableElements = [...otherStages, ...focusableElementsWithCoords].sort((a, b) => {
    const sameLine = Math.abs(a.y - b.y) <= 20;
    return sameLine ? (a.x < b.x ? -1 : 1) : a.y < b.y ? -1 : 1;
  });

  const groupedFocusableElements = orderedFocusableElements.reduce((group, element) => {
    if (!group[group.length - 1] || Math.abs(group[group.length - 1][0].y - element.y) > 20) {
      group.push([]);
    }

    group[group.length - 1].push(element);
    return group;
  }, [] as any);

  return {
    isPriorized: false,
    container: rootElement,
    items: groupedFocusableElements,
  };
}

/**
 * Simple reusable function to load all navigable items
 */
function loadNavigationItems(forceResetNavigation = false) {
  if (forceResetNavigation || oldHref !== document.location.href) {
    if (inFocusElement) inFocusElement.node.classList.remove('focused');
    inFocusElement = null;

    memoryStack = [[0, 1]];
    tempMemoryStack = null;
    focusableItems = [];
    oldHref = document.location.href;
    priorityContainerInUse = null;
    isCustomNavigation = false;
  }

  const root = document.querySelector('#root');
  const navigationItemsData = getNavigationItems(root);
  focusableItems = navigationItemsData.items;

  const isPriorityContainerInUse = Boolean(priorityContainerInUse);
  const isSamePriorityContainer = priorityContainerInUse === navigationItemsData.container;

  if (isPriorityContainerInUse && !navigationItemsData.isPriorized) {
    memoryStack = [[0, 1]];
    priorityContainerInUse = null;
  }

  if (!isSamePriorityContainer && navigationItemsData.isPriorized) {
    memoryStack = [[0, 0]];
    priorityContainerInUse = navigationItemsData.container;
  }

  handleMemoryChange();
}

/**
 * This function will be triggered when memoryStack changes through set memory
 * helper. It`s a recursive function and have the capability to create new memory stacks
 */
function handleMemoryChange() {
  if (isCustomNavigation) return;

  try {
    const maybeInFocusElement = memoryStack.reduce((element: any, memory: any) => {
      if (element?.nodes) return element?.nodes?.[memory[0]]?.[memory[1]];
      return element?.[memory[0]]?.[memory[1]];
    }, focusableItems);

    if (!maybeInFocusElement) {
      if (inFocusElement) inFocusElement.node.classList.remove('focused');

      inFocusElement = null;

      memoryStack = [[0, 1]];
      tempMemoryStack = null;
      focusableItems = [];
      priorityContainerInUse = null;
      isCustomNavigation = false;
    } else if (maybeInFocusElement?.nodes) {
      const firstIndex = getFirstIndexFromStage(maybeInFocusElement.container);
      memoryStack = [...memoryStack, [firstIndex, 0]];
      handleMemoryChange();
    } else {
      if (inFocusElement) inFocusElement.node.classList.remove('focused');

      inFocusElement = maybeInFocusElement;
      const stageContainer = inFocusElement.node.parentElement.closest('.navigation-container');
      const useInstantScroll = stageContainer.classList.contains('instant-scroll');
      const behavior = useInstantScroll ? 'instant' : 'smooth';

      if (inFocusElement.node.parentElement.closest('.rounded-6')) {
        inFocusElement.node.classList.add('rounded-6');
      }

      if (inFocusElement.node.parentElement.closest('.ranking-item')) {
        inFocusElement.node.classList.add('ranking-item');
      }

      inFocusElement.node.classList.add('focused');
      inFocusElement.node.scrollIntoView({ behavior, block: 'center', inline: 'start' });
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Just to organize all memory related data
 * @returns some reusable items
 */
function useMemory() {
  const lastMemoryIndex = memoryStack.length - 1;
  const memory = memoryStack[lastMemoryIndex];

  const getCurrentStageElement = () => {
    const element = inFocusElement.node.parentElement.closest('.navigation-container');
    return element;
  };

  const setMemory = (newData: any) => {
    memoryStack = [...memoryStack.slice(0, lastMemoryIndex), newData];
    tempMemoryStack = null;
    handleMemoryChange();
  };

  const bubbleEventToPreviousStage = (keyCode: any) => {
    if (memoryStack.length > 1) {
      if (!tempMemoryStack) tempMemoryStack = memoryStack;
      memoryStack = memoryStack.slice(0, -1);
      handleMovement(keyCode);
    } else if (tempMemoryStack !== null) {
      memoryStack = tempMemoryStack;
      tempMemoryStack = null;
    }
  };

  const getCurrentStage = () => {
    if (memoryStack.length > 1) {
      const breadcrumbs = memoryStack.slice(0, memoryStack.length - 1);

      return breadcrumbs.reduce((stage: any, breadcrumb: any) => {
        const line = stage[breadcrumb[0]];
        const row = line[breadcrumb[1]];
        if (row.nodes) return row.nodes;
        else if (line.nodes) return line.nodes;
        return line;
      }, focusableItems);
    }

    return focusableItems;
  };

  return { memory, setMemory, getCurrentStage, getCurrentStageElement, bubbleEventToPreviousStage };
}

(window as any).useMemory = useMemory;

/**
 * Handle the keyboard pressing keys
 * @param {number} keyCode
 */
function handleMovement(keyCode: number) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useMemory();
  const { memory, setMemory, bubbleEventToPreviousStage } = data;
  const currentStage = data.getCurrentStage();
  const currentStageElement = data.getCurrentStageElement();
  const isGrid = currentStageElement.classList.contains('navigation-grid');
  const currentElement = inFocusElement.node;

  function getMaxY(x: any, y: any) {
    try {
      const maxY = currentStage[x].length - 1;
      return y > maxY ? maxY : y;
    } catch {
      return 0;
    }
  }

  switch (keyCode) {
    // down
    case 40:
      if (
        currentStageElement.classList.contains('ignore-down-key') ||
        currentElement.classList.contains('ignore-down-key')
      ) {
        break;
      }

      const downMaxIndex = currentStage.length - 1;
      const downX = memory[0] + 1;
      const downY = isGrid ? getMaxY(downX, memory[1]) : 0;

      if (memory[0] !== downMaxIndex) setMemory([downX, downY]);
      else bubbleEventToPreviousStage(keyCode);
      break;

    // up
    case 38:
      if (
        currentStageElement.classList.contains('ignore-up-key') ||
        currentElement.classList.contains('ignore-up-key')
      ) {
        break;
      }

      const upMaxIndex = 0;
      const upX = memory[0] - 1;
      const upY = isGrid ? getMaxY(upX, memory[1]) : 0;

      if (memory[0] !== upMaxIndex) setMemory([upX, upY]);
      else bubbleEventToPreviousStage(keyCode);
      break;

    // right
    case 39:
      if (
        currentStageElement.classList.contains('ignore-right-key') ||
        currentElement.classList.contains('ignore-right-key')
      ) {
        break;
      }

      const rightMaxIndex = currentStage[memory[0]].length - 1;
      const rightX = memory[0];
      const rightY = memory[1] + 1;

      if (memory[1] !== rightMaxIndex) setMemory([rightX, rightY]);
      else bubbleEventToPreviousStage(keyCode);
      break;

    // left
    case 37:
      if (
        currentStageElement.classList.contains('ignore-left-key') ||
        currentElement.classList.contains('ignore-left-key')
      ) {
        break;
      }

      const leftMaxIndex = 0;
      const leftX = memory[0];
      const leftY = memory[1] - 1;

      if (memory[1] !== leftMaxIndex) setMemory([leftX, leftY]);
      else bubbleEventToPreviousStage(keyCode);
      break;

    // enter
    case 13:
      if (
        currentStageElement.classList.contains('ignore-enter-key') ||
        currentElement.classList.contains('ignore-enter-key')
      ) {
        break;
      }

      inFocusElement.node.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );

      break;

    // esc
    // control back button tizen
    case 27:
    case 461:
    case 10009:
      if (history.length) {
        history.back();
      } else {
        try {
          // @ts-ignore
          tizen.application.getCurrentApplication().exit();
        } catch (ignore) {}
      }
      break;

    default:
      break;
  }
}

function handleLoad() {
  setTimeout(() => {
    loadNavigationItems();
    handleMemoryChange();

    const observer = new MutationObserver((mutations: any) => {
      let shouldUpdate = false;

      for (const mutation of mutations) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (mutation.target.classList.contains('navigation-container')) {
            shouldUpdate = true;
          }

          continue;
        }

        shouldUpdate = true;
      }

      if (shouldUpdate) loadNavigationItems();
    });

    observer.observe(document.querySelector('body') as any, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['class'],
    });
  }, 0);
}

function handleKeypress(event: KeyboardEvent) {
  event.preventDefault();
  handleMovement(event.keyCode);
}

if (Platform.isTV) {
  // @TODO mover para um bootstrap de ctv
  document.documentElement.classList.add('ctv');

  // setimeout 0 force to be executed after all js instructions
  window.addEventListener('navigation', () => setTimeout(() => loadNavigationItems(true), 0));
  window.addEventListener('load', handleLoad, false);
  window.addEventListener('keydown', _.throttle(handleKeypress, 200), false);
}
