import { Box, View } from '@gluestack-ui/themed';
import { useFocusEffect } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import { useMobileOrDesktop } from 'hoc/mobileOrDesktop';
import { RootStackScreenProps } from 'navigation/AppNavigator';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAnalytics } from 'services/analytics/useAnalytics';
import { ProgramType } from 'utils/enum';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

import Hero from 'components/Hero';
import Player from 'components/Player';
import { usePlayer } from 'components/Player/context';
import ProgramContent from 'components/Program/Content';
import ProgramRadio from 'components/Program/Radio';
import ProgramRelated from 'components/Program/Related';
import { useProgram } from 'components/Program/context';

const RadioScreen: React.FC<RootStackScreenProps<'Program'>> = ({ navigation }) => {
  const program = useProgram();
  const analytics = useAnalytics();
  const [mediaId, setMediaId] = useState<string>();
  const [isActive, setIsActive] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const { isPlaying, isFullScreen, isStreaming, pause, isVisible, mute, setNextTrack } =
    usePlayer();
  const { ads } = useGlobalConfig();

  useEffect(() => {
    setNextTrack(undefined);
  }, []);

  useEffect(() => {
    return navigation.addListener('beforeRemove', async () => {
      if (isPlaying && !isStreaming) pause();
      mute(false);
    });
  }, [navigation, isPlaying, isStreaming, pause]);

  useEffect(() => {
    if (Platform.isAndroidTV && !isVisible) {
      setOpenVideo(false);
    }
  }, [isVisible]);

  useFocusEffect(
    useCallback(() => {
      setIsActive(true);

      return () => {
        if (!isStreaming) pause();
        setIsActive(false);
      };
    }, [isStreaming])
  );

  useFocusEffect(
    useCallback(() => {
      analytics.triggerRadioScreenPageViewEvent({
        programId: program._id,
        programTitle: program.title,
        programAgeRating: program.rating,
        programAdUnit: program.ads?.adunit?.val!,
        programCategory: program.category.map((tag) => tag.name).join(', '),
        programGenre: program.genre.map((genre) => genre.name).join(', '),
      });
    }, [])
  );

  const playerProps: PlayerProps | undefined = useMemo(() => {
    if (!program) {
      return undefined;
    }

    const media: PlayerMedia = {
      title: program.title,
      image: [program.imageVertical?.url!, program.imageHorizontal?.url!],
      duration: program.duration,
      programId: program._id,
      programTitle: program.title,
      programUrl: program.url,
      ratingValue: program.rating,
      ratingDescription: program.rateDescription,
      videoTracks: [],
    };

    const mediaIdDub = program.mediaIdDub;

    if (mediaIdDub) {
      media.videoTracks.push({
        language: 'dub',
        trackId: mediaIdDub,
      });
    }

    media.videoTracks.push({ language: 'origin', trackId: program.mediaId });

    return {
      media,
      isLive: program.type == ProgramType.LIVE || program.type == ProgramType.RADIO,
      adTagUrl:
        ads?.disableAds || program.ads?.disableAds
          ? undefined
          : ads?.vast.replace(
              /iu=([^&]+)/,
              (_, original) => `iu=${program.ads?.adunit?.val ?? original}`
            ),
      adsExpirationTime: ads?.adsExpirationTime,
    };
  }, [program, mediaId]);

  const Video = useMemo(
    () =>
      mediaId != undefined && isActive && playerProps != undefined ? (
        <Player {...playerProps} autoplay />
      ) : undefined,
    [mediaId, isActive, playerProps]
  );

  const [VideoMobile, VideoDesktop] = useMobileOrDesktop(Video, Video);

  if (Platform.isAndroidTV && isVisible) {
    return VideoDesktop;
  }

  return (
    <>
      {VideoMobile != undefined && (
        <View style={{ marginTop: isFullScreen ? 0 : Platform.isMobileWeb() ? 45 : 80 }}>
          {Video}
        </View>
      )}
      {(Platform.isAndroidTV || Platform.OS === 'web' || !isFullScreen) && (
        <Hero program={program} hideCover={mediaId != undefined}>
          <Hero.Header
            program={program}
            Aside={
              VideoDesktop != undefined && !Platform.isAndroidTV ? (
                <Box aspectRatio={1 / 0.56} width={Platform.isTV ? 0 : '80%'}>
                  {Video}
                </Box>
              ) : undefined
            }
            isVideoOpen={openVideo}>
            <ProgramRadio
              mediaId={mediaId}
              setMediaId={setMediaId}
              openVideo={openVideo}
              setOpenVideo={setOpenVideo}
            />
            <ProgramContent />
          </Hero.Header>

          <View py={calcSizeForDevice(24)}>
            <ProgramRelated />
          </View>
        </Hero>
      )}
    </>
  );
};

export default RadioScreen;
