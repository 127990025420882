import {
  Box,
  ButtonText,
  Center,
  Image,
  Pressable,
  ScrollView,
  Spinner,
  Text,
  View,
} from '@gluestack-ui/themed';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { useGlobalConfig } from 'context/GlobalContext';
import Constants from 'expo-constants';
import { ImageBackground } from 'expo-image';
import { useEffect, useState } from 'react';
import { Dimensions, useWindowDimensions } from 'react-native';
import { SCREEN_SIZE, calcSizeForDevice } from 'utils/index';
import { calcSizeForTv } from 'utils/index';
import { Platform } from 'utils/platform';

import backgroundImageDesktop from 'assets/error-background-desktop.png';
import backgroundImageMobile from 'assets/error-background-mobile.png';
import geolocationErrorDesktop from 'assets/geolocation-error-desktop.png';
import geolocationErrorMobile from 'assets/geolocation-error-mobile.png';
import Logo from 'assets/loginLogo2x.png';
import notFoundErrorDesktop from 'assets/not-found-error-desktop.png';
import notFoundErrorMobile from 'assets/not-found-error-mobile.png';
import systemErrorDesktop from 'assets/system-error-desktop.png';
import systemErrorMobile from 'assets/system-error-mobile.png';

import NewGradientButton from 'components/Button/NewGradienteButton';
import { Icons } from 'components/Icons';
import { usePlayer } from 'components/Player/context';

export enum ErrorHandlerType {
  NO_INTERNET = 'NO_INTERNET',
  NETWORK_ERROR = 'NETWORK_ERROR',
  GEOLOCATION_ERROR = 'GEOLOCATION_ERROR',
  NOT_FOUND = 'NOT_FOUND',
}
export interface ErrorHandlerProps {
  error: ErrorHandlerType | undefined;
}

interface DataState {
  title: string;
  description: string;
  image: string;
  buttonTxt?: string;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = (props) => {
  const { error = undefined } = props;
  const {
    reloadMedia,
    setError,
    error: playerError,
    setIsVisible,
    setHasTransmissionError,
    setFullScreen,
  } = usePlayer();
  const linkTo = useLinkTo();
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [data, setData] = useState<DataState>({
    title: '',
    description: '',
    image: '',
  });
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  const isMobile = width < 950;
  const { hasInternet } = useGlobalConfig();
  const backgroundImage = isMobile ? backgroundImageMobile : backgroundImageDesktop;

  const clearError = () => {
    setIsVisible(false);
    setFullScreen(false);
    setError(undefined);
    setImageLoaded(false);
    setHasTransmissionError(false);
  };

  useEffect(() => {
    return navigation.addListener('beforeRemove', async () => {
      clearError();
    });
  }, []);

  useEffect(() => {
    if (!error) return;

    setIsVisible(false);

    const getErrorData = (
      hasTransmissionError: boolean,
      title: string,
      description: string,
      image: { mobile: string; desktop: string },
      buttonTxt?: string
    ) => {
      setHasTransmissionError(hasTransmissionError);

      return {
        title,
        description,
        image: isMobile ? image.mobile : image.desktop,
        ...(buttonTxt && { buttonTxt }),
      };
    };

    const errorHandlers = {
      [ErrorHandlerType.NO_INTERNET]: () => {
        return getErrorData(
          !hasInternet,
          'Identificamos um problema na sua conexão',
          'Verifique sua internet. \nVocê ja tentou desligar e ligar de novo?',
          { mobile: systemErrorMobile, desktop: systemErrorDesktop }
        );
      },
      [ErrorHandlerType.NETWORK_ERROR]: () =>
        getErrorData(
          true,
          'A conexão parece instável. Recarregue a página.',
          'Estamos lidando com um problema de conexão. Recarregue a página para tentar novamente.',
          { mobile: systemErrorMobile, desktop: systemErrorDesktop },
          'Recarregar a página'
        ),
      [ErrorHandlerType.NOT_FOUND]: () =>
        getErrorData(
          true,
          'Conteúdo desaparecido!',
          'Este conteúdo não faz mais parte do nosso catálogo. Que tal explorar outras opções do meu catálogo?',
          { mobile: notFoundErrorMobile, desktop: notFoundErrorDesktop },
          'Voltar para página inicial'
        ),
      [ErrorHandlerType.GEOLOCATION_ERROR]: () =>
        getErrorData(
          true,
          'Cadê você?',
          'Não é nada pessoal, é apenas uma questão de localização. Que tal navegar por outras opções?',
          { mobile: geolocationErrorMobile, desktop: geolocationErrorDesktop },
          'Retornar para página inicial'
        ),
      default: () =>
        getErrorData(
          true,
          'Oops! Deu tilt!',
          'Não se preocupe, nosso time técnico já está resolvendo. Enquanto isso, você pode procurar outros títulos do meu catálogo.',
          { mobile: systemErrorMobile, desktop: systemErrorDesktop },
          'Retornar para página inicial'
        ),
    };

    setData(errorHandlers[error]?.() || errorHandlers.default());
    setIsLoading(false);
  }, [error]);

  const haderNavigation = () => {
    if (Platform.isTV || (Platform.OS === 'web' && !isMobile)) {
      return <></>;
    }

    const iconsSize = {
      width: calcSizeForDevice(24, 42).toString(),
      height: calcSizeForDevice(24, 42).toString(),
    };

    return (
      <View
        alignItems="center"
        justifyContent="center"
        mb={3}
        mt={Platform.OS !== 'web' ? 5 : 15}
        height={calcSizeForDevice(44)}>
        {!Platform.isTV ? (
          <Image
            source={Logo}
            alt="Logo do B do Bandplay"
            width={calcSizeForDevice(157, 290)}
            height={calcSizeForDevice(44, 82)}
          />
        ) : null}

        {hasInternet && (
          <Pressable
            w={calcSizeForDevice(64)}
            top="$0"
            h="$full"
            left="$0"
            position="absolute"
            onPress={() => {
              clearError();
              if (Platform.OS === 'web') {
                if (window.history.length > 1) {
                  window.history.back();
                } else {
                  linkTo('/');
                }
              } else {
                navigation.goBack();
              }
            }}
            justifyContent="center">
            <Box>
              <Icons.ChevronLeft {...iconsSize} />
            </Box>
          </Pressable>
        )}
      </View>
    );
  };

  if (isLoading) {
    return (
      <View flex={1} bg="#161233">
        <Center h="$full">
          <Spinner size="large" />
        </Center>
      </View>
    );
  }

  return (
    <View flex={1} bg="#161233">
      {!imageLoaded ? (
        <Center
          h="$full"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}>
          <Spinner size="large" />
        </Center>
      ) : null}

      <ImageBackground
        source={backgroundImage}
        style={{
          padding: isMobile ? 24 : 44,
          paddingBottom: 0,
          paddingTop: isMobile ? Constants?.statusBarHeight : 0,
          flex: 1,
          justifyContent: isMobile ? 'flex-start' : 'center',
          maxHeight: Dimensions.get('screen').height,
          overflow: 'hidden',
          opacity: imageLoaded || !hasInternet ? 1 : 0,
        }}
        {...(hasInternet ? { onLoadEnd: () => setImageLoaded(true) } : {})}>
        {haderNavigation()}
        <ScrollView showsVerticalScrollIndicator={false} paddingBottom={isMobile ? 24 : 44}>
          <Box
            height={'$full'}
            alignItems="center"
            justifyContent={Platform.OS !== 'web' || isMobile ? 'flex-start' : 'center'}
            gap={isMobile ? 24 : 34}
            maxWidth={isMobile ? width - 40 : calcSizeForDevice(SCREEN_SIZE().width)}
            alignSelf={Platform.OS !== 'web' ? 'auto' : 'center'}
            flexDirection={!isMobile ? 'row' : 'column'}>
            <View
              w={isMobile ? '$full' : calcSizeForDevice(SCREEN_SIZE().width / 2.4)}
              padding={calcSizeForDevice(16)}
              paddingHorizontal={calcSizeForDevice(isMobile ? 5 : 16)}
              alignItems={!isMobile ? 'flex-start' : 'center'}>
              <Text
                fontWeight="$bold"
                color="$white"
                textAlign={isMobile ? 'center' : 'auto'}
                textShadowColor="#00000077"
                textShadowOffset={{ width: 0, height: 3 }}
                textShadowRadius={10}
                fontSize={calcSizeForDevice(isMobile ? 28 : 64, 74)}
                lineHeight={calcSizeForDevice(isMobile ? 36 : 70, 80)}>
                {data.title}
              </Text>

              {isMobile ? (
                <Image
                  source={data.image}
                  width={width - 48}
                  height={(width - 48) * (Platform.OS === 'web' ? 0.9 : 0.95)}
                  maxHeight={400}
                  mt={18}
                  resizeMode="contain"
                />
              ) : null}

              <Text
                fontSize={calcSizeForDevice(isMobile ? 18 : 24, 34)}
                lineHeight={calcSizeForDevice(isMobile ? 28 : 32, 42)}
                color="$white"
                textAlign={isMobile ? 'center' : 'left'}
                textShadowColor="#00000077"
                fontWeight="500"
                textShadowOffset={{ width: 0, height: 2 }}
                textShadowRadius={10}
                mt={calcSizeForDevice(isMobile ? 32 : 48)}
                mb={calcSizeForDevice(isMobile ? 32 : 32)}>
                {data.description}
              </Text>

              {data?.buttonTxt ? (
                <Box
                  w={width > 500 ? 300 : '$full'}
                  mx={width > 500 ? undefined : 'auto'}
                  gap={24}
                  flexDirection={!isMobile ? 'row' : 'column'}>
                  <NewGradientButton
                    useTVFocus
                    hasTVPreferredFocus
                    style={{ height: 54, width: '100%' }}
                    onPress={() => {
                      if (playerError === ErrorHandlerType.NETWORK_ERROR) {
                        reloadMedia();
                        setIsLoading(true);
                        setIsVisible(true);

                        setTimeout(() => {
                          setIsLoading(false);
                        }, 2000);
                      } else {
                        linkTo('/');
                      }

                      clearError();
                    }}>
                    <ButtonText fontWeight="bold" color="#070D31" fontSize={16}>
                      {data.buttonTxt}
                    </ButtonText>
                  </NewGradientButton>
                </Box>
              ) : null}
            </View>
            {!isMobile && (
              <Box height={'$full'} w={'$1/2'} alignItems="center" justifyContent="center">
                <Image
                  source={data.image}
                  width={Platform.isTV ? calcSizeForTv(998) : SCREEN_SIZE().width / 2.1}
                  height={Platform.isTV ? calcSizeForTv(1066) : width / 2}
                  resizeMode={Platform.isTV ? 'contain' : 'center'}
                />
              </Box>
            )}
          </Box>
        </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default ErrorHandler;
