import { HStack, Icon, Link, LinkText } from '@gluestack-ui/themed';
import { useLinkTo, useRoute } from '@react-navigation/native';

import { Icons } from 'components/Icons';

const MenuNavigation: React.FC = () => {
  const linkTo = useLinkTo();
  const route = useRoute();
  const NavigationLink = [
    {
      icon: Icons.Streaming,
      title: 'Ao vivo',
      menuRef: '/band-ao-vivo',
      opacity: route.path === '/band-ao-vivo',
    },
    {
      icon: Icons.ClapperboardPlay,
      title: 'Assista',
      onPress() {
        linkTo('/assista');
      },
      opacity: route.name === 'Videos',
    },
    {
      icon: Icons.Radio,
      title: 'Ouça',
      onPress() {
        linkTo('/ouca');
      },
      opacity: route.name === 'Listen',
    },
  ];

  return (
    <HStack
      sx={{
        '@lg': {
          gap: 45,
          display: 'flex',
        },
        '@md': {
          gap: 20,
          display: 'flex',
        },
        '@sm': {
          gap: 20,
          display: 'flex',
        },
        '@base': {
          display: 'none',
        },
      }}
      marginLeft={40}>
      {NavigationLink.map((menu) => (
        <Link
          style={{ flexDirection: 'row', alignItems: 'center' }}
          href={menu.menuRef ? menu.menuRef : undefined}
          onPress={menu.onPress}
          key={menu.title}>
          <Icon as={menu.icon} mr="$2" color="#FFF" opacity={menu.opacity ? 1 : 0.7} />
          <LinkText
            fontWeight="600"
            opacity={menu.opacity ? 1 : 0.7}
            sx={{
              '@lg': {
                fontSize: '$lg',
              },
              '@md': {
                fontSize: '$lg',
              },
              '@sm': {
                fontSize: '$md',
              },
              '@base': {
                fontSize: '$sm',
              },
            }}
            color="#FFF"
            textDecorationLine="none">
            {menu.title}
          </LinkText>
        </Link>
      ))}
    </HStack>
  );
};

export default MenuNavigation;
