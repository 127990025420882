import { Box, Text, useBreakpointValue } from '@gluestack-ui/themed';
import React from 'react';
import theme from 'theme';
import { formatTimeWithHSeparator, getWeekdayAbbreviation, isToday } from 'utils/date';
import { calcSizeForDevice } from 'utils/index';
import { Platform } from 'utils/platform';

type EventDateTagProps = {
  startDate: Date;
};

export const EventDateTag: React.FC<EventDateTagProps> = ({ startDate }) => {
  const isAndroidTV = Platform.isAndroidTV;
  const tagBorderBottomRightRadius = isAndroidTV ? 8 : 16;

  const eventTagHeight = useBreakpointValue({
    base: isAndroidTV ? calcSizeForDevice(51) : 24,
    lg: calcSizeForDevice(40, 51),
  });

  const eventDayTagWidth = useBreakpointValue({
    base: isAndroidTV ? calcSizeForDevice(110) : 50,
    lg: calcSizeForDevice(95, 120),
  });

  const eventTimeTagWidth = useBreakpointValue({
    base: isAndroidTV ? calcSizeForDevice(125) : 60,
    lg: calcSizeForDevice(100, 125),
  });

  const eventTagTextSize = useBreakpointValue({
    base: isAndroidTV ? calcSizeForDevice(32) : 14,
    lg: calcSizeForDevice(24, 32),
  });

  if (!startDate || isNaN(startDate.getTime())) return null;

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      display="flex"
      flexDirection="row"
      shadowColor="rgb(0, 0, 0)"
      shadowOpacity={0.3}
      shadowRadius={25}
      borderBottomRightRadius={tagBorderBottomRightRadius}>
      <Box
        width={eventDayTagWidth}
        height={eventTagHeight}
        backgroundColor={theme.colors.secondary[500]}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Text
          color={theme.colors.base.darkBlue}
          fontWeight="$extrabold"
          fontSize={eventTagTextSize}>
          {(isToday(startDate) ? 'Hoje' : getWeekdayAbbreviation(startDate.getDay())).toUpperCase()}
        </Text>
      </Box>
      <Box
        width={eventTimeTagWidth}
        height={eventTagHeight}
        backgroundColor="#181A20"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderBottomRightRadius={tagBorderBottomRightRadius}>
        <Text color={theme.colors.base.white} fontWeight="$extrabold" fontSize={eventTagTextSize}>
          {formatTimeWithHSeparator(startDate)}
        </Text>
      </Box>
    </Box>
  );
};
